<template>
  <div>
    <template v-if="!complete">
      <v-alert v-if="loadingTicketGroups" class="mb-0" type="info" text>
        Carregando lotes, por favor aguarde...
      </v-alert>
      <v-alert
        v-else-if="!ticketGroups.length"
        class="mb-0"
        type="warning"
        text
      >
        Você não possui permissão para emitir nenhum lote como cortesia.
      </v-alert>
      <template v-else>
        <h5>Link Promocional</h5>

        <v-form v-model="valid" @submit.prevent>
          <v-row dense class="mx-0">
            <v-col cols="12">
              <v-text-field
                v-model="data.name"
                label="Nome"
                outlined
                :disabled="loading"
                dense
                :rules="[(v) => !!v || 'Campo obrigatório']"
              />
            </v-col>

            <v-col cols="12">
              <base-editor
                label="Descrição"
                v-model="data.description"
                class="mb-4"
              />
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="data.ticketBlock"
                :items="ticketBlocksList"
                item-text="text"
                return-object
                label="Lote"
                dense
                outlined
                :disabled="loading"
                :rules="[(v) => !!v || 'Campo obrigatório']"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="data.maxTickets"
                label="Quantidade máxima de ingressos"
                hint="O lote deve ter estoque suficiente para atender ao máximo de ingressos"
                type="number"
                outlined
                :disabled="loading"
                :rules="[
                  (v) => !!v || 'Campo obrigatório',
                  (v) => v >= 1 || 'Mínimo de 1',
                ]"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="data.maxPerUser"
                label="Máximo de ingressos por usuário"
                type="number"
                outlined
                :disabled="loading"
                :rules="[
                  (v) => !!v || 'Campo obrigatório',
                  (v) => v >= 1 || 'Mínimo de 1',
                ]"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="data.maxDate"
                label="Data máxima de emissão"
                type="datetime-local"
                outlined
                :disabled="loading"
                dense
                hint="Deixe em branco para não limitar a data"
                :min="new Date().toISOString().split('T')[0]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>

        <v-alert v-if="error" class="mb-0 mt-4" type="error" text>
          {{ error }}
        </v-alert>
      </template>
    </template>
    <template v-else>
      <div class="d-flex flex-column align-center">
        <h5 class="mb-2 text-center">
          {{ loading ? "Criando link promocional" : "Link promocional criado" }}
        </h5>
        <!-- <v-alert v-if="success" class="mb-0 mt-4" type="success" text>
          Link promocional criado com sucesso!
        </v-alert> -->

        <div class="my-6">
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
            size="50"
          />

          <v-btn
            v-else-if="courtesyPromotional"
            color="primary"
            large
            @click="copyLink"
            :disabled="copied || loading"
          >
            <v-icon left>mdi-{{ copied ? "check" : "content-copy" }}</v-icon>
            {{ copied ? "Link copiado" : "Copiar link" }}
          </v-btn>
        </div>
      </div>

      <v-btn block text :disabled="loading" @click="$emit('close', true)">
        Fechar
      </v-btn>
    </template>
    <div v-if="!complete" class="d-flex justify-space-between mt-3">
      <v-btn text @click="$emit('prev')"> Voltar </v-btn>
      <v-btn color="primary" @click="send" :disabled="loading || !valid">
        Continuar
      </v-btn>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import COURTESY_PROMOTIONAL from "@/services/admin/courtesyPromotional";
import PhoneInput from "../../../global/PhoneInput.vue";

export default {
  components: { PhoneInput },
  props: {
    party: {
      type: Object,
      required: true,
    },
    ticketGroups: {
      type: Array,
      required: true,
    },
    loadingTicketGroups: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      complete: false,
      error: null,
      success: false,
      valid: false,
      copied: false,
      courtesyPromotional: null,
      data: {
        name: "",
        description: "",
        ticketBlock: null,
        maxTickets: 1,
        maxPerUser: 1,
        maxDate: null,
        active: true,
      },
    };
  },
  methods: {
    async send() {
      try {
        this.loading = true;
        this.error = null;
        this.complete = true;
        this.$emit("next");

        const data = {
          name: this.data.name,
          description: this.data.description,
          ticketBlockId: this.data.ticketBlock.id,
          maxTickets: this.data.maxTickets,
          maxPerUser: this.data.maxPerUser,
          maxDate: moment(this.data.maxDate).toISOString(),
        };

        const { courtesyPromotional } = await COURTESY_PROMOTIONAL.create(
          this.party.organizationId,
          this.party.id,
          data
        );
        this.courtesyPromotional = courtesyPromotional;
        this.success = true;
      } catch (error) {
        this.error = error.message || "Erro ao emitir cortesias";
        this.complete = false;
        this.$emit("prev");
      } finally {
        this.loading = false;
      }
    },
    copyLink() {
      var url = `${window.location.origin}/redeem/courtesy/${this.party.slug}/${this.courtesyPromotional.id}`;
      navigator.clipboard.writeText(url);
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 3000);
    },
  },
  watch: {},
  computed: {
    ticketBlocksList() {
      const ticketBlocks = [];
      this.ticketGroups
        .filter(({ tableGroupId }) => !tableGroupId)
        .forEach((ticketGroup) => {
          ticketGroup.TicketBlock.forEach((ticketBlock) => {
            ticketBlocks.push({
              text: `${ticketGroup.name} - ${ticketBlock.name}`,
              id: ticketBlock.id,
            });
          });
        });

      return ticketBlocks;
    },
  },
  mounted() {
    this.data.maxDate = moment(this.party.date).format("YYYY-MM-DDTHH:mm");
  },
};
</script>

<style></style>

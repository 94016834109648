<template>
  <div>
    <v-card-text>
      <template>
        <h5>Ingressos</h5>
        <div>
          <div v-for="(_ticketBlock, i) in value" :key="i" class="d-flex gap-2">
            <v-autocomplete
              v-model="value[i].TicketBlock"
              :items="availableTicketBlocks"
              item-text="name"
              item-value="id"
              return-object
              label="Lote"
              dense
              outlined
              clearable
              :item-disabled="itemDisabled(i)"
              style="max-width: calc(100% - 98px)"
              :prepend-icon="i ? 'mdi-close' : null"
              @click:prepend="value.splice(i, 1)"
            >
              <template v-slot:selection="data">
                <span v-bind="data.attrs" @click:close="data.select">
                  {{ data.item.name }}
                </span>
              </template>
            </v-autocomplete>
            <v-text-field
              v-model="value[i].quantity"
              label="Quantidade"
              dense
              outlined
              type="number"
              style="max-width: 90px"
              min="1"
              :rules="[
                (v) => !!v || 'Campo obrigatório',
                (v) => v > 0 || 'Quantidade inválida',
              ]"
            ></v-text-field>
          </div>
          <v-btn
            text
            block
            @click="value.push({ TicketBlock: null, quantity: 1 })"
            :disabled="!valid || value.length >= itemsOptions.length"
          >
            Adicionar lote
          </v-btn>
        </div>
      </template>
    </v-card-text>
  </div>
</template>

<script>
export default {
  emits: ["input"],
  data: () => ({
    search: "",
    options: [],
  }),
  computed: {
    availableTicketBlocks() {
      return this.itemsOptions.map((block) => ({
        ...block,
        name: `${block.TicketGroup?.name} - ${block?.name}`,
      }));
    },
    selectedTicketBlocks() {
      return this.value.map((tb) => tb.TicketBlock?.id);
    },
    valid() {
      const isValid = this.value.every(
        (tb) => tb.TicketBlock && tb.quantity > 0
      );
      this.$emit("valid", isValid);
      return isValid;
    },
  },
  methods: {
    itemDisabled(i) {
      return (item) => {
        const selectedIds = this.selectedTicketBlocks;
        return (
          selectedIds.includes(item.id) && i !== selectedIds.indexOf(item.id)
        );
      };
    },
    updateTicketBlock(index) {
      const selectedBlock = this.itemsOptions.find(
        (block) => block.id === this.value[index].TicketBlock?.id
      );
      this.$set(this.value[index], "TicketBlock", selectedBlock);
    },
  },
  props: {
    itemsOptions: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style></style>

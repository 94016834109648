<template>
  <v-dialog v-model="isOpen" width="600" scrollable content-class="rounded-lg">
    <v-card class="h-full d-flex flex-column pb-2" style="max-height: 90dvh">
      <v-card-title class="d-flex justify-space-between">
        Estoque
        <v-btn-toggle v-model="managementMode">
          <v-btn small> Adicionar </v-btn>
          <v-btn small> Corrigir </v-btn>
        </v-btn-toggle></v-card-title
      >
      <v-card-text v-if="product?.Variants" class="d-flex flex-column gap-3">
        <v-card
          v-for="(variant, i) in product?.Variants"
          outlined
          :key="i"
          elevation="0"
        >
          <v-divider v-if="i"></v-divider>
          <v-card-text>
            <div class="d-flex gap-3 align-stretch">
              <div class="flex-1" style="display: grid; position: relative">
                <v-img
                  class="rounded-lg"
                  :src="variant.Images[0]?.url || product.image"
                  :aspect-ratio="1"
                >
                </v-img>
                <div
                  class="text-center"
                  style="position: absolute; bottom: -10px; right: 0; left: 0"
                >
                  <v-chip color="primary" small>{{ variant.name }}</v-chip>
                </div>
              </div>
              <v-card
                elevation="0"
                class="pa-3 d-flex flex-1 flex-column align-center justify-center"
              >
                <span class="text-22">
                  {{ (variant.quantity - variant.sold || 0) | number }}
                </span>
                <span class="text-secondary">Estoque atual</span>
              </v-card>
              <v-card
                elevation="0"
                class="flex-1 pa-3 d-flex flex-column justify-center"
              >
                <v-form v-model="isValid" ref="form" @submit.prevent="save">
                  <v-text-field
                    v-if="managementMode === 0"
                    v-model="productFixes[variant.id]"
                    label="Adicionar"
                    type="number"
                    dense
                    class="mt-4"
                    filled
                    :autofocus="i === 0"
                    :rules="[
                      (v) =>
                        v >= variant.sold - variant.quantity ||
                        'Adição deve ser maior ou igual ao estoque atual',
                    ]"
                  />
                  <v-text-field
                    v-else
                    v-model="productFixes[variant.id]"
                    label="Quantidade"
                    type="number"
                    dense
                    class="mt-4"
                    :rules="[
                      (v) => v >= 0 || 'Correção deve ser maior ou igual a 0',
                    ]"
                  />
                </v-form>
              </v-card>
            </div>
          </v-card-text>
        </v-card>
        <v-alert v-if="this.error" type="error">
          {{ this.error }}
        </v-alert>
      </v-card-text>

      <v-card-actions class="align-end gap-1 justify-space-between">
        <v-btn text :disabled="loading" @click="close"> Cancelar </v-btn>
        <v-btn
          color="success"
          :disabled="!isValid"
          :loading="loading"
          @click="save"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import PRODUCT from "@/services/admin/products";
export default {
  components: {},
  data: () => ({
    isOpen: false,
    loading: false,
    error: null,
    product: null,
    managementMode: 0,
    productFixes: {},
    isValid: false,
  }),

  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },

  methods: {
    open(product) {
      this.isOpen = true;
      this.loading = false;
      this.product = product;
    },
    close() {
      this.isOpen = false;
      this.loading = false;
      this.error = null;
      this.product = null;
      this.productFixes = {};
    },
    async save() {
      this.loading = true;
      this.error = null;
      let organizationId = this.selectedOrganization.id;

      try {
        const updateVariant = this.product.Variants.reduce((acc, variant) => {
          const adjustmentValue = Number(this.productFixes[variant.id]);

          const quantity =
            this.managementMode === 0
              ? adjustmentValue
              : adjustmentValue - (variant.quantity - variant.sold || 0);

          if (quantity !== 0) acc[variant.id] = quantity;
          return acc;
        }, {});

        console.log(updateVariant);

        await PRODUCT.updateStock(
          organizationId,
          this.product.id,
          updateVariant
        );
        this.$root.$emit("product-updated");
        this.close();
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.$root.$on("open-product-stock-modal", this.open);
  },
};
</script>

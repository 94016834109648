<template>
  <div>
    <v-card>
      <v-card-title class="px-0">
        <v-text-field
          hide-details
          outlined
          :prepend-inner-icon="template.icon"
          v-model="data.question"
          label="Pergunta"
        ></v-text-field>
      </v-card-title>
      <div>
        <div>
          <v-chip-group
            column
            v-model="selectedAnswer"
            dense
            :multiple="template.multiple"
            color="primary"
            class="d-flex justify-center mb-3"
          >
            <v-chip
              :small="template.answers.length > 10"
              v-for="item in template.answers"
              :key="item.id"
            >
              <span>{{ item.short }}</span>
            </v-chip>
          </v-chip-group>
        </div>
        <v-textarea outlined label="Resposta" v-model="data.answer" />
        <v-alert type="error" v-if="error">{{ error }}</v-alert>
        <div class="d-flex">
          <v-btn small text @click="$emit('close')" :disabled="loading">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn small text @click="$emit('next')" :disabled="loading">
            Ignorar
          </v-btn>
          <v-btn
            small
            color="primary"
            @click="saveFaq"
            :disabled="!data.question || !data.answer"
            :loading="loading"
          >
            Salvar
          </v-btn>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FAQ from "@/services/admin/faq";
export default {
  props: {
    template: {
      type: Object,
      required: true,
    },
    party: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    error: false,
    loading: false,
    data: {
      question: "",
      answer: "",
    },
    selectedAnswer: [],
  }),
  methods: {
    async saveFaq() {
      try {
        this.loading = true;
        this.error = false;
        await FAQ.create(this.selectedOrganization.id, this.party.id, {
          question: this.data.question,
          answer: this.data.answer,
          templateId: this.template.id,
        });
        this.$emit("next");
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.error = e.message || e;
      }
    },
  },
  mounted() {
    this.data.question = this.template.question;
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  watch: {
    selectedAnswer: {
      handler() {
        if (!this.selectedAnswer && this.selectedAnswer !== 0) return;
        if (typeof this.selectedAnswer === "object")
          this.data.answer = this.selectedAnswer
            .reduce((acc, id) => {
              const answer = this.template.answers[id];
              if (answer) acc.push(answer.long);
              return acc;
            }, [])
            .join("\n");
        else this.data.answer = this.template.answers[this.selectedAnswer].long;
      },
      immediate: true,
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <h5>Opções de filtros</h5>
    <div class="px-3">
      <v-row align="center">
        <v-btn-toggle color="primary" v-model="filterType" mandatory>
          <v-btn value="summary">
            <span>Resumida</span>
          </v-btn>
          <v-btn disabled value="full">
            <span>Completa</span>
          </v-btn>
        </v-btn-toggle>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      filterType: null,
    };
  },
  watch: {
    filterType: {
      handler() {
        this.$emit("input", { type: this.filterType, courtesy: true });
      },
      deep: true,
    },
  },
  mounted() {},
};
</script>

<style></style>

<template>
  <v-dialog
    v-model="isOpen"
    width="500"
    persistent
    scrollable
    content-class="rounded-lg"
  >
    <v-card class="h-full d-flex flex-column pb-2" style="max-height: 90dvh">
      <v-card-title>Nova Categoria</v-card-title>
      <v-card-text>
        <v-form v-model="valid" @submit.prevent>
          <v-text-field
            v-model="category.name"
            label="Nome da categoria"
            outlined
            dense
            class="mt-4"
            :rules="[
              (v) => !!v || 'Name é obrigatório',
              (v) =>
                (v && v.length <= 30) || 'Name deve ter menos de 30 caracteres',
            ]"
          />

          <icon-selector v-model="category.icon" />
        </v-form>
        <v-alert v-if="this.error" type="error">
          {{ this.error }}
        </v-alert>
      </v-card-text>

      <v-card-actions class="align-end gap-1 justify-space-between">
        <v-btn text :disabled="loading" @click="close"> Cancelar </v-btn>
        <v-btn
          :disabled="!valid"
          color="success"
          :loading="loading"
          @click="save"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import IconSelector from "../../../global/IconSelector.vue";
import PRODUCT from "@/services/admin/products";
export default {
  components: {
    IconSelector,
  },
  data: () => ({
    isOpen: false,
    loading: false,
    error: null,
    valid: false,
    category: {
      name: "",
      icon: "",
    },
  }),

  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },

  methods: {
    open() {
      this.isOpen = true;
      this.loading = false;
    },
    close() {
      this.isOpen = false;
      this.loading = false;
    },
    async save() {
      this.loading = true;
      this.error = null;
      let organizationId = this.selectedOrganization.id;
      try {
        if (this.category.id) {
          await PRODUCT.category.update(organizationId, this.category);
        } else {
          const data = await PRODUCT.category.create(
            organizationId,
            this.category
          );
          this.$root.$emit("category-created", data.category);
        }

        this.$emit("success");
        this.close();
      } catch (error) {
        this.error = error.message || "Erro ao salvar categoria";
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.$root.$on("category-modal", this.open);
  },
};
</script>

<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <div class="flex-grow-1">
        <i v-if="index === 0 && !variant.name && count === 1"
          >Variação Padrão</i
        >
        <i v-else-if="!variant.name"> Variação {{ index + 1 }} </i>
        <span v-else> {{ variant.name }} </span>
      </div>
      <div class="flex-grow-0 flex-shrink-1 d-flex align-center">
        <v-icon v-if="!variant.valid" color="red" small
          >mdi-alert-circle</v-icon
        >

        <v-btn
          v-if="count > 1"
          icon
          @click.stop="toggleDeleteState(index)"
          :loading="loadingVariant[index]"
        >
          <v-icon
            v-if="!loadingVariant[index]"
            :color="deleteConfirm === index ? 'red' : ''"
          >
            {{ deleteConfirm === index ? "mdi-delete-empty" : "mdi-delete" }}
          </v-icon>
        </v-btn>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-card elevation="0" class="pa-0"
        ><v-card-text class="pa-0">
          <v-form v-model="variant.valid" @click.stop>
            <div class="h-full d-flex flex-column">
              <v-text-field
                v-model="variant.name"
                label="Nome da variante"
                outlined
                dense
                class="mt-4"
                :rules="[
                  (v) => !!v || 'Name é obrigatório',
                  (v) =>
                    (v && v.length >= 3) ||
                    'Name deve ter mais de 3 caracteres',
                  (v) =>
                    (v && v.length <= 30) ||
                    'Name deve ter menos de 30 caracteres',
                ]"
              />
              <v-textarea
                v-model="variant.description"
                label="Descrição"
                outlined
                :counter="100"
                :rows="2"
                dense
                :rules="[
                  (v) =>
                    !v ||
                    v.length <= 100 ||
                    'Descrição deve ter menos de 100 caracteres',
                ]"
              />
              <v-text-field
                v-model="variant.price"
                :rules="[
                  (v) => !!v || 'Preço é obrigatório',
                  (v) => v > 0 || 'Preço deve ser maior que 0',
                ]"
                dense
                prefix="R$"
                label="Preço"
                type="number"
                step="0.01"
                outlined
              ></v-text-field>

              <div class="d-flex justify-space-between">
                <span class="mt-4" style="color: rgba(0, 0, 0, 0.6)"
                  >Especificações</span
                >
                <v-btn icon @click="addSpecification" class="my-2">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
              <div
                v-for="(spec, index) in variant.specifications"
                :key="index"
                class="d-flex"
              >
                <v-text-field
                  v-model="spec.key"
                  label="Nome"
                  outlined
                  dense
                  class="mr-2"
                />
                <v-text-field
                  v-model="spec.value"
                  label="Descrição"
                  outlined
                  dense
                />
                <v-btn icon @click="removeSpecification(index)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>

              <div class="mb-6 mt-3">
                <span>Imagem do Produto</span>
                <file-drop
                  @change="addVariants"
                  :disabled="!!loading"
                  :multiple="true"
                  accept=".png,.jpg,.jpeg,.webp,"
                  label="Arraste a imagem do produto ou clique para selecionar"
                />

                <v-card
                  outlined
                  class="pa-2 rounded-lg mt-2"
                  v-if="variant.Images.length"
                >
                  <vue-perfect-scrollbar
                    :settings="{
                      suppressScrollY: false,
                      wheelPropagation: false,
                    }"
                    class="w-full"
                  >
                    <div>
                      <div class="d-flex gap-2">
                        <div v-for="(img, i) in variant.Images" :key="i">
                          <v-img
                            height="60"
                            width="60"
                            class="rounded"
                            :aspect-ratio="1"
                            :src="img.url"
                            @click="deleteImage(i)"
                          >
                            <v-overlay
                              color="error"
                              opacity="0.7"
                              absolute
                              :value="confirmRemove === i"
                            >
                              <div>
                                <v-icon>mdi-delete-empty</v-icon>
                              </div>
                            </v-overlay>
                          </v-img>
                        </div>
                      </div>
                    </div>
                  </vue-perfect-scrollbar>
                </v-card>

                <!-- <p
              v-if="variant.image.url"
              class="px-2 text-center text-12 text--secondary"
            >
              Formato recomendado: proporção 4:3 e mínimo de 800x600px, peso
              máximo de 1MB.
            </p> -->
              </div>
            </div>
          </v-form>
        </v-card-text></v-card
      >
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapGetters } from "vuex";
import FileDrop from "../../../global/FileDrop.vue";
import PRODUCT from "@/services/admin/products";

export default {
  props: {
    index: Number,
    value: Object,
    count: Number,
    product: Object,
    loadingVariant: Array,
  },
  components: { FileDrop },
  data: () => ({
    loading: false,
    confirmRemove: false,
    confirmRemoveTimeout: null,
    deleteConfirm: null,

    variant: {
      id: null,
      valid: false,
      name: "",
      description: "",
      price: 0,
      Images: [],
      specifications: [],
    },
  }),
  watch: {
    value: {
      handler(value) {
        this.variant = Object.assign(this.variant, value);
      },
      deep: true,
    },
    variant: {
      handler(value) {
        this.$emit("input", value);
      },
      deep: true,
    },
  },
  methods: {
    toggleDeleteState(index) {
      if (this.deleteConfirm === index) {
        this.$emit("delete-variant", index);
        this.deleteConfirm = null;
      } else {
        this.deleteConfirm = index;
        clearTimeout(this.confirmRemoveTimeout);
        this.confirmRemoveTimeout = setTimeout(() => {
          this.deleteConfirm = null;
        }, 3000);
      }
    },
    addSpecification() {
      this.variant.specifications.push({ key: "", value: "" });
    },
    removeSpecification(index) {
      this.variant.specifications.splice(index, 1);
    },
    addVariants(files) {
      console.log(files);
      this.variant.Images.push(
        ...files.map((f) => ({
          url: URL.createObjectURL(f),
          file: f,
        }))
      );
    },

    async deleteImage(index) {
      const organizationId = this.selectedOrganization.id;
      const productId = this.product.id;
      const variantId = this.variant.id;
      const imageId = this.variant.Images[index].id;

      clearTimeout(this.confirmRemoveTimeout);

      if (this.confirmRemove !== index) {
        this.confirmRemove = index;
        this.confirmRemoveTimeout = setTimeout(() => {
          this.confirmRemove = false;
        }, 3000);
        return;
      }

      this.variant.Images.splice(index, 1);
      this.confirmRemove = false;

      try {
        await PRODUCT.deleteImage(
          organizationId,
          productId,
          variantId,
          imageId
        );
      } catch (error) {
        this.error = error;
        return;
      }
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  mounted() {
    if (this.value) this.variant = Object.assign(this.variant, this.value);
  },
};
</script>

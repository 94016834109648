import moment from "moment";
import applyedFilters from "../../utils/applyedFilters";
import header from "../../utils/header.js";

const PDFManager = require("@/utils/PDFManager").default;
const PAYMENT = require("@/utils/payment").default;
export default async function ({
  party,
  filter,
  reportData,
  ticketGroups,
  posSellers,
}) {
  const document = new PDFManager({
    orientation: "landscape",
    format: "a4",
    margin: 5,
    initialY: 10,
  });

  await header(document, party, `Pagamentos Reembolsados`);

  // applyedFilters(document, filter);

  const filteredPayments = reportData.filter((item) => item.status === 'refunded').sort((a, b) => moment(a.refundedAt).diff(moment(b.refundedAt)));


  const tableHeader = [
    "ID",
    "Valor",
    "Data Pagamento",
    "Data Reembolso",
    "Meio de Pagamento",
    "Forma de Pagamento",
  ];

  const tableBody = filteredPayments.map((item) => {
    debugger;
    return [
      `#${item.id.substring(0, 8)}`,
      formatCurrency(item.amount),
      moment(item.createdAt).format("DD/MM/YYYY HH:mm"),
      item.refundedAt ? moment(item.refundedAt).format("DD/MM/YYYY HH:mm") : 'N/A',
      PAYMENT.paymentType[item.paymentType]?.text || item.paymentType,
      PAYMENT.paymentMethod[item.paymentMethod]?.text || item.paymentType,
    ];
  });

  document.addTable(tableHeader, tableBody);

  return document;
}

const formatInt = (value) => {
  return new Intl.NumberFormat("pt-BR").format(value);
};

const formatCurrency = (value) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
};

<template>
  <div class="mt-2">
    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field
          label="Início das vendas"
          v-model="policies.onlineSaleStartsAt"
          :rules="[(v) => !v || moment(v).isValid() || 'Data inválida']"
          type="datetime-local"
          :disabled="loading"
          hide-details
          clearable
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          label="Fim das vendas online"
          v-model="policies.onlineSaleEndsAt"
          :rules="[
            (v) => !v || moment(v).isValid() || 'Data inválida',
            (v) =>
              !v ||
              !policies.onlineSaleStartsAt ||
              moment(v).isAfter(policies.onlineSaleStartsAt) ||
              'Data deve ser maior que a data de início das vendas',
          ]"
          type="datetime-local"
          :disabled="loading"
          hide-details
          clearable
          outlined
          dense
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PARTY from "@/services/admin/party";
import moment from "moment";

export default {
  data() {
    return {
      moment,
      policies: {
        onlineSaleStartsAt: null,
        onlineSaleEndsAt: null,
      },
    };
  },
  methods: {
    async save() {
      const onlineSaleStartsAt = this.policies.onlineSaleStartsAt
        ? moment(this.policies.onlineSaleStartsAt).toDate()
        : null;
      const onlineSaleEndsAt = this.policies.onlineSaleEndsAt
        ? moment(this.policies.onlineSaleEndsAt).toDate()
        : null;

      const partyUpdate = await PARTY.update({
        id: this.party.id,
        organizationId: this.party.organizationId,
        onlineSaleStartsAt,
        onlineSaleEndsAt,
      });

      this.policies.onlineSaleStartsAt = partyUpdate.party.onlineSaleStartsAt
        ? moment(partyUpdate.party.onlineSaleStartsAt).format(
            "YYYY-MM-DDTHH:mm"
          )
        : null;
      this.policies.onlineSaleEndsAt = partyUpdate.party.onlineSaleEndsAt
        ? moment(partyUpdate.party.onlineSaleEndsAt).format("YYYY-MM-DDTHH:mm")
        : null;

      return partyUpdate;
    },
    loadInitialData() {
      this.policies.onlineSaleStartsAt = this.party.onlineSaleStartsAt
        ? moment(this.party.onlineSaleStartsAt).format("YYYY-MM-DDTHH:mm")
        : null;
      this.policies.onlineSaleEndsAt = this.party.onlineSaleEndsAt
        ? moment(this.party.onlineSaleEndsAt).format("YYYY-MM-DDTHH:mm")
        : null;
    },
  },
  computed: {},
  watch: {
    party: {
      handler: function (val, oldVal) {
        this.loadInitialData();
      },
      deep: true,
    },
  },
  mounted() {
    this.loadInitialData();
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>

<template>
  <v-dialog
    v-model="isOpen"
    width="400"
    persistent
    scrollable
    content-class="rounded-lg"
  >
    <v-card class="h-full d-flex flex-column" style="max-height: 90dvh">
      <v-card-title> Apagar Lote • {{ ticketBlock.name }} </v-card-title>

      <v-card-text class="pb-1">
        <v-alert v-if="errorMessage.length" color="warning" text>
          <b>{{ errorMessage[0].title }}</b>
          <p class="mb-0">{{ errorMessage[0].description }}</p>
        </v-alert>
        <template v-else>
          <v-alert type="success" text>
            <b>Esse lote pode ser deletado!</b>
          </v-alert>
          <p class="text-center mb-0 font-weight-bold">
            Deseja mesmo deletar o lote {{ ticketBlock.name }}?
          </p>
        </template>

        <v-alert v-if="error" color="error" text>
          {{ error }}
        </v-alert>
      </v-card-text>

      <v-card-actions class="d-flex align-end gap-1 justify-space-between">
        <v-btn text @click="close" small :disabled="loading">Cancelar</v-btn>
        <v-btn
          v-if="!errorMessage.length"
          color="error"
          small
          @click="this.delete"
          :loading="loading"
        >
          <v-icon small left>mdi-delete</v-icon>
          Deletar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import TICKET from "@/services/admin/ticket";

export default {
  name: "TicketBlockDelete",
  data: () => ({
    isOpen: false,
    loading: false,
    error: false,

    rules: [
      {
        title: "Ingressos vendidos",
        description:
          "Não é possível deletar o lote pois ele possui ingressos vendidos!",
        verify: (ticketBlock) => !!ticketBlock._count.Ticket,
      },
      {
        title: "Kit vinculado",
        description:
          "Não é possível deletar o lote pois ele está vinculado a um kit!",
        verify: (ticketBlock) => !!ticketBlock.KitItem.length,
      },
    ],
  }),

  methods: {
    open() {
      this.isOpen = true;
      this.loading = false;
      this.error = false;
    },
    close() {
      this.isOpen = false;
      this.loading = false;
      this.error = false;
    },
    async delete() {
      try {
        this.loading = true;
        this.error = false;
        let orgId = this.selectedOrganization.id;
        let { partyId } = this.$route.params;

        await TICKET.deleteTicketBlock(orgId, partyId, this.ticketBlock.id);

        this.$emit("success");
        this.close();
      } catch (e) {
        this.error = e.message;
        this.loading = false;
      }
    },
  },

  computed: {
    ...mapGetters("auth", ["config"]),
    ...mapGetters("organization", ["memberships", "selectedOrganization"]),

    errorMessage() {
      return this.rules.filter((rule) => rule.verify(this.ticketBlock));
    },
  },
  mounted() {
    this.$root.$on("ticket-block-delete-modal", this.open);
  },
  props: {
    party: {
      type: Object,
      default: () => ({}),
    },
    ticketBlock: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {},
};
</script>

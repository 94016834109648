<template>
  <div class="d-flex flex-column gap-3">
    <v-data-table
      :headers="headers"
      :items="data?.cashouts || []"
      :loading="loading"
      :items-per-page="5"
      sort-by="createdAt"
      sort-desc
    >
      <template v-slot:item.id="{ item }">
        <span class="text-overline lh-1"> #{{ item.id | shortId }} </span>
      </template>

      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | date("DD/MM/YYYY HH:mm") }}
      </template>
      <template v-slot:item.amount="{ item }">
        {{ item.amount | currency }}
      </template>
      <template v-slot:item.User.name="{ item }">
        <div class="d-flex align-center gap-1">
          <base-avatar :src="item.User.photo" :seed="item.User.id" :size="18" />
          {{ item.User.name }}
        </div>
      </template>
      <template v-slot:item.printedAt="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              mdi-printer-pos-{{ item.printedAt ? "check" : "remove" }}
            </v-icon>
          </template>
          <div>
            <span v-if="item.printedAt" class="text-overline">
              {{ item.printedAt | date("DD/MM/YYYY HH:mm") }}
            </span>
            <span v-else>
              <v-icon small color="white" left>mdi-shield-key</v-icon>
              <b>{{ calculatePrintToken(item) }}</b>
            </span>
          </div>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import PAYMENT from "@/utils/payment";

export default {
  props: {
    session: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    calculatePrintToken(item) {
      const id = item.id;
      return id.replace(/\D/g, "").slice(0, 4);
    },
  },

  data: () => ({
    PAYMENT,
    headers: [
      {
        text: "",
        value: "printedAt",
        sortable: false,
        class: "px-0",
        cellClass: "px-0",
        width: 24,
      },
      { text: "#", value: "id", sortable: false },
      { text: "Data", value: "createdAt" },
      { text: "Valor", value: "amount" },
      { text: "Responsavel", value: "User.name" },
    ],
  }),
};
</script>

<style>
</style>
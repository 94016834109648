<template>
  <v-form v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" class="pb-0 pt-1">
        <v-text-field
          v-model="kit.name"
          :rules="[(v) => !!v || 'Nome é obrigatório']"
          label="Nome"
          required
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6" class="pb-0 pt-2">
        <v-text-field
          v-model="kit.price"
          :rules="[
            (v) => !!v || 'Preço é obrigatório',
            (v) => v > 0 || 'Preço deve ser maior que 0',
          ]"
          dense
          prefix="R$"
          label="Preço"
          type="number"
          step="0.01"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="6" class="pb-0 pt-2">
        <v-text-field
          v-model="kit.quantity"
          dense
          :rules="[
            (v) => !!v || 'Quantidade é obrigatório',
            (v) => v >= 1 || 'Quantidade deve ser maior ou igual a ' + 1,
          ]"
          label="Quantidade"
          type="number"
          :min="1"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="pb-0 pt-2">
        <v-text-field
          :max="new Date(party.endDate).toISOString().substr(0, 16)"
          v-model="kit.startDate"
          label="Inicio das vendas"
          type="datetime-local"
          :rules="dateRules.start"
          outlined
          dense
        />
      </v-col>
      <v-col cols="12" md="6" class="pb-0 pt-2">
        <v-text-field
          :label="`${kit.startType ? 'Fim' : 'Limite'} das vendas`"
          :max="new Date(party.endDate).toISOString().substr(0, 16)"
          v-model="kit.endDate"
          dense
          type="datetime-local"
          :rules="dateRules.end"
          outlined
        />
      </v-col>
      <v-col cols="4" class="pb-0 pt-0">
        <v-switch
          v-model="kit.onlineSale"
          label="Venda online"
          :messages="[
            kit.onlineSale
              ? 'Venda online permitida'
              : 'Venda online não permitida',
          ]"
        ></v-switch>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
const defaultKit = () => ({
  id: null,
  name: "",
  price: 0,
  quantity: 0,
  startDate: new Date().toISOString().substr(0, 16),
  endDate: new Date().toISOString().substr(0, 16),
  active: true,
  ticketGroup: null,
  startType: 0,
});
export default {
  emits: ["input"],
  data: () => ({
    valid: false,
    cacheDate: null,
  }),
  methods: {},
  watch: {
    valid(val) {
      this.$emit("valid", val);
    },
  },
  computed: {
    kit: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit("input", value);
      },
    },

    dateRules() {
      return {
        start: [
          (v) => !!v || "Data de início é obrigatória",
          (v) => new Date(v).getFullYear() < 9999 || "Data de início inválida",
          (v) =>
            v < this.kit.endDate ||
            "Data de início deve ser menor que a data de fim",
        ],
        end: [
          (v) => !!v || "Data de fim é obrigatória",
          (v) => new Date(v).getFullYear() < 9999 || "Data de fim inválida",
          (v) =>
            !this.kit.startType ||
            v >= this.kit.startDate ||
            "Data de fim deve ser maior que a data de início",
        ],
      };
    },
  },
  props: {
    value: {
      type: Object,
      default: defaultKit,
    },
    party: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style></style>

<template>
  <v-card outlined :loading="loading">
    <v-card-text class="d-flex flex-wrap justify-space-around px-">
      <div class="text-center px-4">
        <h6 class="mt-2 mb-0">
          {{
            (reducedPayments.tickets - (reducedPayments.courtesy || 0)) | number
          }}
        </h6>
        <span class="text-caption">Ingressos Vendidos</span>
      </div>
      <div class="text-center px-4">
        <h6 class="mt-2 mb-0">
          {{ (reducedPayments.courtesy || 0) | number }}
        </h6>
        <span class="text-caption">Cortesias </span>
      </div>
      <div class="text-center px-4">
        <h6 class="mt-2 mb-0">{{ reducedPayments.tickets | number }}</h6>
        <span class="text-caption">Ingressos Emitidos</span>
      </div>
      <div class="text-center px-4">
        <h6 class="mt-2 mb-0">
          {{
            ((reducedPayments.amount - reducedPayments.fee) /
              reducedPayments.tickets)
              | currency
          }}
        </h6>
        <span class="text-caption">Ticket Médio</span>
      </div>
      <div class="text-center px-4">
        <h6 class="mt-2 mb-0">
          {{ (reducedPayments.amount - reducedPayments.fee) | currency }}
        </h6>
        <span class="text-caption">Valor Líquido</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  data: () => ({}),
  computed: {
    approvedTickets() {
      return this.data.tickets.filter(
        ({ Payment: p }) => p.status === "succeeded"
      );
    },

    reducedPayments() {
      return this.approvedTickets.reduce(
        (acc, t) => {
          const p = t.Payment;
          const amount = parseFloat(t.amount || 0);
          const platformFee = parseFloat(t.platformFee || 0);

          if (
            p.payedAt &&
            moment(p.createdAt).local().isSame(moment().local(), "day")
          ) {
            acc.today.tickets += 1;
            acc.today.amount += amount;
            acc.today.fee += platformFee;
          }

          if (p.paymentType === "COURTESY") acc.courtesy += 1;

          acc.tickets += 1;
          acc.amount += amount;
          acc.fee += platformFee;
          return acc;
        },
        {
          tickets: 0,
          amount: 0,
          fee: 0,
          today: {
            tickets: 0,
            amount: 0,
            fee: 0,
          },
          courtesy: 0,
        }
      );
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style></style>

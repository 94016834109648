<template>
  <div class="d-flex flex-column align-center">
    <v-carousel
      v-model="focusedIndex"
      :cycle="!disabledCycle && !fullScreen"
      hide-delimiters
      :show-arrows="items.length > 1"
      show-arrows-on-hover
      :height="'auto'"
      class="rounded-lg"
      :style="{
        maxHeight: maxSize,
        minHeight: minSize,
        maxWidth: maxSize,
        minWidth: minSize,
      }"
    >
      <v-carousel-item v-if="items.length === 0">
        <v-card outlined class="rounded-xl" color="#43434340">
          <v-responsive :aspect-ratio="1">
            <v-card-text
              class="h-full d-flex flex-column gap-5 justify-center align-center"
            >
              <v-icon size="100">mdi-image-off</v-icon>
              <span>Resolução recomendada 600x600px</span>
            </v-card-text>
          </v-responsive>
        </v-card>
      </v-carousel-item>
      <v-carousel-item v-for="(item, i) in items" :key="i" class="rounded-lg">
        <v-img
          :src="item.src"
          :alt="item.variant?.name"
          :aspect-ratio="1"
          class="rounded-xl custom-cursor"
          @click="fullScreen = true"
        >
          <template v-slot:placeholder>
            <v-card elevation="0" class="h-full">
              <v-skeleton-loader
                type="image"
                class="rounded-lg"
                height="100%"
              />
            </v-card>
          </template>
          <div
            class="pa-4"
            style="position: absolute; top: 0; left: 0; right: 0; bottom: 0"
          >
            <v-chip
              v-if="item.variant && variants.length > 1"
              color="primary"
              small
            >
              {{ item.variant.name }}
            </v-chip>
          </div>
        </v-img>
      </v-carousel-item>
    </v-carousel>
    <div v-if="miniatures" style="margin-top: -40px" class="w-full px-3 d-flex justify-center">
      <v-card
        class="pa-1 rounded-lg"
      >
        <v-alert color="warning" dense class="mb-0" text v-if="!items.length">
          Não há imagens cadastradas
        </v-alert>
        <vue-perfect-scrollbar
          :settings="{
            suppressScrollY: false,
            wheelPropagation: false,
          }"
          class="w-full"
          ref="miniatures"
        >
          <div>
            <div class="d-flex gap-1">
              <div v-for="(img, i) in items" :key="i" @click="openItem(i)">
                <v-img
                  :ref="`miniature-${i}`"
                  :height="miniaturesSize"
                  :width="miniaturesSize"
                  class="rounded"
                  :aspect-ratio="1"
                  :src="img.src"
                  :style="{
                    cursor: 'pointer',
                    border:
                      focusedIndex === i
                        ? '2px solid var(--v-primary-base)'
                        : '2px solid transparent',
                  }"
                >
                  <template v-slot:placeholder>
                    <v-card elevation="0" class="h-full">
                      <v-skeleton-loader
                        type="image"
                        class="rounded"
                        height="100%"
                      />
                    </v-card>
                  </template>
                </v-img>
              </div>
            </div>
          </div>
        </vue-perfect-scrollbar>
      </v-card>
    </div>
    <v-dialog
      content-class="dialog-no-shadow"
      v-model="fullScreen"
      overlay-opacity="0.7"
    >
      <v-img
        height="90vh"
        contain
        :src="items[focusedIndex]?.src"
        @click="fullScreen = false"
      />
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
    variants: {
      type: Array,
      default: () => [],
    },
    minSize: {
      type: String,
      default: "300px",
    },
    maxSize: {
      type: String,
      default: "500px",
    },
    miniatures: {
      type: Boolean,
      default: true,
    },
    miniaturesSize: {
      type: String,
      default: "60px",
    },
  },
  data: () => ({
    focusedIndex: 0,
    disabledCycle: false,
    timeout: null,
    fullScreen: false,
  }),
  methods: {
    openItem(index) {
      this.focusedIndex = index;
    },
    getImageUrl(image) {
      if (typeof image === "string") return image;
      if (image.url) return image.url;
      if (image.file) return URL.createObjectURL(image.file);
      return null;
    },
    centralizeMiniature(i) {
      var [miniature] = this.$refs[`miniature-${i}`] || [];
      if (!miniature) return;
      miniature = miniature.$el;

      const container = this.$refs.miniatures.$el;
      const scrollLeft =
        miniature.offsetLeft -
        container.offsetWidth / 2 +
        miniature.offsetWidth / 2;

      container.scrollTo({
        left: scrollLeft,
      });
    },
    focusVariant(variant) {
      const variantId = variant.id;
      const index = this.items.findIndex(
        (item) => item.variant && item.variant.id === variantId
      );
      if (index < 0) return;

      this.focusedIndex = index;
      this.$nextTick(() => {
        this.disabledCycle = true;
        clearInterval(this.interval);
        this.interval = setInterval(() => {
          this.disabledCycle = false;
        }, 15 * 1000);
      });
    },
  },
  computed: {
    items() {
      const items = [];
      if (this.product.image) {
        const productUrl = this.getImageUrl(this.product.image);
        if (productUrl)
          items.push({
            src: productUrl,
          });
      }
      this.variants.forEach((variant) => {
        if (!variant.Images || !variant.Images.length) return;
        variant.Images.forEach((image) => {
          const variantUrl = this.getImageUrl(image);
          if (variantUrl)
            items.push({
              src: variantUrl,
              variant,
            });
        });
      });
      return items;
    },
  },
  watch: {
    focusedIndex: {
      handler(i) {
        this.centralizeMiniature(i);
        this.disabledCycle = false;
      },
      immediate: true,
    },
    product() {
      this.focusedIndex = 0;
    },
  },
  mounted() {
    this.$root.$on("focus-variant", this.focusVariant);
  },
};
</script>
<style>
.custom-cursor {
  cursor: zoom-in !important; /* Cursor para navegadores modernos */
  cursor: pointer; /* Fallback para navegadores antigos */
}
</style>

<template>
  <v-card
    class="pa-4 mt-6 rounded-xl"
    elevation="5"
    style="
      position: fixed;
      bottom: 2%;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
    "
  >
    <div class="d-flex justify-space-between pb-2">
      <span class="font-weight-bold">Modo Técnico</span>
      <span class="font-weight-bold">{{ selectedCountMessage }} </span>
    </div>
    <div class="d-flex gap-2">
      <v-btn
        color="secondary"
        @click="toggleSelectionMode"
        :loading="loading === 'selectTicketGroups'"
        :disabled="!!loading"
        small
        depressed
      >
        {{ selectionMode ? "Cancelar seleção" : "Selecionar lotes" }}
      </v-btn>
      <v-divider vertical class="mx-2" />
      <div v-for="(button, index) in filteredButtons" :key="index">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-2"
              :color="buttonsFeedback[button.id].color"
              @click="button.action(button.id)"
              :disabled="
                buttonsFeedback[button.id].disabled || buttonDisabled(button)
              "
              :loading="buttonsFeedback[button.id].loading"
              small
              depressed
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>{{ buttonsFeedback[button.id].icon }}</v-icon>
            </v-btn>
          </template>
          <span>{{ button.name }}</span>
        </v-tooltip>
      </div>
    </div>
    <turn-ticket-blocks :party="party" @refresh="$emit('refresh')" />
  </v-card>
</template>

<script>
import TICKET from "@/services/admin/ticket";
import TurnTicketBlocks from "./TurnTicketBlocks.vue";

export default {
  name: "TicketManagerOpMode",
  components: {
    TurnTicketBlocks,
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
    ticketGroups: {
      type: Array,
      required: true,
    },
    selectedTicketBlocks: {
      type: Array,
      required: true,
    },
    currentTicketGroupId: {
      type: String,
      default: null,
    },
  },
  data() {
    const buttons = [
      {
        id: "copyTicketGroups",
        name: "Copiar todos setores",
        icon: "mdi-content-copy",
        color: "secondary",
        action: (id) => this.copyTicketGroups(id),
        rule: (that) => !that.selectionMode,
        disabled: (that, button) =>
          that.buttonsFeedback[button.id].loading ||
          that.ticketGroups.length === 0,
      },
      {
        id: "copySelected",
        name: "Copiar selecionados",
        icon: "mdi-content-copy",
        color: "secondary",
        action: (id) => this.copySelected(id),
        rule: (that) => that.selectionMode,
        disabled: (that, button) =>
          that.buttonsFeedback[button.id].loading ||
          that.selectedTicketBlocks.length === 0,
      },
      {
        id: "inactivateTicketBlocks",
        name: "Inativar selecionados",
        icon: "mdi-block-helper",
        color: "secondary",
        action: (id) => this.inactivateTicketBlocks(id),
        rule: (that) => that.selectionMode,
        disabled: (that, button) =>
          that.buttonsFeedback[button.id].loading ||
          that.selectedTicketBlocks.length === 0 ||
          that.selectedTicketBlocks.every((tb) => !tb.active),
      },
      {
        id: "activateTicketBlocks",
        name: "Ativar selecionados",
        icon: "mdi-check-circle",
        color: "secondary",
        action: (id) => this.activateTicketBlocks(id),
        rule: (that) => that.selectionMode,
        disabled: (that, button) =>
          that.buttonsFeedback[button.id].loading ||
          that.selectedTicketBlocks.length === 0 ||
          that.selectedTicketBlocks.every((tb) => tb.active),
      },
      {
        id: "turnTicketBlocks",
        name: "Virar selecionados",
        icon: "mdi-sync",
        color: "secondary",
        action: (id) => this.turnTicketBlocks(id),
        rule: (that) => that.selectionMode,
        disabled: (that, button) =>
          that.buttonsFeedback[button.id].loading ||
          that.selectedTicketBlocks.length === 0,
      },
      {
        id: "pasteSelected",
        name: "Colar selecionados",
        icon: "mdi-content-paste",
        color: "secondary",
        action: (id) => this.pasteSelected(id),
        rule: (that) => that.selectionMode && !!that.currentTicketGroupId,
        disabled: (that, button) =>
          that.buttonsFeedback[button.id].loading || !that.currentTicketGroupId,
      },
      {
        id: "pasteTicketGroups",
        name: "Colar setores",
        icon: "mdi-content-paste",
        color: "secondary",
        action: (id) => this.pasteTicketGroups(id),
        rule: (that) => !that.selectionMode,
        disabled: (that, button) => that.buttonsFeedback[button.id].loading,
      },
    ];

    const buttonsFeedback = {};
    buttons.forEach((button) => {
      buttonsFeedback[button.id] = {
        color: button.color || "primary",
        disabled: false,
        loading: false,
        icon: button.icon || "",
      };
    });

    return {
      loading: false, // Pode ser mantido para estados globais
      selectionMode: false,
      buttons,
      buttonsFeedback,
    };
  },
  methods: {
    buttonDisabled(button) {
      if (button.disabled) return button.disabled(this, button);
      return false;
    },
    resetButtonFeedback(id) {
      const button = this.buttons.find((btn) => btn.id === id);
      if (button) {
        this.buttonsFeedback[id] = {
          color: button.color || "primary",
          disabled: false,
          loading: false,
          icon: button.icon || "",
        };
      }
    },
    toggleSelectionMode() {
      this.selectionMode = !this.selectionMode;
      this.$emit("toggleSelectionMode", this.selectionMode);
    },
    writeToClipboard(text) {
      return navigator.clipboard.writeText(text);
    },
    incrementName(name) {
      const regex = /(\d+)/g;
      const matches = name.match(regex);
      if (matches && matches.length > 0) {
        const lastNumber = matches[matches.length - 1];
        const incrementedNumber = parseInt(lastNumber) + 1;
        const index = name.lastIndexOf(lastNumber);
        return (
          name.substring(0, index) +
          incrementedNumber +
          name.substring(index + lastNumber.length)
        );
      } else {
        return name + " 2";
      }
    },
    copyTicketGroups(id) {
      this.buttonsFeedback[id].loading = true;
      this.buttonsFeedback[id].disabled = true;
      const ticketGroups = this.ticketGroups.map((tg) => {
        return {
          ...tg,
          type: "ticketGroup",
          TicketBlock: tg.TicketBlock.map((tb) => ({
            ...tb,
            type: "ticketBlock",
            id: undefined,
          })),
          id: undefined,
        };
      });

      this.writeToClipboard(JSON.stringify(ticketGroups))
        .then(() => {
          this.buttonsFeedback[id].icon = "mdi-check";
          this.buttonsFeedback[id].color = "success";
        })
        .catch(() => {
          this.buttonsFeedback[id].icon = "mdi-alert";
          this.buttonsFeedback[id].color = "error";
        })
        .finally(() => {
          this.buttonsFeedback[id].loading = false;
          this.buttonsFeedback[id].disabled = false;
          setTimeout(() => {
            this.resetButtonFeedback(id);
          }, 1000);
        });
    },
    copySelected(id) {
      this.buttonsFeedback[id].loading = true;
      this.buttonsFeedback[id].disabled = true;
      const ticketBlocks = this.selectedTicketBlocks.map((tb) => {
        return {
          ...tb,
          type: "ticketBlock",
          id: undefined,
        };
      });

      this.writeToClipboard(JSON.stringify(ticketBlocks))
        .then(() => {
          this.buttonsFeedback[id].icon = "mdi-check";
          this.buttonsFeedback[id].color = "success";
        })
        .catch(() => {
          this.buttonsFeedback[id].icon = "mdi-alert";
          this.buttonsFeedback[id].color = "error";
        })
        .finally(() => {
          this.buttonsFeedback[id].loading = false;
          this.buttonsFeedback[id].disabled = false;
          setTimeout(() => {
            this.resetButtonFeedback(id);
          }, 1000);
        });
    },
    inactivateTicketBlocks(id) {
      this.buttonsFeedback[id].loading = true;
      this.buttonsFeedback[id].disabled = true;

      this.toggleProp(id, "active", false)
        .then(() => {
          this.buttonsFeedback[id].icon = "mdi-check";
          this.buttonsFeedback[id].color = "success";
        })
        .catch(() => {
          this.buttonsFeedback[id].icon = "mdi-alert";
          this.buttonsFeedback[id].color = "error";
        })
        .finally(() => {
          this.buttonsFeedback[id].loading = false;
          this.buttonsFeedback[id].disabled = false;
          setTimeout(() => {
            this.resetButtonFeedback(id);
          }, 1000);
        });
    },
    activateTicketBlocks(id) {
      this.buttonsFeedback[id].loading = true;
      this.buttonsFeedback[id].disabled = true;

      this.toggleProp(id, "active", true)
        .then(() => {
          this.buttonsFeedback[id].icon = "mdi-check";
          this.buttonsFeedback[id].color = "success";
        })
        .catch(() => {
          this.buttonsFeedback[id].icon = "mdi-alert";
          this.buttonsFeedback[id].color = "error";
        })
        .finally(() => {
          this.buttonsFeedback[id].loading = false;
          this.buttonsFeedback[id].disabled = false;
          setTimeout(() => {
            this.resetButtonFeedback(id);
          }, 1000);
        });
    },
    toggleProp(id, prop, value) {
      return new Promise(async (resolve, reject) => {
        try {
          const orgId = this.party.organizationId;
          const partyId = this.party.id;

          const updates = this.selectedTicketBlocks.map(async (ticketBlock) => {
            const data = { ...ticketBlock };
            data[prop] = value;

            data.sellers = ticketBlock.sellers;
            data.posSellers = ticketBlock.posSellers;

            await TICKET.updateTicketBlock(orgId, partyId, data);

            ticketBlock[prop] = value;
          });

          await Promise.all(updates);

          this.$emit("refresh");
          resolve();
        } catch (error) {
          console.error(error);
          reject(error);
        }
      });
    },
    turnTicketBlocks(id) {
      this.$root.$emit("turn-ticket-blocks-modal", this.selectedTicketBlocks);
    },
    async pasteSelected(id) {
      try {
        this.buttonsFeedback[id].loading = true;
        this.buttonsFeedback[id].disabled = true;

        if (!this.currentTicketGroupId) {
          throw new Error("Nenhum setor selecionado para colar os lotes");
        }

        const text = await navigator.clipboard.readText();
        const ticketBlocks = JSON.parse(text);

        if (!Array.isArray(ticketBlocks)) throw new Error("Conteúdo inválido");
        if (ticketBlocks.some((tb) => tb.type !== "ticketBlock"))
          throw new Error("Conteúdo inválido");

        const currentTicketGroup = this.ticketGroups.find(
          (tg) => tg.id === this.currentTicketGroupId
        );
        if (!currentTicketGroup) {
          throw new Error("Setor atual não encontrado");
        }

        for (const tb of ticketBlocks) {
          const newName = this.incrementName(tb.name);

          const newTicketBlock = {
            ...tb,
            ticketGroup: this.currentTicketGroupId,
            name: newName,
          };

          await TICKET.createTicketBlock(
            this.party.organizationId,
            this.party.id,
            newTicketBlock
          );
        }

        this.buttonsFeedback[id].icon = "mdi-check";
        this.buttonsFeedback[id].color = "success";
        this.$emit("refresh");
      } catch (error) {
        console.error(error);
        this.buttonsFeedback[id].icon = "mdi-alert";
        this.buttonsFeedback[id].color = "error";
      } finally {
        this.buttonsFeedback[id].loading = false;
        this.buttonsFeedback[id].disabled = false;
        setTimeout(() => {
          this.resetButtonFeedback(id);
        }, 1000);
      }
    },
    async pasteTicketGroups(id) {
      try {
        this.buttonsFeedback[id].loading = true;
        this.buttonsFeedback[id].disabled = true;

        const text = await navigator.clipboard.readText();
        const ticketGroups = JSON.parse(text);

        if (!Array.isArray(ticketGroups)) throw new Error("Conteúdo inválido");
        if (ticketGroups.some((tg) => tg.type !== "ticketGroup"))
          throw new Error("Conteúdo inválido");

        for (const tg of ticketGroups) {
          const newGroupName = this.incrementName(tg.name);

          const ticketGroupData = {
            ...tg,
            TicketBlock: undefined,
            id: undefined,
            name: newGroupName,
          };

          const { ticketGroup } = await TICKET.createTicketGroup(
            this.party.organizationId,
            this.party.id,
            ticketGroupData
          );

          for (const tb of tg.TicketBlock) {
            const newTicketBlockName = this.incrementName(tb.name);

            const newTicketBlock = {
              ...tb,
              ticketGroup: ticketGroup.id,
              id: undefined,
              name: newTicketBlockName,
            };

            await TICKET.createTicketBlock(
              this.party.organizationId,
              this.party.id,
              newTicketBlock
            );
          }
        }

        this.buttonsFeedback[id].icon = "mdi-check";
        this.buttonsFeedback[id].color = "success";
        this.$emit("refresh");
      } catch (error) {
        console.error(error);
        this.buttonsFeedback[id].icon = "mdi-alert";
        this.buttonsFeedback[id].color = "error";
      } finally {
        this.buttonsFeedback[id].loading = false;
        this.buttonsFeedback[id].disabled = false;
        setTimeout(() => {
          this.resetButtonFeedback(id);
        }, 1000);
      }
    },
  },
  computed: {
    selectedCountMessage() {
      if (!this.selectionMode) return "";
      const count = this.selectedTicketBlocks.length;
      if (count === 0) {
        return "Nenhum lote selecionado";
      } else if (count === 1) {
        return "1 Lote selecionado";
      } else {
        return `${count} Lotes selecionados`;
      }
    },
    filteredButtons() {
      return this.buttons.filter((btn) => {
        if (btn.rule) return btn.rule(this);
        return true;
      });
    },
  },
};
</script>

<template>
  <div class="d-flex gap-2">
    <v-text-field
      outlined
      dense
      :value="price"
      @input="updatePrice($event)"
      hide-details
      style="max-width: 110px; min-width: 110px"
      prefix="R$"
      placeholder="Preço"
      :rules="priceRules"
    ></v-text-field>
    <v-text-field
      outlined
      dense
      :value="quantity"
      @input="updateQuantity($event)"
      hide-details
      style="max-width: 110px; min-width: 110px"
      placeholder="Quantidade"
      :rules="quantityRules"
    ></v-text-field>
  </div>
</template>

<script>

export default {
  props: {
    price: {
      type: String,
      default: "",
    },
    quantity: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      valid: false,
    };
  },
  watch: {
    price() {
      this.checkValid();
    },
    quantity() {
      this.checkValid();
    },
  },
  methods: {
    updatePrice(value) {
      this.$emit("price", value);
    },
    updateQuantity(value) {
      this.$emit("quantity", value);
    },
    checkValid() {
      const priceValid = this.priceRules.every(
        (rule) => rule(this.price) === true
      );
      const quantityValid = this.quantityRules.every(
        (rule) => rule(this.quantity) === true
      );

      const isValid = priceValid && quantityValid;

      if (isValid !== this.valid) {
        this.valid = isValid;
        this.$emit("valid", this.valid);
      }
    },
  },
  computed: {
    priceRules() {
      if (!this.quantity) {
        return [(v) => true];
      }
      return [
        (v) => !!v || "Preço é obrigatório",
        (v) => v > 0 || "O preço deve ser maior que 0",
      ];
    },
    quantityRules() {
      if (!this.price) {
        return [(v) => true];
      }
      return [
        (v) => !!v || "Quantidade é obrigatória",
        (v) => v > 0 || "A quantidade deve ser maior que 0",
      ];
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <v-scroll-y-transition>
      <v-alert v-if="!!error" type="error" class="mt-1">
        {{ error }}
      </v-alert>
    </v-scroll-y-transition>
    <div v-if="!error">
      <div class="d-flex justify-space-between align-center mt-2">
        <h6 class="mb-0">Atrações</h6>
        <v-btn
          @click="newAttraction"
          color="primary"
          :small="$vuetify.breakpoint.mdAndDown"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">mdi-plus</v-icon>
          <template v-if="$vuetify.breakpoint.smAndUp">Atração</template>
        </v-btn>
      </div>
      <v-progress-linear
        v-if="loading"
        color="primary"
        indeterminate
        class="mt-2"
      />
      <v-alert
        v-if="!loading && !attractions.length"
        color="info"
        class="mt-2 mb-0"
        text
      >
        <p class="text-18 font-weight-bold lh-1">
          Seu evento ainda não possui atrações.
        </p>
        <p class="mb-0">
          As atrações permitem criar um cronograma para seu evento. Deixe seu
          evento mais organizado e atraente para os participantes.
        </p>
      </v-alert>

      <v-timeline v-else align-top dense>
        <template v-for="(period, i) in attractionsAndPeriods">
          <v-timeline-item
            v-if="period.type == 'period'"
            :key="i"
            hide-dot
            class="pb-0"
          >
            <v-divider v-if="i" class="mb-4" />
            <div class="d-flex flex-column align-center gap-0 mb-4">
              <h5 class="mb-0">
                {{ period.name || `Período ${period.index + 1}` }}
                <v-btn
                  @click="editAttraction({ periodId: period.id })"
                  color="primary"
                  x-small
                  class="ml-2"
                >
                  <v-icon small left>mdi-plus</v-icon>
                  Atração
                </v-btn>
              </h5>
              <span class="text-overline">
                {{ period | startEndDate("startDate", "endDate") }}
              </span>
              <p class="text-center mb-0" style="max-width: 500px">
                {{ period.description }}
              </p>
            </div>
            <div
              v-if="
                attractionsAndPeriods.length == i + 1 ||
                attractionsAndPeriods[i + 1].type == 'period'
              "
            >
              <v-alert type="info" text border="left" class="mt-1" dense>
                Esse período ainda não tem atrações.
              </v-alert>
            </div>
          </v-timeline-item>
          <template v-else>
            <attraction-row class="pb-0" :key="i" :attraction="period" />
            <v-timeline-item hide-dot :key="`edit-${i}`" class="pb-8">
              <v-btn
                block
                @click="editAttraction(period)"
                :disabled="loading"
                class="mr-2"
                text
                :color="period.type == 'attraction:invalid' ? 'error' : ''"
              >
                <v-icon small left>
                  mdi-{{
                    period.type == "attraction:invalid" ? "alert" : "pencil"
                  }}
                </v-icon>
                {{
                  period.type == "attraction:invalid" ? "Corrigir" : "Editar"
                }}
              </v-btn>
            </v-timeline-item>
          </template>
        </template>
      </v-timeline>
    </div>
    <attraction-modal :party="party" @success="getAttractions" />
  </div>
</template>

<script>
import moment from "moment";
import ORGANIZATION from "@/services/admin/organization";
const PARTY = ORGANIZATION.party;
import AttractionModal from "./modal/AttractionModal.vue";
import AttractionCategories from "@/definitions/AttractionCategories.json";
import AttractionRow from "../../global/party/AttractionRow.vue";

export default {
  components: { AttractionModal, AttractionRow },
  data: () => ({
    loading: false,
    error: false,
    search: "",
    attractions: [],
    categories: AttractionCategories,
  }),
  methods: {
    newAttraction() {
      this.$emit("attraction-modal");
    },
    editAttraction(attraction) {
      this.$emit("attraction-modal", attraction);
    },
    sortAttractions(a, b) {
      if (a.timed && b.timed) return moment(a.start).diff(moment(b.start));
      if (a.timed) return -1;
      if (b.timed) return 1;
      return 0;
    },
    async getAttractions() {
      this.loading = true;
      try {
        const { attractions } = await PARTY.attraction.getAll(
          this.party.organizationId,
          this.party.id
        );
        this.attractions = attractions;
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    attractionsAndPeriods() {
      const response = [];

      const periods = this.party.Period || [];

      if (!periods.length) return this.attractions.sort(this.sortAttractions);

      periods.forEach((period, i) => {
        response.push({
          type: "period",
          index: i,
          ...period,
        });

        this.attractions
          .filter((attraction) => attraction.periodId === period.id)
          .sort(this.sortAttractions)
          .forEach((attraction) => {
            response.push({
              type: "attraction",
              ...attraction,
            });
          });
      });

      //  invalidAttractions
      this.attractions
        .filter((attraction) => !attraction.periodId)
        .forEach((attraction) => {
          // add in start of array
          response.unshift({
            type: "attraction:invalid",
            ...attraction,
          });
        });

      return response;
    },
  },
  mounted() {
    this.getAttractions();
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <v-dialog v-model="isOpen" width="800" scrollable>
      <v-card v-if="!!guest">
        <v-card-title>
          <base-avatar
            v-if="!!guest.name"
            :seed="guest.id"
            :src="guest.photo"
            :size="50"
          />
          <v-avatar v-else color="grey lighten-3" size="50">
            <v-icon color="grey darken-1">mdi-account-question</v-icon>
          </v-avatar>
          <div class="pl-4 text-truncate">
            <p class="mb-0 text-subtitle-2 font-medium">
              {{ guest.name || "Conta não criada" }}
            </p>
            <p class="mb-0 text-caption">
              <a
                :href="
                  'https://wa.me/55' +
                  guest.phone.replace(/[^0-9]/g, '') +
                  '?text=Olá ' +
                  (guest.name || '') +
                  '!'
                "
                target="_blank"
                >{{ guest.phone }}</a
              >
            </p>
            <p class="mb-0 text-caption">
              <a :href="'mailto:' + guest.email" target="_blank">
                {{ guest.email }}
              </a>
            </p>
          </div>
        </v-card-title>

        <v-card-text v-if="data" class="d-flex flex-column gap-3">
          <v-card :loading="loading" outlined class="pa-3 pb-0">
            <guest-payments
              :guest="guest"
              :tickets="data?.tickets"
              @refresh="refresh"
            />
            <!-- <guest-tickets :tickets="data.tickets" @refresh="refresh" /> -->
          </v-card>
          <v-card
            v-if="data.ticketTransfer.length"
            :loading="loading"
            outlined
            class="pa-3 pb-0"
          >
            <guest-ticket-transfer
              :guest="guest"
              :ticketTransfer="data.ticketTransfer"
              @refresh="refresh"
            />
          </v-card>
        </v-card-text>
        <v-card-text v-else>
          <div v-if="loading && !error" class="d-flex justify-center pa-8">
            <v-progress-circular indeterminate color="primary" />
          </div>
          <v-alert
            v-if="error"
            dense
            outlined
            color="error"
            icon="mdi-alert"
            border="left"
          >
            {{ error }}
          </v-alert>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn text :disabled="loading" @click="close" block> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <transfer-ticket :party="party" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import GuestPayments from "./GuestPayments.vue";
import GuestTicketTransfer from "./GuestTicketTransfer.vue";
import PARTY from "@/services/admin/party";

import TransferTicket from "../TransferTicket.vue";

export default {
  components: { TransferTicket, GuestPayments, GuestTicketTransfer },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isOpen: false,
    loading: false,
    error: false,

    guest: null,
    data: null,
  }),

  methods: {
    refresh(close = false) {
      this.$emit("refresh");
      if (close) this.close();
    },
    open(guest) {
      this.isOpen = true;
      this.guest = guest;
      this.data = null;
      this.getGuest();
    },
    close() {
      this.isOpen = false;
      this.loading = false;
      this.error = false;
      this.$nextTick(() => (this.guest = null));
    },
    async getGuest() {
      try {
        this.loading = true;
        const data = await PARTY.reports.guestDetail(
          this.party.organizationId,
          this.party.id,
          this.guest.id
        );
        this.data = data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["hasPermission"]),
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  watch: {},
  mounted() {
    this.$parent.$on("guest-profile", this.open);
    this.$root.$on("refund-ticket:success", this.getGuest);
  },
  beforeDestroy() {
    this.$root.$off("refund-ticket:success", this.getGuest);
  },
};
</script>

<style></style>

<template>
  <div>
    <h5>Opções de filtros</h5>
    <div class="d-flex flex-wrap gap-4 pb-3 justify-space-between align-start">
      <v-card outlined class="pa-3 flex-grow">
        <v-switch
          hide-details
          class="mt-0"
          v-model="filter.courtesy"
          label="Incluir cortesias"
        ></v-switch>
      </v-card>
      <v-card outlined class="pa-3 flex-grow">
        <v-switch
          hide-details
          class="mt-0"
          v-model="filter.allDates"
          label="Incluir todas as datas"
        ></v-switch>
        <div class="mt-3" v-if="!filter.allDates">
          <v-chip-group
            mandatory
            show-arrows
            v-model="filter.selectedRangeDate"
            style="max-width: 290px; min-width: 100%"
            color="primary"
          >
            <v-chip
              v-for="date in dateOptions"
              :key="date.value"
              :value="date.value"
              :input-value="date.value"
              label
            >
              {{ date.text }}
            </v-chip>
          </v-chip-group>
          <div class="d-flex justify-center">
            <v-date-picker
              v-if="filter.selectedRangeDate === 'custom'"
              v-model="filter.dates"
              range
            ></v-date-picker>
            <span v-else>{{ formattedDateRange }}</span>
          </div>
        </div>
      </v-card>
      <v-card outlined class="pa-3 flex-grow">
        <v-switch
          hide-details
          class="mt-0"
          v-model="filter.allTicketBlocks"
          label="Incluir todos os setores"
        ></v-switch>
        <ticket-block-selector
          class="mt-5"
          v-if="!filter.allTicketBlocks"
          v-model="filter.ticketBlocks"
          :ticketGroups="ticketGroups"
          outlined
          startSelectAll
        ></ticket-block-selector>
      </v-card>
      <v-card outlined class="pa-3 flex-grow">
        <v-switch
          hide-details
          class="mt-0"
          v-model="filter.allPosSellers"
          label="Incluir todos os PDV's"
        ></v-switch>
        <v-autocomplete
          dense
          outlined
          v-if="!filter.allPosSellers"
          hide-details
          return-object
          item-value="id"
          :items="posSellers"
          multiple
          :filter="filterSearch"
          v-model="filter.selectedPdv"
          placeholder="Filtrar"
          class="mediumInput mt-5"
          label="Filtrar por vendedor"
        >
          <template v-slot:selection="{ index }">
            <div class="d-flex align-center" v-if="index === 0">
              <v-chip
                color="primary"
                v-if="posSellers.length === filter.selectedPdv.length"
                small
              >
                Todos
              </v-chip>

              <v-chip v-else small>
                {{ filter.selectedPdv.length }} Pdv
              </v-chip>
            </div>
          </template>
          <template v-slot:item="{ item }">
            <v-icon>mdi-printer-pos</v-icon>
            {{ item.Address?.name }}
          </template>
        </v-autocomplete>
      </v-card>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import TicketBlockSelector from "../../../../../../global/TicketBlockSelector.vue";

export default {
  props: {
    ticketGroups: {
      type: Array,
      default: () => [],
    },
    posSellers: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    TicketBlockSelector,
  },
  emits: ["input", "isValid"],
  data: () => ({
    filter: {
      courtesy: true,
      allDates: true,
      allTicketBlocks: true,
      allPosSellers: true,
      dates: [],
      selectedRangeDate: null,
      ticketBlocks: [],
      selectedPdv: [],
    },
    dateOptions: [
      {
        text: "Hoje",
        value: "today",
        math: [0, 0],
      },
      {
        text: "Ontem",
        value: "yesterday",
        math: [-1, -1],
      },
      {
        text: "Últimos 7 dias",
        value: "last7days",
        math: [-7, 0],
      },
      {
        text: "Últimos 30 dias",
        value: "last30days",
        math: [-30, 0],
      },
      {
        text: "Este mês",
        value: "thisMonth",
        math: () => [
          moment().startOf("month").toDate(),
          moment().endOf("month").toDate(),
        ],
      },
      {
        text: "Personalizado",
        value: "custom",
        math: null,
      },
    ],
  }),

  methods: {
    isValid() {
      if (!this.filter.allPosSellers && this.filter.selectedPdv.length === 0)
        return false;
      if (!this.filter.allTicketBlocks && this.filter.ticketBlocks.length === 0)
        return false;
      if (!this.filter.allDates && !this.filter.selectedRangeDate) return false;
      if (this.filter.allDates) return true;
      if (this.filter.selectedRangeDate != "custom") return true;
      if (this.filter.dates.length != 2) return false;
      return this.filter.dates[0] <= this.filter.dates[1];
    },
    formatFilter() {
      const ticketBlockLenght = this.ticketGroups.reduce(
        (acc, tg) => acc + tg.TicketBlock.length,
        0
      );
      return {
        status: ["succeeded"],
        courtesy: this.filter.courtesy,
        dates: this.filter.allDates ? null : this.dateRange,
        ticketBlocks:
          this.filter.allTicketBlocks ||
          ticketBlockLenght === this.filter.ticketBlocks.length
            ? null
            : this.filter.ticketBlocks,
        sellers:
          this.filter.allPosSellers ||
          this.filter.selectedPdv.length === this.posSellers.length
            ? null
            : this.filter.selectedPdv,
      };
    },
    filterSearch(item, queryText) {
      const itemName = item.name || item.Address?.name || item.type;
      return itemName.toLowerCase().includes(queryText.toLowerCase());
    },
  },
  computed: {
    dateRange() {
      if (this.filter.allDates) return null;
      if (!this.filter.selectedRangeDate) return this.filter.dates;
      const date = this.dateOptions.find(
        (d) => d.value === this.filter.selectedRangeDate
      );
      if (date.math === null) return this.filter.dates;
      if (typeof date.math === "function") return date.math();
      return [
        moment().add(date.math[0], "days").toDate(),
        moment().add(date.math[1], "days").toDate(),
      ];
    },
    formattedDateRange() {
      if (this.filter.allDates) return "Todas as datas";
      const startDate = moment(this.dateRange[0]).format("D [de] MMMM");
      const endDate = moment(this.dateRange[1]).format("D [de] MMMM");
      if (startDate === endDate) return startDate;
      return `${startDate} até ${endDate}`;
    },
  },
  watch: {
    filter: {
      handler() {
        const isValid = this.isValid();
        this.$emit("isValid", isValid);
        if (isValid) this.$emit("input", this.formatFilter());
      },
      deep: true,
    },
  },
  mounted() {
    this.filter.selectedPdv = this.posSellers;
  },
};
</script>

<style>
.v-slide-group__next,
.v-slide-group__prev {
  min-width: 32px;
}
</style>

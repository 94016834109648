var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.complete)?[(_vm.loadingTicketGroups)?_c('v-alert',{staticClass:"mb-0",attrs:{"type":"info","text":""}},[_vm._v(" Carregando lotes, por favor aguarde... ")]):(!_vm.ticketGroups.length)?_c('v-alert',{staticClass:"mb-0",attrs:{"type":"warning","text":""}},[_vm._v(" Você não possui permissão para emitir nenhum lote como cortesia. ")]):[_c('v-autocomplete',{attrs:{"label":"Lote","outlined":"","items":_vm.ticketBlocks,"messages":_vm.ticketBlockMessage},model:{value:(_vm.ticketBlock),callback:function ($$v) {_vm.ticketBlock=$$v},expression:"ticketBlock"}}),_c('v-textarea',{attrs:{"outlined":"","label":"WhatsApp (um por linha)","messages":[
          (_vm.numberPhones.length > _vm.selectedTicketBlock?.tickets?.remaining
            ? 'Você não possui ingressos suficientes. '
            : '') +
            `${_vm.numberPhones.length} telefone${
              _vm.numberPhones.length != 1 ? 's' : ''
            }${_vm.selectedTicketBlock ? ` de ${_vm.ticketBlockMessage}` : ''}`,
        ],"rules":[
          (v) => !!v || 'Informe pelo menos um telefone',
          (v) =>
            _vm.numberPhones.length > 0 ||
            'Informe pelo menos um telefone válido',
          () =>
            _vm.selectedTicketBlock
              ? _vm.numberPhones.length <=
                  _vm.selectedTicketBlock.tickets.remaining ||
                'Você não possui ingressos suficientes'
              : true,
        ]},model:{value:(_vm.phones),callback:function ($$v) {_vm.phones=$$v},expression:"phones"}}),_c('v-switch',{staticClass:"mt-0",attrs:{"label":"Remover duplicados","color":"primary","hide-details":""},model:{value:(_vm.removeDuplicates),callback:function ($$v) {_vm.removeDuplicates=$$v},expression:"removeDuplicates"}}),(_vm.error)?_c('v-alert',{staticClass:"mb-0 mt-4",attrs:{"type":"error","text":""}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()]]:[_c('h5',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.loading ? "Enviando cortesias" : "Cortesias enviadas")+" ")]),_c('v-list',_vm._l((_vm.results ? _vm.results : _vm.numberPhones),function(result,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex align-center gap-2"},[(_vm.loading)?_c('v-progress-circular',{attrs:{"size":"15","indeterminate":""}}):(result.error)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-alert-circle ")]):_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check-circle ")]),_vm._v(" "+_vm._s(result.phone || result)+" ")],1),(!_vm.loading)?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(result.error || "Enviado com sucesso")+" ")]):_vm._e()],1)],1)}),1),_c('v-btn',{attrs:{"block":"","text":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('close', true)}}},[_vm._v(" Fechar ")])],(!_vm.complete)?_c('div',{staticClass:"d-flex mt-3 justify-space-between"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('prev')}}},[_vm._v(" Voltar ")]),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.ticketBlock ||
        !_vm.numberPhones.length ||
        _vm.numberPhones.length > _vm.selectedTicketBlock.tickets.remaining},on:{"click":_vm.send}},[_vm._v(" Continuar ")])],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
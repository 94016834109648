const axios = require("axios");

module.exports = async (workbook, sheet, { title = "", party }) => {
  // Add a new row before the data and add a title
  const columnsLength = Math.min(sheet.columns.length, 6);
  const row = sheet.insertRow(1, []);
  // set entire row background color
  row.height = 40;
  row.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "242939" },
  };
  row.font = { color: { argb: "ffffff" } };

  sheet.mergeCells(`A1:${String.fromCharCode(64 + columnsLength)}1`);
  row.getCell(1).value = title;
  row.getCell(1).style.font = { size: 22, bold: true, color: { argb: "ffffff" } };
  row.getCell(1).style.alignment = { horizontal: "center", vertical: "middle" };

  // Subtitle
  const subtitleRow = sheet.insertRow(2, []);
  subtitleRow.height = 30;
  subtitleRow.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "242939" },
  };
  subtitleRow.font = { color: { argb: "ffffff" } };
  
  sheet.mergeCells(`A2:${String.fromCharCode(64 + columnsLength)}2`);
  subtitleRow.getCell(1).style.font = { size: 14, bold: true, color: { argb: "ffffff" } };
  subtitleRow.getCell(1).style.alignment = {
    horizontal: "center",
    vertical: "top",
  };



  // Add logo
  var logoImgId = workbook.media.length ? 0 : null;
  if (logoImgId === null) {
    const logoBuffer = await axios.get("/img/logo/logo-light.png", {
      responseType: "arraybuffer",
    });

    logoImgId = workbook.addImage({
      buffer: logoBuffer.data,
      extension: "png",
    });
  }

  sheet.addImage(logoImgId, {
    tl: { col: 0.2, row: 0.6 },
    ext: { width: 150, height: 40 },
    editAs: "oneCell",
  });

  if (party) {
    subtitleRow.getCell(1).value = party.name;

    var coverImgId = workbook.media.length > 1 ? 1 : null;
    if (!coverImgId) {
      const partyCoverBuffer = await axios.get(party.cover, {
        responseType: "arraybuffer",
      }).catch(() => false)
      if (!partyCoverBuffer) return true;
      coverImgId = workbook.addImage({
        buffer: partyCoverBuffer.data,
        extension: "png",
      });

    }
    sheet.addImage(coverImgId, {
      tl: { col: columnsLength - 0.5, row: 0.3 },
      ext: { height: 60, width: (16 / 9) * 60 },
      editAs: "oneCell",
    });
  }

  // Protect entire row
  row.protection = {
    locked: true,
    lockText: true,
  };

  return true;
};

<template>
  <v-dialog
    v-model="isOpen"
    width="700"
    scrollable
    :persistent="loading"
    @click:outside="close"
  >
    <v-card>
      <v-card-title>
        {{ editing ? "Editar acesso da organização" : "Adicionar co-autor" }}
      </v-card-title>

      <v-card-text class="pa-4">
        <organization-select v-if="!editing" v-model="organization" />
        <v-card
          outlined
          v-else
          class="d-flex gap-2 align-center mb-4 pa-4 rounded-lg"
        >
          <base-avatar
            :src="organization?.photo"
            :seed="organization?.id || ''"
            :size="50"
            avatarStyle="shapes"
          />
          <p class="text-subtitle-2 font-weight-black mb-0">
            {{ organization?.name }}
          </p>
        </v-card>
        <v-form @submit.prevent v-model="valid">
          <div class="d-flex justify-space-between">
            <p class="text--primary font-weight-bold mb-1 ma-0">Permissões</p>
            <v-btn small text @click="toggleAll(permGroup)">
              {{
                permGroup.some((p) => !permission.includes(`${p}`))
                  ? "Marcar todos"
                  : "Desmarcar todos"
              }}
            </v-btn>
          </div>
          <div class="d-flex flex-column gap-2">
            <v-card class="pa-2" elevation="0">
              <v-row dense class="mx-0">
                <v-col
                  v-for="p in permGroup"
                  :key="p"
                  cols="12"
                  sm="6"
                  md="4"
                  class="pt-0"
                  align-self="center"
                >
                  <v-checkbox
                    class="mt-0"
                    hide-details
                    v-model="permission"
                    :label="permissions[p]"
                    :value="`${p}`"
                    :rules="[
                      (v) => !!v || 'Permissão é obrigatória',
                      (v) => !!v.length || 'Permissão é obrigatória',
                    ]"
                  />
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-form>

        <v-alert v-if="!!error" dense text type="error">
          {{ error }}
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-btn
          v-if="editing"
          color="danger"
          text
          :disabled="loading"
          :loading="loading"
          @click="remove"
        >
          Remover
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text :disabled="loading" @click="close"> Cancelar </v-btn>
        <v-btn
          color="primary"
          :disabled="loading || !valid || !organization?.id"
          :loading="loading"
          @click="save"
        >
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import OrganizationSelect from "./modules/OrganizationSelect.vue";
import CO_ORGANIZER from "@/services/admin/party/coOrganizer";

export default {
  components: { OrganizationSelect },
  data: () => ({
    isOpen: false,
    loading: false,
    valid: false,
    error: false,
    organization: null,
    expiryDate: null,
    editing: false,
    permission: [],
  }),

  methods: {
    open({ permissions = [], ...organization } = {}) {
      this.isOpen = true;
      console.log(permissions);
      this.organization = Object.assign({}, organization.Organization || {});
      this.editing = !!organization.id;
      this.permission = (permissions || []).map((p) => `${p}`);
    },
    close() {
      if (this.loading) return;
      this.isOpen = false;
      this.loading = false;
      this.error = false;
      this.organization = null;
      this.permission = [];
    },
    toggleAll(perms) {
      perms = perms.map((p) => `${p}`);
      const hasAll = perms.every((p) => this.permission.includes(p));
      if (hasAll) {
        this.permission = this.permission.filter((p) => !perms.includes(p));
      } else {
        this.permission = [
          ...this.permission,
          ...perms.filter((p) => !this.permission.includes(p)),
        ];
      }
    },
    reducePermissions(permissions) {
      return permissions.reduce((acc, permission) => {
        return acc + parseInt(permission);
      }, 0);
    },
    async remove() {
      try {
        if (
          !window.confirm(
            "Tem certeza que deseja remover o acesso dessa organização?"
          )
        )
          return;
        this.loading = true;
        this.error = false;

        const response = await CO_ORGANIZER.remove(
          this.party.organizationId,
          this.party.id,
          this.organization.id
        );

        this.$emit("refresh");
        this.loading = false;
        this.close();
      } catch (e) {
        this.error = e.message;
        this.loading = false;
      }
    },
    async save() {
      try {
        this.loading = true;
        this.error = false;

        const response = await CO_ORGANIZER.set(
          this.party.organizationId,
          this.party.id,
          {
            organizationId: this.organization.id,
            permission: this.reducePermissions(this.permission),
          }
        );

        this.$emit("success", response);
        this.loading = false;
        this.$emit("refresh");
        this.close();
      } catch (e) {
        this.error = e.message;
        this.loading = false;
      }
    },
  },

  computed: {
    ...mapGetters("auth", ["permissions", "permissionsGroups"]),
    ...mapGetters("organization", ["selectedOrganization"]),
    permissionsArray() {
      return Object.entries(this.permissions).map(([key, value]) => ({
        value: key,
        text: value,
      }));
    },
    permGroup() {
      return this.permissionsGroups["Eventos"] || [];
    },
  },
  mounted() {
    this.$root.$on("co-organizer-modal", this.open);
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>

<template>
  <div class="mt-2">
    <v-alert v-if="party.ParentParty" type="warning" text class="mb-0">
      O grupo do WhatsApp não pode ser vinculado a um sub evento. Vincule o
      grupo ao evento principal!
    </v-alert>
    <template v-else>
      <div class="d-flex">
        <v-spacer />
        <v-btn
          @click="getGroups"
          icon
          small
          :loading="loading"
          :disabled="blockRefresh"
          class="mr-2"
        >
          <v-icon small>mdi-refresh</v-icon>
        </v-btn>
        <v-btn color="primary" small @click="add">
          <v-icon small>mdi-plus</v-icon>
          Adicionar
        </v-btn>
      </div>

      <v-alert
        v-if="!loading && !whatsappGroup.length"
        type="info"
        text
        class="mb-0 mt-2"
      >
        Não há grupos do WhatsApp vinculados a este evento.
      </v-alert>

      <v-row>
        <v-col
          v-for="(item, i) in whatsappGroup"
          :key="`${i}-${item.id}`"
          cols="12"
          sm="6"
          lg="4"
        >
          <v-card outlined class="rounded-lg pa-3 d-flex flex-column gap-2">
            <div class="d-flex gap-2 align-center">
              <v-avatar size="32">
                <v-icon v-if="!item.image"> mdi-account-multiple</v-icon>
                <v-img v-else :src="item.image" />
              </v-avatar>
              <span class="flex-grow-1 font-weight-bold">{{ item.name }}</span>

              <v-btn depressed small @click="view(item)">
                <v-icon small left>mdi-pencil</v-icon>
                Editar
              </v-btn>
            </div>
            <div class="d-flex gap-2 align-center">
              <v-chip
                label
                small
                dark
                :color="types[item.WhatsappGroupParty[0].type].color"
              >
                <v-icon small left>{{
                  types[item.WhatsappGroupParty[0].type].icon
                }}</v-icon>
                {{ types[item.WhatsappGroupParty[0].type].label }}
              </v-chip>
              <v-chip label small dark>
                <v-icon small left>mdi-account-multiple</v-icon>
                {{ item.WhatsAppGroupMember.length }}
              </v-chip>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <whats-app-group-add @update="getGroups" :party="party" />
      <whats-app-group-view :party="party" @update="getGroups" />
    </template>
  </div>
</template>

<script>
import WHATSAPPGROUP from "@/services/admin/party/whatsappGroup";
import WhatsAppGroupAdd from "./modal/WhatsAppGroupAdd.vue";
import WhatsAppGroupView from "./modal/WhatsAppGroupView.vue";
import WhatsappGroupTypes from "@/definitions/WhatsappGroupTypes";

export default {
  components: { WhatsAppGroupAdd, WhatsAppGroupView },
  data: () => ({
    loading: false,
    blockRefresh: false,
    error: false,
    whatsappGroup: [],
    headers: [
      { text: "Nome", value: "name" },
      { text: "Tipo", value: "type" },
      {
        text: "Participantes",
        value: "WhatsAppGroupMember.length",
        align: "center",
      },
      { text: "Ações", value: "actions", sortable: false, align: "right" },
    ],
    types: WhatsappGroupTypes,
  }),
  methods: {
    view(item) {
      this.$emit("whatsapp-group-view", item);
    },
    add() {
      this.$emit("whatsapp-group-add");
    },
    async getGroups() {
      try {
        this.loading = true;
        this.blockRefresh = true;
        const { whatsappGroup } = await WHATSAPPGROUP.getAll(
          this.party.organizationId,
          this.party.id
        );
        this.whatsappGroup = whatsappGroup;
      } catch (error) {
        this.error = error.message || "Erro ao carregar avaliações";
      } finally {
        this.loading = false;
        this.enableRefresh(5000);
      }
    },
    enableRefresh(time) {
      setTimeout(() => {
        this.blockRefresh = false;
      }, time);
    },
  },

  computed: {},

  mounted() {
    this.getGroups();
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>

<template>
  <div class="mt-4">
    <template v-if="!party.ParentParty">
      <div class="d-flex align-center justify-space-between mb-3">
        <h6 class="mb-0">Subeventos</h6>
        <v-btn color="primary" small @click="addSubEvent">
          <v-icon>mdi-plus</v-icon>
          Subevento
        </v-btn>
      </div>
      <v-alert v-if="party.ChildParties.length === 0" type="info" text>
        Nenhum subevento cadastrado
      </v-alert>

      <div v-else class="d-flex flex-column gap-2">
        <v-card
          v-for="childParty in party.ChildParties"
          :key="childParty.id"
          outlined
          class="d-flex align-center gap-2 pr-3"
        >
          <div>
            <v-img
              height="60"
              :width="60 * (16 / 9)"
              :src="childParty.cover"
              class="rounded-l"
              :aspect-ratio="16 / 9"
            />
          </div>
          <div class="flex-grow-1">
            <span class="text-overline lh-1">Subevento</span>
            <h6 class="mb-0">{{ childParty.name }}</h6>
          </div>
          <v-btn icon small @click="copyPartyLink(childParty)">
            <template v-if="copied === childParty.id">
              <v-icon>mdi-check</v-icon>
            </template>
            <template v-else>
              <v-icon>mdi-content-copy</v-icon>
            </template>
          </v-btn>
        </v-card>
      </div>
    </template>
    <template v-else>
      <v-alert type="info" text>
        Esse evento é um subevento de outro, não é possível adicionar subeventos
      </v-alert>
      <v-card outlined class="d-flex align-center gap-2">
        <div>
          <v-img
            height="60"
            :width="60 * (16 / 9)"
            :src="party.ParentParty.cover"
            class="rounded-l"
            :aspect-ratio="16 / 9"
          />
        </div>
        <div>
          <span class="text-overline lh-1">Evento principal</span>
          <h6 class="mb-0">{{ party.ParentParty.name }}</h6>
        </div>
      </v-card>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      copied: null,
      interval: null,
    };
  },
  methods: {
    addSubEvent() {
      this.$router.push({
        name: "admin.party.create",
        query: {
          parentId: this.party.id,
        },
      });
    },
    copyPartyLink(party) {
      const url = `${window.location.origin}/shop/${
        this.selectedOrganization.slug
      }/${party.slug || party.id}`;
      navigator.clipboard.writeText(url);
      this.copied = party.id;

      clearTimeout(this.interval);
      this.interval = setTimeout(() => {
        this.copied = null;
      }, 2000);
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  watch: {},
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>

<template>
    <v-card outlined class="pa-4 mt-6">
      <p class="font-weight-bold mb-1">Modo técnico</p>
      <div class="d-flex gap-2">
        <v-btn
          color="secondary"
          @click="copyFaqs"
          :disabled="!!loading"
          small
          depressed
        >
          Copiar todos os FAQs
        </v-btn>
        <v-btn
          color="secondary"
          @click="pasteFaqs"
          :loading="loading === 'pasteFaqs'"
          :disabled="!!loading"
          small
          depressed
        >
          Colar FAQs
        </v-btn>
      </div>
    </v-card>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import FAQ from "@/services/admin/faq";
  
  export default {
    props: {
      party: {
        type: Object,
        required: true,
      },
      faqs: {
        type: Array,
        required: true,
      },
    },
    data: () => ({
      loading: false,
    }),
    methods: {
      writeToClipboard(text) {
        navigator.clipboard.writeText(text).then(
          () => window.alert("Copiado com sucesso"),
          () => window.alert("Erro ao copiar")
        );
      },
      copyFaqs() {
        const faqs = this.faqs.map((faq) => {
          return {
            ...faq,
            type: "faq",
            id: undefined,
            createdAt: undefined,
            updatedAt: undefined,
          };
        });
  
        this.writeToClipboard(JSON.stringify(faqs));
      },
      pasteFaqs() {
        if (!document.hasFocus()) {
          window.alert("A janela não está em foco. Por favor, clique na janela e tente novamente.");
          return;
        }
  
        navigator.clipboard.readText().then(
          (text) => {
            this.handlePastedText(text);
          },
          (err) => {
            console.error(err);
            window.alert("Erro ao ler a área de transferência");
          }
        );
      },
      async handlePastedText(text) {
        try {
          this.loading = "pasteFaqs";
          const faqs = JSON.parse(text);
  
          if (!Array.isArray(faqs)) return window.alert("Conteúdo inválido");
          if (faqs.some((faq) => faq.type !== "faq"))
            return window.alert("Conteúdo inválido");
  
          for (const faq of faqs) {
            const faqData = { ...faq };
  
            delete faqData.id;
            delete faqData.createdAt;
            delete faqData.updatedAt;
            delete faqData.type;
            delete faqData.partyId;
            delete faqData.platformId;
  
            faqData.partyId = this.party.id;
            faqData.platformId = this.selectedOrganization.id;
  
            await FAQ.create(
              this.selectedOrganization.id,
              this.party.id,
              faqData
            );
          }
  
          window.alert("FAQs coladas com sucesso");
          this.$emit("refresh");
        } catch (error) {
          console.error(error);
          window.alert("Erro ao colar FAQs");
        } finally {
          this.loading = false;
        }
      },
    },
    computed: {
      ...mapGetters("organization", ["selectedOrganization"]),
    },
  };
  </script>
  
  <style></style>
  
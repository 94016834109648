<template>
  <div class="ma-2 py-2 d-flex flex-column gap-4">
    <v-card outlined class="pa-3">
      <h6>Alvará</h6>
      <v-file-input
        v-if="!party.permitDocument"
        v-model="permitPdf"
        label="Alvará de funcionamento"
        accept=".pdf"
        outlined
        hide-details
        dense
      ></v-file-input>

      <v-alert class="mb-0" v-else type="info" dense text>
        <div class="d-flex w-full align-center justify-space-between">
          <span> Alvará de funcionamento já cadastrado. </span>
          <v-btn depressed small @click="party.permitDocument = null">
            Remover
          </v-btn>
        </div>
      </v-alert>
    </v-card>
    <v-card outlined class="pa-3">
      <h6>Template de autorização para menores</h6>
      <v-file-input
        v-if="!party.authorizationTemplate"
        v-model="authorizationPdf"
        label="Template de autorização para menores"
        accept=".pdf"
        outlined
        hide-details
        dense
      ></v-file-input>

      <v-alert class="mb-0" v-else type="info" dense text>
        <div class="d-flex w-full align-center justify-space-between">
          <span> Template de autorização para menores já cadastrado. </span>
          <v-btn depressed small @click="party.authorizationTemplate = null">
            Remover
          </v-btn>
        </div>
      </v-alert>
    </v-card>
  </div>
</template>

<script>
import PARTY from "@/services/admin/party";
export default {
  data() {
    return {
      permitPdf: null,
      authorizationPdf: null,
    };
  },
  methods: {
    async save() {
      const hasAuthorization =
        this.party.authorizationDocument || this.authorizationPdf;
      const hasPermit = this.party.permitDocument || this.permitPdf;
      const partyUpdate = await PARTY.update({
        id: this.party.id,
        organizationId: this.party.organizationId,
        permitDocument: hasPermit ? undefined : null,
        authorizationDocument: hasAuthorization ? undefined : null,
      });
      if (this.permitPdf)
        await this.saveDocument(this.permitPdf, "permit-document");
      if (this.authorizationPdf)
        await this.saveDocument(
          this.authorizationPdf,
          "authorization-template"
        );

      return partyUpdate;
    },
    saveDocument(document, type) {
      return PARTY.document(
        this.party.organizationId,
        this.party.id,
        type,
        document
      );
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>

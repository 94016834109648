<template>
  <div class="py-2 d-flex flex-column gap-4">
    <div>
      <h6>Vendas no Site</h6>
      <div class="d-flex justify-space-between gap-2 flex-wrap">
        <v-card
          dark
          color="deep-purple darken-4"
          rounded="lg"
          outlined
          class="pa-4 flex-1 card-min-w"
          :disabled="party.lockedMaxTickets"
          @click="maxTickets = 1"
        >
          <div class="d-flex align-center mb-2 justify-space-between">
            <h6 class="mb-0">
              <v-icon left>mdi-account-lock</v-icon>
              Anti-Cambistas
            </h6>
            <v-icon>
              mdi-{{ maxTickets === 1 ? "radiobox-marked" : "radiobox-blank" }}
            </v-icon>
          </div>
          <ul>
            <li>Apenas 1 ingresso por conta</li>
            <li>Identificação obrigatória do comprador</li>
          </ul>
        </v-card>
        <v-card
          dark
          color="blue darken-3"
          outlined
          rounded="lg"
          class="pa-4 flex-1 card-min-w"
          :disabled="party.lockedMaxTickets"
          @click="maxTickets = 2"
        >
          <div class="d-flex align-center mb-2 justify-space-between">
            <h6 class="mb-0">
              <v-icon left>mdi-ticket-account</v-icon>
              Venda Controlada
            </h6>
            <v-icon>
              mdi-{{ maxTickets > 1 ? "radiobox-marked" : "radiobox-blank" }}
            </v-icon>
          </div>
          <ul>
            <li>Escolha a quantidade máxima de ingressos por conta</li>
            <li>Identificação obrigatória do comprador</li>
          </ul>
          <v-slide-y-transition>
            <v-text-field
              :disabled="party.lockedMaxTickets"
              v-show="maxTickets > 1"
              v-model="maxTicketsInput"
              type="number"
              label="Máximo de ingressos por conta"
              outlined
              dense
              min="2"
              max="15"
              class="mt-4"
              dark
              color="white"
              :rules="[
                (v) => !!v || 'Campo obrigatório',
                (v) => v > 1 || 'Mínimo 2 ingressos',
              ]"
              hide-details="auto"
            ></v-text-field>
          </v-slide-y-transition>
        </v-card>
        <v-card
          dark
          color="light-blue darken-2"
          outlined
          rounded="lg"
          class="pa-4 flex-1 card-min-w"
          @click="maxTickets = 0"
          :disabled="party.lockedMaxTickets"
        >
          <div class="d-flex align-center mb-2 justify-space-between">
            <h6 class="mb-0">
              <v-icon left>mdi-ticket-outline</v-icon>
              Venda Livre
            </h6>
            <v-icon>
              mdi-{{ maxTickets === 0 ? "radiobox-marked" : "radiobox-blank" }}
            </v-icon>
          </div>
          <ul>
            <li>Sem limite de ingressos por conta</li>
            <li>Identificação opcional do comprador no PDV</li>
          </ul>
          <v-slide-y-transition>
            <v-alert
              v-show="maxTickets === 0"
              dense
              color="white"
              dark
              text
              class="mb-0 mt-2"
            >
              <v-icon left>mdi-alert</v-icon>
              <span class="caption">
                Atenção: Venda livre pode gerar cambistas
              </span>
            </v-alert>
          </v-slide-y-transition>
        </v-card>
      </div>
    </div>
    <v-divider></v-divider>
    <v-alert
      dense
      :color="`${posRestrict ? 'warning' : 'blue'} darken-4`"
      text
      class="mb-0"
    >
      <div class="d-flex justify-space-between gap-2">
        <b>Vendas em PDV</b>
        <v-switch
          v-model="posRestrict"
          label="Restringir vendas em PDV"
          :disabled="party.lockedMaxTickets"
          class="ma-0 pa-0"
          color="error"
          hide-details
        ></v-switch>
      </div>
      <div v-if="posRestrict">
        <v-text-field
          v-model="maxTicketsPosInput"
          :disabled="party.lockedMaxTickets"
          type="number"
          label="Máximo de ingressos por conta"
          outlined
          dense
          min="1"
          max="15"
          class="mt-4 mb-1"
          :rules="[
            (v) => !!v || 'Campo obrigatório',
            (v) => v > 0 || 'Mínimo 1 ingresso',
          ]"
          hide-details="auto"
        ></v-text-field>
      </div>
      <div v-else>Não há restrição de vendas em PDV</div>
    </v-alert>

    <v-alert v-if="party.lockedMaxTickets" dense type="warning" text>
      A política de restrição de venda está bloqueada, entre em contato com o
      suporte saber mais
    </v-alert>
  </div>
</template>

<script>
import PARTY from "@/services/admin/party";

export default {
  components: {},
  data() {
    return {
      maxTicketsInput: Math.max(this.party?.maxTickets || 2, 2),
      maxTickets: Number.isInteger(this.party?.maxTickets)
        ? this.party.maxTickets
        : 1,

      maxTicketsPosInput: Math.max(this.party?.maxTicketsPos || 1, 1),
      posRestrict: parseInt(this.party?.maxTicketsPos || 0) > 0,
    };
  },
  methods: {
    save() {
      return PARTY.update({
        id: this.party.id,
        organizationId: this.party.organizationId,
        maxTickets: parseInt(this.ticketsPerUser),
        maxTicketsPos: parseInt(this.ticketsPos),
      });
    },
  },
  computed: {
    ticketsPerUser() {
      if (this.maxTickets > 1) return this.maxTicketsInput || 2;
      return this.maxTickets;
    },
    ticketsPos() {
      if (this.posRestrict) return Math.max(this.maxTicketsPosInput || 1, 1);
      return 0;
    },
  },
  watch: {
    maxTickets: {
      handler: function (val, oldVal) {
        this.maxTicketsInput = Math.max(this.maxTicketsInput || val || 0, 2);
      },
    },
    party: {
      handler: function (val, oldVal) {
        this.maxTickets = this.party?.maxTickets || 0;
      },
      deep: true,
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
.card-min-w {
  cursor: pointer;
  min-width: 200px;
}
</style>

<template>
  <v-dialog
    v-model="dialogVisible"
    max-width="700px"
    overlay-opacity="0.9"
    @click:outside="close"
    persistent
  >
    <v-card>
      <v-card-text class="pb-2">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <slot></slot>
          </div>
        </template>
        <div class="d-flex gap-2 align-center mb-3 mt-3">
          <base-avatar
            :seed="
              item?.Ticket?.Owner?.id ||
              item?.Payment?.Ticket[0]?.Owner?.id ||
              ''
            "
            :src="
              item?.Ticket?.Owner?.photo ||
              item?.Payment?.Ticket[0]?.Owner?.photo
            "
            :size="50"
          />
          <div>
            <h6 class="mb-0">
              {{
                item?.Ticket?.Owner?.name ||
                item?.Payment?.Ticket[0]?.Owner?.name
              }}
            </h6>
            <a @click="openWhatsapp">
              {{
                item?.Ticket?.Owner?.phone ||
                item?.Payment?.Ticket[0]?.Owner?.phone
              }}
            </a>
          </div>
          <v-spacer />
          <v-btn @click="openWhatsapp" fab color="success" x-small>
            <v-icon>mdi-whatsapp</v-icon>
          </v-btn>
        </div>
        <v-card outlined class="pa-4 mb-2">
          <div
            class="d-flex align-end mb-1 justify-space-between flex-wrap-reverse"
          >
            <h6 class="font-weight-bold mb-0">Motivo</h6>
            <div class="text-end">
              <p class="mb-0 lh-1 font-weight-medium">
                Solicitado em: {{ item?.createdAt | date("DD/MM/YY HH:mm") }}
              </p>
              <small class="lh-1">
                {{
                  [
                    item?.Ticket?.Payment?.payedAt ||
                      item?.Ticket?.Payment?.createdAt ||
                      item?.Payment?.payedAt ||
                      item?.Payment?.createdAt,
                    item?.createdAt,
                  ] | dateDiff
                }}
                após o pagamento
              </small>
            </div>
          </div>
          {{ item?.reason }}
        </v-card>

        <v-card outlined class="pa-4 mb-4">
          <h6 class="font-weight-bold mb-1">Compra</h6>
          <div class="d-flex flex-wrap gap-x-6 gap-y-3 justify-space-around">
            <div class="text-center">
              <p class="mb-0 lh-1">Pagamento</p>
              <p class="mb-0 font-weight-bold text-overline lh-2">
                #{{ item?.Ticket?.Payment?.id || item?.Payment?.id | shortId }}
              </p>
            </div>
            <div class="text-center">
              <p class="mb-0 lh-1">Data do Pagamento</p>
              <p class="mb-0 font-weight-bold">
                {{
                  item?.Ticket?.Payment?.payedAt ||
                  item?.Ticket?.Payment?.createdAt ||
                  item?.Payment?.payedAt ||
                  item?.Payment?.createdAt | date("DD/MM/YY HH:mm")
                }}
              </p>
            </div>
            <div class="text-center">
              <p class="mb-0 lh-1">Vendido por</p>
              <template
                v-if="
                  !item?.Ticket?.Seller || !item?.Payment?.Ticket[0]?.Seller
                "
              >
                Online
              </template>
              <template v-else>
                <base-avatar
                  :seed="
                    item?.Ticket?.Seller?.id ||
                    item?.Payment?.Ticket[0]?.Seller?.id ||
                    ''
                  "
                  :src="
                    item?.Ticket?.Seller?.photo ||
                    item?.Payment?.Ticket[0]?.Seller?.photo
                  "
                  :size="20"
                  class="mr-1"
                />
                <span class="font-weight-bold">
                  {{
                    item?.Ticket?.Seller?.name ||
                    item?.Payment?.Ticket[0]?.Seller?.name ||
                    "Conta não criada"
                  }}</span
                >
              </template>
            </div>

            <div class="text-center">
              <p class="mb-0 lh-1">Meio de Pagamento</p>
              <p class="mb-0 font-weight-bold">
                {{
                  PAYMENT.paymentMethod[
                    item?.Ticket?.Payment?.paymentMethod ||
                      item?.Payment?.paymentMethod
                  ]?.text
                }}
              </p>
            </div>
            <div class="text-center">
              <p class="mb-0 lh-1">Forma de Pagamento</p>
              <p class="mb-0 font-weight-bold">
                {{
                  PAYMENT.paymentType[
                    item?.Ticket?.Payment?.paymentType ||
                      item?.Payment?.paymentType
                  ]?.text
                }}
              </p>
            </div>
            <div class="text-center">
              <p class="mb-0 lh-1">Status do Pagamento</p>
              <v-chip
                x-small
                label
                :color="
                  PAYMENT.ticketStatus[
                    item?.Ticket?.Payment?.status || item?.Payment?.status
                  ]?.color
                "
              >
                {{
                  PAYMENT.ticketStatus[
                    item?.Ticket?.Payment?.status || item?.Payment?.status
                  ]?.text
                }}
              </v-chip>
            </div>
          </div>
        </v-card>

        <v-alert
          v-if="
            item?.Ticket?.Payment.Ticket.length > 1 ||
            item?.Payment?.Ticket.length > 1
          "
          type="warning"
          text
          dense
        >
          Esse pagamento possui
          <strong
            >{{
              item?.Ticket?.Payment.Ticket.length ||
              item?.Payment?.Ticket.length
            }}
            ingressos</strong
          >
          vinculados, todos serão cancelados ao reembolsar.
        </v-alert>
        <v-card outlined class="pa-4 mb-2" v-if="item?.ClosedBy">
          <h6 class="font-weight-bold mb-1">Solicitação encerrada por</h6>
          <div class="d-flex gap-2 my-2">
            <base-avatar
              :seed="item?.ClosedBy.id || ''"
              :src="item?.ClosedBy.photo"
              :size="50"
            />
            <div>
              <h6 class="mb-0 text-14">{{ item?.ClosedBy.name }}</h6>
              <span> em {{ item?.closedAt | date("DD/MM/YYYY HH:mm") }} </span>
            </div>
          </div>
          <h6 class="mb-0 text-14 my-2">Resposta</h6>
          <p class="mb-0">{{ item.answer }}</p>
        </v-card>

        <v-alert type="error" v-if="error">
          {{ error }}
        </v-alert>

        <div class="d-flex flex-wrap gap-2">
          <v-btn
            v-if="paymentStatus != 'refunded'"
            @click="refundTicket"
            class="flex-grow-1"
            color="primary"
            :disabled="loading"
            :outlined="confirmingRefund"
          >
            Reembolsar
          </v-btn>
          <v-btn
            v-if="!item?.closedAt && paymentStatus != 'refunded'"
            @click="declineDialog"
            class="flex-grow-1"
            color="error"
            :loading="loading"
          >
            <template> Recusar </template>
          </v-btn>
          <v-btn
            v-if="paymentStatus === 'refunded'"
            @click="finishRequest"
            class="flex-grow-1"
            color="info"
            :loading="loading"
          >
            <template> Finalizar </template>
          </v-btn>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close" block text>Fechar</v-btn>
      </v-card-actions>
    </v-card>
    <decline-refund :item="item" @refresh="refresh" />
  </v-dialog>
</template>

<script>
import PAYMENT from "@/utils/payment";
import PARTY from "@/services/admin/party";
import DeclineRefund from "../party/modal/DeclineRefund.vue";
import { mapGetters } from "vuex";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { DeclineRefund },
  data() {
    return {
      PAYMENT,
      confirmingRefund: false,
      loading: false,
      error: null,
      dialogVisible: false,
    };
  },
  methods: {
    refresh() {
      this.close();
      this.$emit("refresh");
    },
    declineDialog() {
      this.$root.$emit("decline-refund", this.item);
    },
    async finishRequest() {
      try {
        this.loading = true;
        this.error = null;
        await PARTY.refund.closeRefoundRequest(
          this.selectedOrganization.id,
          this.$route.params.partyId,
          this.item.id,
          { answer: null, notify: false }
        );
        this.dialogVisible = false;
        this.$emit("refresh");
      } catch (e) {
        this.error = e.message || "Erro ao recusar reembolso";
      } finally {
        this.loading = false;
      }
    },
    confirmRefund() {
      if (!this.confirmingRefund) {
        this.confirmingRefund = true;
        setTimeout(() => {
          this.confirmingRefund = false;
        }, 3000);
      } else {
        this.closeRefoundRequest();
      }
    },
    open() {
      this.dialogVisible = true;
    },
    close() {
      if (this.loading) return;
      this.dialogVisible = false;
      this.$emit("close");
      this.$nextTick(() => {
        this.whatsapp = "";
        this.ddi = 55;
        this.check = false;
        this.error = false;
      });
    },
    refundTicket() {
      console.log("Aqui");
      const payment = this.item?.Ticket?.Payment || this.item?.Payment;
      this.$root.$emit("refund-ticket", payment);
    },

    openWhatsapp() {
      const phone = `${
        this.item?.Ticket?.Owner.ddi || this.item?.Payment.Ticket[0].Owner.ddi
      }${
        this.item?.Ticket?.Owner.phone ||
        this.item?.Payment.Ticket[0].Owner.phone
      }`.replace(/\D/g, "");

      window.open(
        `https://wa.me/${phone}?text=Olá ${
          this.item?.Ticket?.Owner.name.split(" ")[0] ||
          this.item?.Payment.Ticket[0].Owner.name.split(" ")[0]
        }%0A`
      );
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    paymentStatus() {
      return this.item?.Ticket?.Payment.status || this.item?.Payment.status;
    },
  },
  mounted() {
    this.$root.$on("refund-details", this.open);
    this.$root.$on("refund-ticket:success", this.close);
  },
  beforeDestroy() {
    this.$root.$off("refund-details", this.open);
  },
};
</script>

<style scoped></style>

<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="800px"
    :persistent="step > 1"
    content-class="rounded-lg"
  >
    <v-card class="rounded-lg">
      <v-card-title class="d-block pa-0">
        <v-stepper v-model="step" class="rounded-t-lg" height="50">
          <v-stepper-header >
            <v-stepper-step :complete="step > 1" step="1"> </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 2" step="2"> </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3"> </v-stepper-step>
          </v-stepper-header>
        </v-stepper>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-stepper v-model="step">
          <v-stepper-items>
            <v-stepper-content step="1" class="pa-0">
              <div class="pa-5">
                <h5>Como deseja emitir a cortesia?</h5>
                <v-row class="mx-0" dense align="stretch">
                  <v-col
                    v-for="(method, index) in methods"
                    :key="index"
                    cols="12"
                    sm="6"
                  >
                    <v-card
                      @click="selectedMethod = method"
                      class="h-full cursor-pointer pa-4 d-flex flex-column"
                      outlined
                      rounded="lg"
                    >
                      <div
                        class="d-flex align-center justify-space-between mb-2"
                      >
                        <h6 class="mb-0">
                          <v-icon left>
                            {{ method.icon }}
                          </v-icon>
                          {{ method.title }}
                        </h6>
                        <v-icon>
                          mdi-radiobox-{{
                            selectedMethod === method ? "marked" : "blank"
                          }}
                        </v-icon>
                      </div>
                      <div
                        class="flex-grow-1 flex-shrink-0 d-flex flex-column justify-center"
                      >
                        <p class="mb-0">{{ method.description }}</p>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
                <div class="d-flex justify-space-between mt-3">
                  <v-btn text @click="close"> Cancelar </v-btn>
                  <v-btn
                    color="primary"
                    :disabled="!selectedMethod || !ticketGroups"
                    :loading="!ticketGroups"
                    @click="step = 2"
                  >
                    Continue
                  </v-btn>
                </div>
              </div>
            </v-stepper-content>

            <v-scroll-x-reverse-transition leave-absolute mode="out-in">
              <div v-if="step > 1" class="pa-5">
                <component
                  v-if="selectedMethod"
                  :is="selectedMethod.component"
                  :party="party"
                  :ticketGroups="ticketGroups"
                  :loadingTicketGroups="loading"
                  @next="step++"
                  @prev="step--"
                  @close="close"
                ></component>
              </div>
            </v-scroll-x-reverse-transition>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import TICKET from "@/services/admin/ticket";
import CourtesyPersonal from "./CourtesyPersonal.vue";
import CourtesyResponsible from "./CourtesyResponsible.vue";
import CourtesyPromotional from "./CourtesyPromotional.vue";

export default {
  components: { CourtesyPersonal, CourtesyResponsible, CourtesyPromotional },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      step: 1,
      selectedMethod: null,
      ticketGroups: null,
      loading: false,
      methods: [
        {
          title: "Personalíssima",
          icon: "mdi-card-account-details-star",
          description:
            "Envie uma cortesia diretamente para uma pessoa específica.",
          component: "courtesy-personal",
        },
        {
          title: "Por responsável",
          icon: "mdi-account-tie-hat",
          description:
            "Defina um responsável para emitir cortesias através de um link.",
          component: "courtesy-responsible",
        },
        {
          title: "Link promocional",
          icon: "mdi-link",
          description:
            "Cria um link promocional onde o usuário pode resgatar uma cortesia específica.",
          component: "courtesy-promotional",
        },
        // {
        //   title: "Impressa",
        //   icon: "mdi-printer",
        //   description:
        //     "Gere cortesias impressas sem precisar identificar o contemplado.",
        // },
        // {
        //   title: "Por PDV",
        //   icon: "mdi-cash-register",
        //   description:
        //     "Gere cortesias impressas sem precisar identificar o contemplado.",
        // },
      ],
    };
  },
  methods: {
    open() {
      this.step = 1;
      this.selectedMethod = null;
      this.dialog = true;
      this.getTickets();
    },
    close(refresh = false) {
      this.dialog = false;
      if (refresh) this.$emit("refresh");
    },
    async getTickets() {
      try {
        this.loading = true;
        this.ticketGroups = [];

        const response = await TICKET.canSell(
          this.party.organizationId,
          this.party.id
        );
        this.ticketGroups = response.ticketGroups;
      } catch (error) {
        console.error(error);
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
  },

  mounted() {
    this.$parent.$on("openEmitterModal", this.open);
  },
};
</script>

<style></style>

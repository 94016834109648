<template>
  <v-dialog
    v-model="dialog"
    width="600"
    scrollable
    :persistent="loading"
    @click:outside="close"
  >
    <v-card>
      <v-card-title>
        {{ faq?.id ? `Editar FAQ` : "Criar FAQ" }}
      </v-card-title>
      <v-card-text class="pt-1 pb-0">
        <v-form @submit.prevent v-model="valid">
          <v-text-field
            v-model="faq.question"
            label="Pergunta"
            outlined
            required
            counter="500"
            :rules="[
              (v) => !!v || 'Pergunta é obrigatória',
              (v) => v.length <= 200 || 'Máximo de 200 caracteres',
              (v) => v.length >= 10 || 'Escreva um pouco mais',
            ]"
          />
          <v-textarea
            label="Resposta"
            outlined
            counter
            v-model="faq.answer"
            :rules="[
              (v) => !!v || 'Resposta é obrigatória',
              (v) => v.length >= 10 || 'Escreva um pouco mais',
            ]"
          ></v-textarea>
        </v-form>
      </v-card-text>

      <v-card-actions class="mb-2">
        <v-btn text :disabled="loading" @click="close"> Fechar </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :disabled="!valid"
          :loading="loading"
          @click="save"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import FAQ from "@/services/admin/faq";
const defaultFAQ = () => ({
  question: "",
  answer: "",
});
export default {
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    error: false,
    loading: false,
    valid: false,
    faq: defaultFAQ(),
  }),

  methods: {
    async save() {
      try {
        this.loading = true;
        this.error = false;
        if (this.faq.id) {
          await FAQ.update(
            this.selectedOrganization.id,
            this.party.id,
            this.faq
          );
        } else {
          await FAQ.create(
            this.selectedOrganization.id,
            this.party.id,
            this.faq
          );
        }
        this.$emit("success");
        this.loading = false;
        this.close();
      } catch (e) {
        this.loading = false;
        this.error = e.message || e;
      }
    },
    open(data = {}) {
      this.faq = { ...defaultFAQ(), ...data };
      this.dialog = true;
    },
    close() {
      if (this.loading) return;
      this.dialog = false;
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  mounted() {
    this.$parent.$on("faq-modal", this.open);
  },
};
</script>

<style></style>

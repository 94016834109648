<template>
  <v-form v-model="valid" class="mt-2">
    <v-text-field
      v-model="details.name"
      label="Nome"
      required
      outlined
      :rules="[(v) => !!v || 'Campo obrigatório']"
      :counter="25"
      dense
    />

    <v-textarea
      v-model="details.description"
      label="Descrição"
      required
      outlined
      :counter="100"
      rows="3"
    />

    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="details.validUntil"
          label="Válido até"
          type="datetime-local"
          required
          outlined
          dense
          clearable
          hint="Deixe em branco para ilimitado"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="details.maxTickets"
          label="Máximo de ingressos por cupom"
          type="number"
          outlined
          :rules="[(v) => !v || v > 0 || 'Valor deve ser maior que 0']"
          hint="Deixe em branco para ilimitado"
          placeholder="Ilimitado"
          dense
          clearable
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
const defaultData = () => ({
  name: "",
  description: "",
  validUntil: "",
  maxTickets: "",
  showOnline: "",
});

export default {
  data: () => ({
    valid: false,
  }),

  computed: {
    details: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    valid: {
      handler(value) {
        console.log(value);
        this.$emit("update:valid", value);
      },
      immediate: true,
    },
  },

  props: {
    value: {
      type: Object,
      default: () => defaultData(),
    },
  },
};
</script>

<style></style>

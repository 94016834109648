<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    overlay-opacity="0.9"
    @click:outside="close"
    :persistent="loading"
  >
    <v-card v-if="!!ticket && !succeeded">
      <v-card-title class="headline" v-if="!transferDisabled">
        Transferir Ingresso
      </v-card-title>
      <v-card-text class="pb-2">
        <template v-if="transferDisabled">
          <div class="text-center pt-8">
            <v-avatar class="mb-4" size="60" color="grey lighten-4">
              <v-icon x-large color="grey darken-2">
                {{ transferDisabled.icon }}
              </v-icon>
            </v-avatar>
            <h6>{{ transferDisabled.text }}</h6>
          </div>
        </template>
        <template v-else>
          <v-alert type="warning" dense text border="left">
            <span class="mb-0">
              Ao transferir o ingresso, o processo é <b>irreversível</b> e o
              ingresso não poderá ser devolvido.
            </span>
          </v-alert>
          <phone-input
            :phone="whatsapp"
            @update:phone="(e) => (whatsapp = e)"
            :ddi="ddi"
            @update:ddi="(e) => (ddi = e)"
            @valid="(v) => (valid = v)"
            dense
            outlined
            label="WhatsApp do Destinatário"
            :disabled="loading"
          />
          <v-checkbox
            class="my-0 pt-0"
            v-model="check"
            :disabled="loading"
            label="Confirmo que o WhatsApp do destinatário está correto."
            :rules="[(v) => !!v || 'Confirmação é obrigatória']"
          />

          <v-alert type="error" v-if="error" class="mt-2 mb-0" border="left">
            {{ error }}
          </v-alert>
        </template>
      </v-card-text>
      <v-card-actions class="d-flex flex-column">
        <v-btn
          v-if="!transferDisabled"
          color="primary"
          @click="transfer"
          block
          :disabled="!valid || !check"
          :loading="loading"
          class="mb-2"
        >
          Transferir
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="dialog = false"
          :disabled="loading"
          block
          class="ml-0"
        >
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="succeeded">
      <v-card-text>
        <div class="text-center pt-8">
          <v-avatar class="mb-4" size="60" color="success lighten-4">
            <v-icon x-large color="success darken-2">mdi-check</v-icon>
          </v-avatar>
          <h6>Ingresso transferido com sucesso!</h6>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex flex-column">
        <v-btn color="primary" text @click="close(true)" block class="ml-0">
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import TICKET from "@/services/admin/ticket";
import { mapGetters } from "vuex";
import PhoneInput from "../../global/PhoneInput.vue";
export default {
  components: { PhoneInput },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    ticket: null,
    loading: false,
    valid: false,
    error: false,
    whatsapp: "",
    ddi: 55,
    check: false,
    succeeded: false,
  }),
  methods: {
    async transfer() {
      try {
        this.loading = true;
        this.error = false;
        await TICKET.transfer(
          this.selectedOrganization.id,
          this.party.id,
          this.ticket.id,
          {
            user: { phone: this.whatsapp, ddi: this.ddi },
          }
        );
        this.succeeded = true;
        this.loading = false;
      } catch (error) {
        this.error = error.message || "Erro ao transferir ingresso";
        this.check = false;
        this.loading = false;
      }
    },
    open(ticket) {
      this.ticket = ticket;
      this.succeeded = false;
      this.error = false;
      this.dialog = true;
    },
    close(success = false) {
      if (this.loading) return;
      this.dialog = false;
      if (success) this.$emit("success");
      this.$nextTick(() => {
        this.whatsapp = "";
        this.ddi = 55;
        this.check = false;
        this.error = false;
        this.succeeded = false;
      });
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("organization", ["selectedOrganization"]),
    transferDisabled() {
      if (!this.ticket) return { icon: "mdi-error", text: "Erro ao carregar" };

      const partyDate = this.party.endDate || this.party.date;
      if (moment(partyDate).isBefore(moment()))
        return {
          icon: "mdi-ticket",
          text: "Não é possível transferir ingressos após o evento",
        };

      return false;
    },
  },
  watch: {
    whatsapp() {
      this.error = false;
    },
  },
  mounted() {
    this.$root.$on("transfer-ticket", this.open);
  },
};
</script>

<style></style>

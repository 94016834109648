var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-2 d-flex flex-column gap-4"},[_c('div',[_c('h6',[_vm._v("Vendas no Site")]),_c('div',{staticClass:"d-flex justify-space-between gap-2 flex-wrap"},[_c('v-card',{staticClass:"pa-4 flex-1 card-min-w",attrs:{"dark":"","color":"deep-purple darken-4","rounded":"lg","outlined":"","disabled":_vm.party.lockedMaxTickets},on:{"click":function($event){_vm.maxTickets = 1}}},[_c('div',{staticClass:"d-flex align-center mb-2 justify-space-between"},[_c('h6',{staticClass:"mb-0"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-lock")]),_vm._v(" Anti-Cambistas ")],1),_c('v-icon',[_vm._v(" mdi-"+_vm._s(_vm.maxTickets === 1 ? "radiobox-marked" : "radiobox-blank")+" ")])],1),_c('ul',[_c('li',[_vm._v("Apenas 1 ingresso por conta")]),_c('li',[_vm._v("Identificação obrigatória do comprador")])])]),_c('v-card',{staticClass:"pa-4 flex-1 card-min-w",attrs:{"dark":"","color":"blue darken-3","outlined":"","rounded":"lg","disabled":_vm.party.lockedMaxTickets},on:{"click":function($event){_vm.maxTickets = 2}}},[_c('div',{staticClass:"d-flex align-center mb-2 justify-space-between"},[_c('h6',{staticClass:"mb-0"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-ticket-account")]),_vm._v(" Venda Controlada ")],1),_c('v-icon',[_vm._v(" mdi-"+_vm._s(_vm.maxTickets > 1 ? "radiobox-marked" : "radiobox-blank")+" ")])],1),_c('ul',[_c('li',[_vm._v("Escolha a quantidade máxima de ingressos por conta")]),_c('li',[_vm._v("Identificação obrigatória do comprador")])]),_c('v-slide-y-transition',[_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.maxTickets > 1),expression:"maxTickets > 1"}],staticClass:"mt-4",attrs:{"disabled":_vm.party.lockedMaxTickets,"type":"number","label":"Máximo de ingressos por conta","outlined":"","dense":"","min":"2","max":"15","dark":"","color":"white","rules":[
              (v) => !!v || 'Campo obrigatório',
              (v) => v > 1 || 'Mínimo 2 ingressos',
            ],"hide-details":"auto"},model:{value:(_vm.maxTicketsInput),callback:function ($$v) {_vm.maxTicketsInput=$$v},expression:"maxTicketsInput"}})],1)],1),_c('v-card',{staticClass:"pa-4 flex-1 card-min-w",attrs:{"dark":"","color":"light-blue darken-2","outlined":"","rounded":"lg","disabled":_vm.party.lockedMaxTickets},on:{"click":function($event){_vm.maxTickets = 0}}},[_c('div',{staticClass:"d-flex align-center mb-2 justify-space-between"},[_c('h6',{staticClass:"mb-0"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-ticket-outline")]),_vm._v(" Venda Livre ")],1),_c('v-icon',[_vm._v(" mdi-"+_vm._s(_vm.maxTickets === 0 ? "radiobox-marked" : "radiobox-blank")+" ")])],1),_c('ul',[_c('li',[_vm._v("Sem limite de ingressos por conta")]),_c('li',[_vm._v("Identificação opcional do comprador no PDV")])]),_c('v-slide-y-transition',[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.maxTickets === 0),expression:"maxTickets === 0"}],staticClass:"mb-0 mt-2",attrs:{"dense":"","color":"white","dark":"","text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-alert")]),_c('span',{staticClass:"caption"},[_vm._v(" Atenção: Venda livre pode gerar cambistas ")])],1)],1)],1)],1)]),_c('v-divider'),_c('v-alert',{staticClass:"mb-0",attrs:{"dense":"","color":`${_vm.posRestrict ? 'warning' : 'blue'} darken-4`,"text":""}},[_c('div',{staticClass:"d-flex justify-space-between gap-2"},[_c('b',[_vm._v("Vendas em PDV")]),_c('v-switch',{staticClass:"ma-0 pa-0",attrs:{"label":"Restringir vendas em PDV","disabled":_vm.party.lockedMaxTickets,"color":"error","hide-details":""},model:{value:(_vm.posRestrict),callback:function ($$v) {_vm.posRestrict=$$v},expression:"posRestrict"}})],1),(_vm.posRestrict)?_c('div',[_c('v-text-field',{staticClass:"mt-4 mb-1",attrs:{"disabled":_vm.party.lockedMaxTickets,"type":"number","label":"Máximo de ingressos por conta","outlined":"","dense":"","min":"1","max":"15","rules":[
          (v) => !!v || 'Campo obrigatório',
          (v) => v > 0 || 'Mínimo 1 ingresso',
        ],"hide-details":"auto"},model:{value:(_vm.maxTicketsPosInput),callback:function ($$v) {_vm.maxTicketsPosInput=$$v},expression:"maxTicketsPosInput"}})],1):_c('div',[_vm._v("Não há restrição de vendas em PDV")])]),(_vm.party.lockedMaxTickets)?_c('v-alert',{attrs:{"dense":"","type":"warning","text":""}},[_vm._v(" A política de restrição de venda está bloqueada, entre em contato com o suporte saber mais ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
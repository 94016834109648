<template>
  <v-timeline-item
    :color="displayColor"
    :icon="categories[displayCategory].icon"
    fill-dot
  >
    <v-img
      v-if="attraction.reveled && displayImage"
      :src="displayImage"
      class="rounded"
      max-height="200"
      contain
    />
    <!-- This div is only to remove arrow from vuetify -->
    <div>
      <v-card outlined rounded="lg">
        <v-card-text v-if="attraction.reveled" class="pb-1">
          <div class="d-flex gap-3">
            <div
              v-if="attraction.Artist"
              style="flex-shrink: 0; margin-top: -25px; margin-left: -25px"
            >
              <v-avatar size="75">
                <v-img
                  :src="attraction.Artist.profilePhoto"
                  class="rounded"
                  :aspect-ratio="1"
                />
              </v-avatar>
            </div>
            <div class="flex-grow-1">
              <span
                v-if="displayCategory != 'OTHER'"
                style="line-height: 1rem"
                class="text-overline"
              >
                {{
                  attraction.Artist?.category ||
                  categories[displayCategory].text
                }}
              </span>
              <span v-if="attraction.timed" class="text-right float-right">
                {{ attraction | startEndDate }}
              </span>
              <h5 :class="`lh-3 font-weight-medium mb-0 ${displayColor}--text`">
                {{ displayName }}
              </h5>
            </div>
          </div>
          <description-wrap :lines="2" :description="displayDescription" />
          <spotify-embed
            class="mt-3 d-flex flex-column"
            v-if="displaySpotifyId"
            :id="displaySpotifyId"
          />
          <small class="text--disabled d-block text-center mt-1">
            Atração sujeita a alterações sem aviso prévio.
          </small>
        </v-card-text>
        <v-card-text v-else>
          <h5 :class="`lh-3 font-weight-medium ${attraction.color}--text`">
            {{ categories[attraction.category].text }}
          </h5>
          <v-alert
            :color="attraction.color"
            small
            text
            dense
            style="width: 100%"
            class="justify-center white--text mb-0 mt-1"
          >
            Não divulgado
          </v-alert>
        </v-card-text>
      </v-card>
    </div>
  </v-timeline-item>
</template>

<script>
import moment from "moment";
import DescriptionWrap from "../DescriptionWrap.vue";
import AttractionCategories from "@/definitions/AttractionCategories.json";
import SpotifyEmbed from "../../../components/global/SpotifyEmbed.vue";

export default {
  data: () => ({
    categories: AttractionCategories,
  }),
  components: { DescriptionWrap, SpotifyEmbed },
  props: {
    attraction: {
      type: Object,
      required: true,
    },
    selectedArtist: {
      type: Object,
      default: null,
    },
  },
  computed: {
    displayName() {
      return (
        this.attraction.name ||
        this.attraction.Artist?.name ||
        this.selectedArtist?.name
      );
    },
    displayDescription() {
      return (
        this.attraction.description ||
        this.attraction.Artist?.shortDescription ||
        this.attraction.Artist?.description ||
        this.selectedArtist?.shortDescription
      );
    },
    displayColor() {
      return (
        this.attraction.color ||
        this.attraction.Artist?.color ||
        this.selectedArtist?.color ||
        "indigo"
      );
    },
    displaySpotifyId() {
      return (
        this.attraction.spotifyId ||
        this.attraction.Artist?.spotifyId ||
        this.selectedArtist?.spotifyId
      );
    },
    displayImage() {
      return this.attraction.image;
    },
    displayCategory() {
      return (
        this.attraction.category || (this.attraction.Artist ? "STAGE" : "OTHER")
      );
    },
  },
};
</script>

<style></style>

<template>
  <v-dialog
    v-model="isOpen"
    :width="800"
    scrollable
    :fullscreen="$vuetify.breakpoint.smAndDown"
    content-class="rounded-lg"
  >
    <v-card class="h-full d-flex flex-column">
      <v-card-title> Virar lotes selecionados </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row no-gutters class="mx-0">
            <v-col cols="12" class="d-flex align-center justify-space-around">
              <h6 class="mb-0 text-center">Antigo</h6>
              <h6 class="mb-0 text-center">Novo</h6>
            </v-col>
            <v-col
              v-for="(tb, index) in ticketBlocks"
              cols="12"
              class="py-0"
              :key="tb.id"
            >
              <v-row>
                <v-col cols="12" sm="">
                  <v-card outlined elevation="0" rounded="lg" class="h-full">
                    <v-card-text class="d-flex flex-column h-full pa-3">
                      <h6>{{ tb.name }}</h6>
                      <v-spacer />
                      <div class="d-flex justify-space-around align-center">
                        <div class="d-flex flex-column align-center flex-1">
                          <h6 class="mb-0">{{ tb.price | currency(true) }}</h6>
                          <span>Preço</span>
                        </div>
                        <div class="d-flex flex-column align-center flex-1">
                          <h6 class="mb-0">{{ tb.quantity }}</h6>
                          <span>Quantidade</span>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="auto" class="px-0 align-self-center">
                  <v-icon> mdi-arrow-right-thin </v-icon>
                </v-col>
                <v-col cols="12" sm="">
                  <v-card class="pa-3" outlined elevation="0" rounded="lg">
                    <v-text-field
                      v-model="newTicketBlocks[index].name"
                      label="Nome do novo lote"
                      hide-details="auto"
                      class="mb-3"
                      :rules="[(v) => !!v || 'Nome é obrigatório']"
                      outlined
                      required
                      dense
                    ></v-text-field>
                    <div class="d-flex gap-2">
                      <v-text-field
                        v-model="newTicketBlocks[index].price"
                        prefix="R$"
                        label="Preço"
                        hide-details="auto"
                        outlined
                        :rules="[
                          (v) => v >= 0 || 'Preço deve ser positivo',
                          (v) =>
                            v > tb.price ||
                            'Preço deve ser maior que o anterior',
                        ]"
                        type="number"
                        required
                        dense
                        class="flex-1"
                      ></v-text-field>

                      <v-text-field
                        v-model="newTicketBlocks[index].quantity"
                        label="Quantidade"
                        hide-details="auto"
                        outlined
                        :rules="[
                          (v) => v > 1 || 'Quantidade deve ser maior que 1',
                        ]"
                        type="number"
                        required
                        dense
                        class="flex-1"
                      ></v-text-field>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="py-2"></v-divider>
          <div class="d-flex gap-3">
            <v-text-field
              v-if="!turnNow"
              label="Início das vendas"
              :min="new Date().toISOString().substr(0, 16)"
              :max="endDate || partyEndDateMax"
              v-model="startDate"
              dense
              type="datetime-local"
              :rules="[(v) => !!v || 'Data de início é obrigatória']"
              hide-details
              outlined
            ></v-text-field>
            <v-text-field
              label="Fim das vendas"
              :max="partyEndDateMax"
              v-model="endDate"
              dense
              type="datetime-local"
              :rules="[(v) => !!v || 'Data de fim é obrigatória']"
              hide-details
              outlined
            ></v-text-field>
          </div>
          <v-switch
            v-model="turnNow"
            hide-details
            label="Virar lotes agora"
            class="mt-4"
          ></v-switch>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex align-end gap-1">
        <v-spacer />
        <v-btn text :disabled="loading" @click="close"> Cancelar </v-btn>
        <v-btn
          color="primary"
          class="ml-2"
          :loading="loading"
          @click="submit"
          :disabled="!isFormValid || loading"
        >
          Criar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import TICKET from "@/services/admin/ticket";

export default {
  data: () => ({
    isOpen: false,
    loading: false,
    ticketBlocks: [],
    newTicketBlocks: [],
    turnNow: true,
    endDate: "",
    startDate: "",
    error: false,
  }),
  methods: {
    incrementName(name) {
      // Expressão regular para encontrar números
      const regex = /(\d+)/g;
      const matches = name.match(regex);
      if (matches && matches.length > 0) {
        // Pegar o último número encontrado
        const lastNumber = matches[matches.length - 1];
        const incrementedNumber = parseInt(lastNumber) + 1;
        // Substituir apenas a última ocorrência do número
        const index = name.lastIndexOf(lastNumber);
        return (
          name.substring(0, index) +
          incrementedNumber +
          name.substring(index + lastNumber.length)
        );
      } else {
        // Se não encontrar número, adicionar " 2" ao nome
        return name + " 2";
      }
    },
    open(ticketBlocks = []) {
      this.isOpen = true;
      this.ticketBlocks = ticketBlocks;

      this.newTicketBlocks = this.ticketBlocks.map((tb) => ({
        name: this.incrementName(tb.name),
        price: tb.price,
        quantity: tb.quantity,
      }));

      this.endDate = "";
      this.startDate = "";
      this.turnNow = true;

      this.$nextTick(() => {
        if (this.$refs.form) {
          // Removemos o reset do formulário para evitar perder os valores dos inputs
          // this.$refs.form.reset();
          this.$refs.form.resetValidation();
        }
      });
    },
    close() {
      this.isOpen = false;
      this.loading = false;
      this.error = false;
      this.ticketBlocks = [];
      this.newTicketBlocks = [];
      this.turnNow = true;
    },
    async submit() {
      try {
        const isValid = await this.$refs.form.validate();
        if (!isValid) return;

        if (!this.endDate) {
          window.alert("Data de fim é obrigatória");
          return;
        }

        if (!this.turnNow && !this.startDate) {
          window.alert("Data de início é obrigatória");
          return;
        }

        this.loading = true;
        const orgId = this.party.organizationId;
        const partyId = this.party.id;

        const now = new Date();

        const endDateForOldBlocks = this.turnNow
          ? now.toISOString()
          : new Date(this.startDate).toISOString();

        const updates = this.ticketBlocks.map(async (ticketBlock) => {
          const data = { ...ticketBlock };
          data.endDate = endDateForOldBlocks;

          data.sellers = ticketBlock.sellers;
          data.posSellers = ticketBlock.posSellers;

          await TICKET.updateTicketBlock(orgId, partyId, data);

          ticketBlock.endDate = data.endDate;
        });

        await Promise.all(updates);

        const creations = this.newTicketBlocks.map(async (newTb, index) => {
          const originalTb = this.ticketBlocks[index];
          const data = {
            ...originalTb,
            id: undefined,
            name: newTb.name,
            price: newTb.price,
            quantity: parseInt(newTb.quantity),
            startDate: this.turnNow
              ? now.toISOString()
              : new Date(this.startDate).toISOString(),
            endDate: new Date(this.endDate).toISOString(),
            ticketGroup: originalTb.ticketGroupId,
          };

          data.sellers = originalTb.sellers;
          data.posSellers = originalTb.posSellers;

          await TICKET.createTicketBlock(orgId, partyId, data);
        });

        await Promise.all(creations);

        window.alert("Lotes virados com sucesso");
        this.close();
        this.$emit("refresh");
      } catch (error) {
        console.error(error);
        window.alert("Erro ao virar lotes");
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["config"]),
    ...mapGetters("organization", ["memberships", "selectedOrganization"]),
    partyEndDateMax() {
      return new Date(this.party.endDate).toISOString().substr(0, 16);
    },
    isFormValid() {
      const areNewTicketBlocksValid = this.newTicketBlocks.every((newTb) => {
        return (
          newTb.name &&
          newTb.price !== null &&
          newTb.price !== undefined &&
          newTb.quantity !== null &&
          newTb.quantity !== undefined
        );
      });

      const isEndDateValid = !!this.endDate;

      const isStartDateValid = this.turnNow ? true : !!this.startDate;

      return areNewTicketBlocksValid && isEndDateValid && isStartDateValid;
    },
  },
  mounted() {
    this.$root.$on("turn-ticket-blocks-modal", this.open);
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <v-row>
      <v-col cols="12">
        <div class="d-flex align-center">
          <v-card elevation="0" class="px-1 rounded-lg">
            <v-tabs
              height="40px"
              class="transparent rounded-lg"
              v-model="selectedTab"
            >
              <v-tab>Ativo</v-tab>
              <v-tab>Encerrado</v-tab>
            </v-tabs>
          </v-card>
          <v-spacer />
          <v-btn
            v-if="config.sudoMode"
            color="primary"
            :disabled="loading"
            small
            :to="{ name: 'admin.party.create' }"
            id="btn-party-create"
          >
            <v-icon small left> mdi-plus </v-icon>
            Evento
          </v-btn>
        </div>
        <v-progress-linear v-if="loading && !parties.length" indeterminate />
      </v-col>
      <v-col md="12">
        <v-tabs-items v-model="selectedTab" class="transparent">
          <v-tab-item v-for="i in 2" :key="i">
            <v-alert v-if="!partiesSorted[selectedTab].length" text type="info">
              <p class="text-18 font-weight-bold mb-0">
                Nenhum evento {{ i === 1 ? "ativo" : "inativo" }}!
              </p>
              <span></span>
            </v-alert>
            <div v-else class="d-flex flex-column" style="gap: 1rem">
              <v-text-field
                v-if="partiesSorted[selectedTab].length > 3"
                v-model="search"
                label="Pesquisar"
                outlined
                dense
                hide-details
                class="pt-1"
              />
              <party-router-links
                v-for="item in filteredSort[selectedTab]"
                :key="item.id"
                class="px-3"
                :party="item"
                :loading="loading"
                :id="`party-${item.id}`"
              />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import PartyQrCode from "../../../components/admin/party/PartyQrCode.vue";
import PartyRouterLinks from "../../../components/admin/party/PartyRouterLinks.vue";
import PartySort from "@/utils/partySortFunction";
import PartyStatus from "@/utils/partyStatus";

export default {
  components: { PartyQrCode, PartyRouterLinks },
  metaInfo: {
    title: "Eventos",
  },
  data() {
    return {
      loading: false,
      itemsPerPage: 5,
      page: 1,
      selectedTab: 0,
      search: "",
    };
  },
  methods: {
    ...mapActions("auth", ["getPermissions"]),
    ...mapActions("organization", ["updateParties"]),
    async getParties() {
      try {
        this.loading = true;
        await this.updateParties();
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },

  computed: {
    ...mapGetters("auth", ["hasPermission", "config"]),
    ...mapGetters("organization", ["selectedOrganization", "parties"]),
    partiesSorted() {
      return this.parties
        .reduce(
          (acc, party) => {
            const status = new PartyStatus(party);
            const key = status.isActive ? 0 : 1;
            acc[key].push(party);
            return acc;
          },
          [[], []]
        )
        .map((parties) => parties.sort(PartySort));
    },
    filteredSort() {
      if (!this.search) return this.partiesSorted;
      return this.partiesSorted.map((parties) =>
        parties.filter((party) =>
          party.name.toLowerCase().includes(this.search.toLowerCase())
        )
      );
    },
    numberOfPages() {
      return Math.ceil(
        this.partiesSorted[this.selectedTab].length / this.itemsPerPage
      );
    },
  },
  watch: {
    "selectedOrganization.id"() {
      this.getParties();
    },
    selectedTab() {
      this.page = Math.min(this.page, this.numberOfPages);
    },
  },
  async mounted() {
    await this.getParties();
  },
};
</script>

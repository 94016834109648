<template>
  <v-dialog v-model="dialog" max-width="700px" @click:outside="close" eager content-class="rounded-xl">
    <v-card v-if="!!party" style="overflow: hidden">
      <table-selector
        v-if="data"
        :tableMap="data"
        :party="party"
        :tooltip="formatTooltip"
        fixedTooltip
        hideStatusBorder
        class="rounded-b-0 elevation-0"
      />
      <v-card-actions>
        <v-btn text @click="close" block class="ml-0">
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RATING from "@/services/app/rating";
import { mapGetters } from "vuex";
import TableSelector from "../../global/partyTable/TableSelector.vue";
import { Group } from "three";
export default {
  components: { TableSelector },
  props: {
    party: {
      type: Object,
      default: null,
    },
    ticket: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    dialog: false,
    data: null,
  }),
  methods: {
    open() {
      this.formattedTable();
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$emit("close");
    },
    formatTooltip(table, group) {
      return `<p class="mb-0 text-center"> Essa é sua mesa<br><b class="font-weight-bold">${group.name} • ${table.name}</b></p>`;
    },
    formattedTable() {
      if (!this.ticket) return null;
      var { Table: tempTable } = this.ticket;
      if (!tempTable) return null;
      var { Group: tempGroup, ...Table } = tempTable;

      var { Map, ...Group } = tempGroup;
      const data = {
        ...Map,
        Groups: [
          {
            ...Group,
            Tables: [
              {
                ...Table,
              },
            ],
          },
        ],
      };

      this.data = data;
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },

  mounted() {
    this.$parent.$on("tableMapView", this.open);
  },
};
</script>

<style></style>

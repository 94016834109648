<template>
  <v-dialog
    v-model="isOpen"
    width="700"
    scrollable
    :fullscreen="$vuetify.breakpoint.smAndDown"
    @click:outside="close"
    content-class="rounded-lg"
  >
    <v-card :loading="loading">
      <v-card-text class="pt-3">
        <div class="d-flex justify-space-between align-center gap-2">
          <div style="display: grid">
            <div class="d-flex gap-2">
              <h6 class="mb-0 text-truncate">
                {{ session?.name || session?.Address?.name }}
              </h6>
              <base-copier
                v-if="config.opMode"
                type="chip"
                :value="session?.id || ''"
                hide-icon
              >
                <template v-slot:default="{ copy, icon }">
                  <v-chip
                    @click="copy"
                    x-small
                    label
                    class="text-overline font-weight-medium"
                  >
                    #{{ session?.id | shortId }}
                    <v-icon right x-small>{{ icon }}</v-icon>
                  </v-chip>
                </template>
              </base-copier>
            </div>
            <span class="text-truncate" v-if="session?.Address">
              {{ session?.Address.street }}, {{ session?.Address.number }} -
              {{ session?.Address.neighborhood }} - {{ session?.Address.city }},
              {{ session?.Address.state }}
            </span>
            <span v-else>
              {{ session?.Party?.name }}
            </span>
          </div>
          <v-chip
            class="pl-2"
            :small="$vuetify.breakpoint.smAndDown"
            style="min-width: fit-content"
          >
            <v-progress-circular
              :size="$vuetify.breakpoint.smAndDown ? 16 : 20"
              :width="5"
              :rotate="0"
              :value="remaining"
              class="mr-2"
              :color="remaining > 17 ? 'primary' : 'error'"
            />
            <span
              class="lh-1 font-weight-bold text-overline"
              :style="`font-size: ${
                $vuetify.breakpoint.smAndDown ? 12 : 14
              }px !important`"
            >
              {{ code | formatTOTP }}
            </span>
          </v-chip>
        </div>
        <v-divider class="my-2" />
        <v-alert v-if="error" type="error">{{ error }}</v-alert>

        <v-tabs v-else v-model="tab" grow>
          <v-tab v-for="(t, i) in tabs" :key="i">
            {{ t.name }}
          </v-tab>
          <v-tab-item v-for="(t, i) in tabs" :key="i">
            <component
              class="mt-2"
              :is="t.component"
              :session="session || {}"
              :data="data"
              :loading="loading"
            />
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions class="d-flex align-end gap-1 justify-center">
        <v-btn text :disabled="loading" @click="close"> Fechar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import POS from "@/services/admin/pos";
import PosReportSummary from "./reportComponents/PosReportSummary.vue";
import PosReportPayments from "./reportComponents/PosReportPayments.vue";
import PosReportCashout from "./reportComponents/PosReportCashout.vue";
import PosReportCashier from "./reportComponents/PosReportCashier.vue";

export default {
  components: {
    PosReportSummary,
    PosReportPayments,
    PosReportCashout,
    PosReportCashier,
  },
  data: () => ({
    isOpen: false,
    loading: false,
    error: false,

    session: null,
    data: null,

    tab: 0,
    tabs: [
      {
        name: "Resumo",
        component: "PosReportSummary",
      },
      {
        name: "Vendas",
        component: "PosReportPayments",
      },
      {
        name: "Sangrias",
        component: "PosReportCashout",
      },
      {
        name: "Caixas",
        component: "PosReportCashier",
      },
      // {
      //   name: "Eventos",
      //   component: "PosReportSummary",
      // },
    ],

    // TOTP
    totp: null,
    code: null,
    remaining: 0,
    interval: null,
  }),

  methods: {
    open(session) {
      this.isOpen = true;
      this.loading = false;
      this.error = false;
      this.session = session;
      this.data = null;
      this.getData();

      const { TOTP, Secret } = window.OTPAuth;
      this.totp = new TOTP({
        issuer: "TicketMe",
        label: session.Device.serialNumber,
        algorithm: "SHA1",
        digits: 6,
        period: 60,
        secret: Secret.fromUTF8(`${session.id}:${session.Device.hash}`),
      });
      this.setInterval();
    },
    close() {
      this.isOpen = false;
      this.loading = false;
      this.error = false;
      this.data = null;
      this.tab = 0;
      this.session = null;
      this.code = null;
      this.totp = null;
      if (this.interval) clearInterval(this.interval);
      this.interval = null;
    },
    generateCode() {
      this.code = this.totp.generate();
    },
    setInterval() {
      if (this.interval) clearInterval(this.interval);
      this.generateCode();
      const secondsToNextCode = 60 - (new Date().getSeconds() % 60);
      this.remaining = (secondsToNextCode / 60) * 100;
      this.interval = setInterval(() => {
        if (this.remaining > 1) this.remaining -= 100 / 120;
        else {
          this.remaining = 101;
          this.generateCode();
        }
      }, 500);
    },
    async getData() {
      this.loading = true;
      try {
        const data = await POS.report(
          this.selectedOrganization.id,
          this.session.id
        );
        this.data = data;
      } catch (error) {
        this.error = error.message || "Erro ao buscar os dados";
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
  filters: {
    formatTOTP(code) {
      return code ? code.match(/.{1,2}/g).join(" ") : "";
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    ...mapGetters("auth", ["config"]),
  },
  mounted() {
    this.$parent.$on("pos-report-modal", this.open);
  },
  props: {},
  created() {},
};
</script>

<style></style>

<template>
  <v-card outlined :loading="loading">
    <v-card-title>
      <h6 class="mb-0 lh-1">Vendas Hoje</h6>
    </v-card-title>
    <v-card-text>
      <div
        v-if="reducedPayments.tickets"
        class="d-flex flex-wrap justify-space-around gap-y-3"
      >
        <div class="text-center px-4">
          <h6 class="mt-2 mb-0">
            {{ (reducedPayments.tickets - reducedPayments.courtesy) | number }}
          </h6>
          <span class="text-caption">Ingressos Vendidos</span>
        </div>
        <div class="text-center px-4">
          <h6 class="mt-2 mb-0">
            {{ reducedPayments.courtesy | number }}
          </h6>
          <span class="text-caption">Cortesias</span>
        </div>
        <div class="text-center px-4">
          <h6 class="mt-2 mb-0">
            {{ reducedPayments.tickets | number }}
          </h6>
          <span class="text-caption">Ingressos Emitidos</span>
        </div>
        <div class="text-center px-4">
          <h6 class="mt-2 mb-0">
            {{ (reducedPayments.total / reducedPayments.tickets) | currency }}
          </h6>
          <span class="text-caption">Ticket Médio</span>
        </div>
        <div class="text-center px-4">
          <h6 class="mt-2 mb-0">
            {{ reducedPayments.total | currency }}
          </h6>
          <span class="text-caption">Valor Líquido</span>
        </div>
      </div>
      <div class="text-center" v-else>
        <v-icon x-large>mdi-emoticon-sad-outline</v-icon>
        <h6 class="mt-2 mb-0">Nenhuma venda hoje</h6>
      </div>
      <v-alert v-if="error" type="error" class="mb-0">
        {{ error }}
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  data: () => ({
    error: null,
  }),
  computed: {
    ticketsFromToday() {
      return this.data.tickets.filter(({ Payment }) => {
        return (
          Payment.status === "succeeded" &&
          moment(Payment.createdAt).local().isSame(moment().local(), "day")
        );
      });
    },
    reducedPayments() {
      return this.ticketsFromToday.reduce(
        (acc, t) => {
          const p = t.Payment;
          acc.tickets += 1;
          acc.total += t.amount - t.platformFee;
          if (p.paymentType === "COURTESY") acc.courtesy += 1;
          return acc;
        },
        { tickets: 0, total: 0, courtesy: 0 }
      );
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <h6 class="mb-0">Transferencias</h6>

    <v-data-table
      :headers="headers"
      :items="ticketTransfer"
      item-key="id"
      single-expand
      show-expand
    >
      <template v-slot:item.id="{ item }"> #{{ item.id | shortId }} </template>
      <template v-slot:item.TicketBlock="{ item }">
        <span class="text-truncate text-12">
          {{ item.TicketBlock.TicketGroup?.name }}
        </span>
        <small class="d-block text-11">{{ item.TicketBlock.name }}</small>
      </template>
      <template v-slot:item.transfer="{ item }">
        {{ item.TicketTransfer.length }}
      </template>

      <template v-slot:item.buyer="{ item }">
        <span class="text-truncate text-12">
          {{ item.TicketTransfer[0].from.name || "Conta não criada" }}
        </span>
        <small class="d-block">
          {{ item.TicketTransfer[0].from.phone }}
        </small>
      </template>
      <template v-slot:item.owner="{ item }">
        <span class="text-truncate text-12">
          {{
            item.TicketTransfer[item.TicketTransfer.length - 1].to.name ||
            "Conta não criada"
          }}
        </span>
        <small class="d-block">
          {{ item.TicketTransfer[item.TicketTransfer.length - 1].to.phone }}
        </small>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="py-3">
          <v-timeline align-top dense>
            <v-timeline-item large>
              <template v-slot:icon>
                <v-tooltip slot="icon" v-if="!i" top>
                  <template v-slot:activator="{ on }">
                    <v-icon size="22" color="white" v-on="on">
                      mdi-account-cash
                    </v-icon>
                  </template>
                  <span>Comprador original</span>
                </v-tooltip>
              </template>
              <div class="d-flex gap-3 align-center" style="flex: 3">
                <baseAvatar
                  :size="30"
                  :src="item.TicketTransfer[0].from.photo"
                  :seed="item.TicketTransfer[0].from.id"
                />
                <div>
                  <span class="font-weight-bold">
                    {{ item.TicketTransfer[0].from.name || "Conta não criada" }}
                  </span>
                  <small class="d-block">
                    {{ item.TicketTransfer[0].from.phone }}
                  </small>
                </div>
              </div>
              <v-divider class="mt-4" />
            </v-timeline-item>
            <v-timeline-item
              v-for="(transfer, i) in item.TicketTransfer"
              :key="transfer.id"
              class="pb-0"
              :color="
                i + 1 == item.TicketTransfer.length ? 'success' : 'primary'
              "
            >
              <template v-slot:icon>
                <span class="white--text font-weight-bold">{{ i + 1 }}</span>
              </template>
              <div class="d-flex gap-3" style="flex: 3">
                <baseAvatar
                  class="mt-1"
                  :size="30"
                  :src="transfer.to.photo"
                  :seed="transfer.to.id"
                />
                <div class="flex-grow-1">
                  <span class="font-weight-bold">
                    {{ transfer.to.name || "Conta não criada" }}
                  </span>
                  <small class="d-block lh-1">
                    {{ transfer.to.phone }}
                  </small>
                </div>
                <small class="text-overline lh-1">
                  {{ transfer.createdAt | date("DD/MM/YY HH:mm") }}
                </small>
              </div>
              <v-divider class="my-4" />
            </v-timeline-item>
          </v-timeline>
          <!-- <v-list>
            <v-list-item
              v-for="(transfer, i) in item.TicketTransfer"
              :key="transfer.id"
              class="px-0"
            >
              <div
                class="d-flex justify-space-between align-center flex-grow-1"
              >
                <div class="d-flex gap-2 align-center" style="flex: 3">
                  <baseAvatar
                    :size="25"
                    :src="transfer.from.photo"
                    :seed="transfer.from.id"
                  />
                  <div>
                    <span>{{ transfer.from.name || "Conta não criada" }}</span>
                    <v-tooltip v-if="!i" top>
                      <template v-slot:activator="{ on }">
                        <v-icon small right v-on="on">mdi-account-cash</v-icon>
                      </template>
                      <span>Comprador original</span>
                    </v-tooltip>
                    <small class="d-block">
                      {{ transfer.from.phone }}
                    </small>
                  </div>
                </div>

                <div class="d-flex flex-column align-center" style="flex: 2">
                  <v-icon small>mdi-arrow-right</v-icon>
                  <small class="lh-1 text-center">
                    {{ transfer.createdAt | date("DD/MM/YY HH:mm") }}
                  </small>
                </div>

                <div class="d-flex gap-2 align-center" style="flex: 3">
                  <baseAvatar
                    :size="20"
                    :src="transfer.to.photo"
                    :seed="transfer.to.id"
                  />
                  <div>
                    <span>{{ transfer.to.name || "Conta não criada" }}</span>
                    <small class="d-block">
                      {{ transfer.to.phone }}
                    </small>
                  </div>
                </div>
              </div>
            </v-list-item>
          </v-list> -->
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data: () => ({
    headers: [
      {
        text: "ID",
        value: "id",
        sortable: false,
        width: 10,
        cellClass: "text-overline lh-1 px-1 text-12",
      },
      { text: "Lote", value: "TicketBlock", sortable: false },
      { text: "Transferencias", value: "transfer", align: "center" },
      { text: "Comprador", value: "buyer" },
      { text: "Proprietário Atual", value: "owner" },
      { text: "", value: "data-table-expand", cellClass: "px-1" },
    ],
  }),
  computed: {},
  props: {
    guest: {
      type: Object,
      default: () => ({}),
    },
    ticketTransfer: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style>
</style>
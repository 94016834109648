<template>
  <div
    style="width: 100%"
    :style="bgStyle"
    class="d-flex flex-column align-center justify-end pa-4 rounded-lg"
  >
    <v-card style="width: 100%" elevation="0" class="rounded-lg">
      <v-card-text class="d-flex flex-column">
        <template v-if="party?.locationReveled && !!party?.Address">
          <h6 class="mb-0">{{ party?.Address.name }}</h6>

          <p class="text-truncate mb-0">
            {{ fullAddress }}
          </p>
          <v-btn @click="openMaps" small block color="primary" class="mt-1">
            Ver no mapa
          </v-btn>
        </template>
        <template v-else-if="!!party?.Address">
          <h6>Endereço não revelado</h6>
          <p class="text-truncate mb-0">
            {{ fullAddress }}
          </p>
        </template>
        <template v-else>
          <v-skeleton-loader
            v-if="loading"
            type="heading"
            class="mb-0"
            height="22"
          />

          <h6 v-else class="mb-0">Endereço não informado</h6>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
const { VUE_APP_MAPS_KEY } = process.env;

export default {
  computed: {
    geometry() {
      if (!this.party?.Address?.lat || !this.party?.Address?.lng) return null;
      return {
        lat: this.party.Address.lat,
        lng: this.party.Address.lng,
      };
    },
    fullAddress() {
      if (!this.party?.Address) return "";
      const addrs = this.party.Address;
      var address = "";
      if (this.party.locationReveled) {
        if (addrs.street) {
          address += `${addrs.street}`;
          if (addrs.number) address += `, ${addrs.number}`;
        }
        if (addrs.neighborhood) address += ` - ${addrs.neighborhood}`;
        if (addrs.complement) address += ` - ${addrs.complement}`;
        if (address) address += ` - `;
        address += `${addrs.city} - ${addrs.state}`;
        if (addrs.zipCode) address += ` - ${addrs.zipCode}`;
      } else {
        address = `${addrs.city} - ${addrs.state}`;
      }

      return address;
    },
    bgUrl() {
      if (!!this.party?.Address) {
        const point = this.geometry
          ? `${this.geometry.lat},${this.geometry.lng}`
          : this.fullAddress;

        const url = new URL(`https://maps.googleapis.com/maps/api/staticmap`);
        url.searchParams.set("center", point);
        url.searchParams.set("zoom", this.party?.locationReveled ? "14" : "11");
        url.searchParams.set("size", "900x295");
        url.searchParams.set("key", VUE_APP_MAPS_KEY);
        if (this.party?.locationReveled)
          url.searchParams.set("markers", `color:blue|${point}`);
        if (this.$vuetify.theme.dark)
          url.searchParams.set("map_id", "63f4baff8ff4ba69");

        return `url(${url.toString()})`;
      } else
        return "url(https://firebasestorage.googleapis.com/v0/b/ticketme-2f29d.appspot.com/o/template-map.jpeg?alt=media&token=70608367-b60e-47d4-9aa0-969169d50092)";
    },
    bgStyle() {
      return {
        backgroundImage: this.bgUrl,
        "min-height": !!this.party?.Address ? "325px" : "auto",
        backgroundPosition: "center top",
        backgroundSize: "cover",
      };
    },
  },
  methods: {
    openMaps() {
      try {
        let address = this.geometry
          ? `${this.geometry.lat},${this.geometry.lng}`
          : this.fullAddress;
        let url = `https://www.google.com/maps/dir/?api=1&parameters&destination=${address}`;
        window.open(encodeURI(url), "_blank").focus();
      } catch (e) {
        console.log("Erro ao abrir o mapa", e);
      }
    },
  },
  props: {
    party: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>

import http from "@/http-common";

const getTickets = async (orgId, partyId, params) => {
  return await http
    .get(`/admin/organization/${orgId}/party/${partyId}/ticket`, { params })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const createTicketGroup = async (orgId, partyId, data) => {
  return await http
    .post(`/admin/organization/${orgId}/party/${partyId}/ticket/group`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const updateTicketGroup = async (orgId, partyId, data) => {
  return await http
    .put(`/admin/organization/${orgId}/party/${partyId}/ticket/group`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const deleteTicketBlock = async (orgId, partyId, ticketBlockId) => {
  return await http
    .delete(`/admin/organization/${orgId}/party/${partyId}/ticket/block/${ticketBlockId}`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const createTicketBlock = async (orgId, partyId, data) => {
  return await http
    .post(`/admin/organization/${orgId}/party/${partyId}/ticket/block`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const updateTicketBlock = async (orgId, partyId, data) => {
  return await http
    .put(`/admin/organization/${orgId}/party/${partyId}/ticket/block`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const reorderTicketBlock = async (orgId, partyId, data) => {
  return await http
    .put(
      `/admin/organization/${orgId}/party/${partyId}/ticket/block/reorder`,
      data
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const reorderTicketGroup = async (orgId, partyId, data) => {
  return await http
    .put(
      `/admin/organization/${orgId}/party/${partyId}/ticket/group/reorder`,
      data
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
}

const getKitItemsOptions = async (orgId, partyId, ticketGroupId) => {
  return await http
    .get(
      `/admin/organization/${orgId}/party/${partyId}/ticket/group/${ticketGroupId}/kit/items`
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const createKit = async (orgId, partyId, data) => {
  return await http
    .post(`/admin/organization/${orgId}/party/${partyId}/ticket/kit`, {
      kit: data,
    })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const updateKit = async (orgId, partyId, data) => {
  return await http
    .put(`/admin/organization/${orgId}/party/${partyId}/ticket/kit`, {
      kit: data,
    })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const canSell = async (orgId, partyId) => {
  return await http
    .get(`/admin/organization/${orgId}/party/${partyId}/ticket/sell`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const sell = async (orgId, partyId, data) => {
  return await http
    .post(`/admin/organization/${orgId}/party/${partyId}/ticket/sell`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const sellKit = async (orgId, partyId, data) => {
  return await http
    .post(`/admin/organization/${orgId}/party/${partyId}/ticket/sell/kit`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const sellVerify = async (orgId, partyId, data) => {
  return await http
    .post(
      `/admin/organization/${orgId}/party/${partyId}/ticket/sell/verify`,
      data
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const sellMoney = async (orgId, partyId, data) => {
  return await http
    .post(
      `/admin/organization/${orgId}/party/${partyId}/ticket/sell/money`,
      data
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const sellKitMoney = async (orgId, partyId, data) => {
  return await http
    .post(
      `/admin/organization/${orgId}/party/${partyId}/ticket/sell/kit/money`,
      data
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const sellLot = async (orgId, partyId, data) => {
  return await http
    .post(`/admin/organization/${orgId}/party/${partyId}/ticket/sell/lot`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const updateTicketPayment = async (orgId, partyId, ticketId) => {
  return await http
    .put(
      `/admin/organization/${orgId}/party/${partyId}/ticket/${ticketId}/payment`
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const transfer = async (orgId, partyId, ticketId, data) => {
  return await http
    .put(
      `/admin/organization/${orgId}/party/${partyId}/ticket/${ticketId}/transfer`,
      data
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const refund = async (orgId, partyId, ticketId, data) => {
  return await http
    .put(
      `/admin/organization/${orgId}/party/${partyId}/ticket/${ticketId}/refund`,
      data
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const resend = async (orgId, partyId, ticketId) => {
  return await http
    .put(
      `/admin/organization/${orgId}/party/${partyId}/ticket/${ticketId}/resend`
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

export default {
  transfer,
  refund,
  resend,
  canSell,
  sell,
  sellKit,
  sellVerify,
  sellMoney,
  sellKitMoney,
  sellLot,
  getTickets,
  getKitItemsOptions,
  updateTicketPayment,
  createTicketGroup,
  updateTicketGroup,
  createTicketBlock,
  updateTicketBlock,
  deleteTicketBlock,
  createKit,
  updateKit,
  reorderTicketBlock,
  reorderTicketGroup,
};

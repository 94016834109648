<template>
  <v-expansion-panel class="elevation-0">
    <v-expansion-panel-header>
      <div class="d-flex align-center justify-space-between">
        <span class="font-weight-bold">
          {{ coupon.name }}
        </span>
        <v-chip :color="couponStatus.color" small label>
          {{ couponStatus.text }}
        </v-chip>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <h6 class="mt-4">Descrição</h6>
      <p>{{ coupon.description || "Sem descrição" }}</p>
      <v-divider class="my-4" />

      <h6 class="mt-4">Detalhes</h6>
      <div class="d-flex flex-wrap gap-3">
        <v-card outlined class="rounded-lg flex-grow-1 flex-1">
          <div class="d-flex align-center gap-3 pa-2 h-full">
            <v-icon size="28">mdi-calendar-range</v-icon>
            <div>
              <span class="text-overline" style="line-height: 1em">
                Válido até
              </span>
              <p v-if="coupon.validUntil" class="mb-0 font-weight-bold">
                {{ coupon.validUntil | date("DD/MM/YYYY HH:mm") }}
              </p>
              <p v-else class="mb-0 font-weight-bold">Sem limite de validade</p>
            </div>
          </div>
        </v-card>
        <v-card outlined class="rounded-lg flex-grow-1 flex-1">
          <div class="d-flex align-center gap-3 pa-2 h-full">
            <v-icon size="28">mdi-ticket</v-icon>
            <div>
              <span class="text-overline" style="line-height: 1em">
                Máx. de ingressos por compra
              </span>
              <p class="mb-0 font-weight-bold">
                {{ coupon.maxTickets || "Ilimitado" }}
              </p>
            </div>
          </div>
        </v-card>
        <v-card outlined class="rounded-lg flex-grow-1 flex-1">
          <div class="d-flex align-center gap-3 pa-2 h-full">
            <v-icon size="28">mdi-ticket-percent</v-icon>
            <div>
              <span class="text-overline" style="line-height: 1em">
                Códigos
              </span>
              <p class="mb-0 font-weight-bold">
                {{ coupon._count.Codes | number }}
                código{{ coupon._count.Codes === 1 ? "" : "s" }}
              </p>
            </div>
          </div>
        </v-card>
      </div>

      <h6 class="mt-4">Vendas</h6>
      <v-card outlined elevation="0" class="pa-3">
        <v-data-table
          :headers="headers"
          :items="payments"
          :loading="loading"
          :items-per-page="25"
          dense
          :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100, -1] }"
        >
          <template v-slot:item.initialPrice="{ item }">
            {{ (item.amount + item.discount) | currency }}
          </template>
          <template v-slot:item.amount="{ item }">
            {{ item.amount | currency }}
          </template>
          <template v-slot:item.discount="{ item }">
            {{ item.discount | currency }}
            <i class="text-11 ml-2">{{
              (item.discount / (item.amount + item.discount)) | percentage
            }}</i>
          </template>
          <template v-slot:item.paymentType="{ item }">
            {{ paymentTypes[item.paymentType].text }}
          </template>
          <template v-slot:item.paymentMethod="{ item }">
            {{ paymentMethods[item.paymentMethod].text }}
          </template>
          <template v-slot:item.createdAt="{ item }">
            {{ item.createdAt | date("DD/MM/YYYY HH:mm") }}
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip :color="item.status | status('color')" small label>
              {{ item.status | status("text") }}
            </v-chip>
          </template>
          <template v-slot:item.platformFee="{ item }">
            {{ item.platformFee | currency }}
          </template>
        </v-data-table>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import PAYMENT from "@/utils/payment";
import COUPON from "@/services/admin/party/coupon";
import moment from "moment";

export default {
  props: {
    coupon: {
      type: Object,
      required: true,
    },
    expanded: {
      type: Boolean,
      required: true,
    },
    party: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    coupons: [],
    paymentTypes: PAYMENT.paymentType,
    paymentMethods: PAYMENT.paymentMethod,
    headers: [
      { text: "Original", value: "initialPrice" },
      { text: "Desconto", value: "discount" },
      { text: "Valor pago", value: "amount" },
      { text: "Tipo de Pagamento", value: "paymentType" },
      { text: "Método de Pagamento", value: "paymentMethod" },
      { text: "Data", value: "createdAt" },
      { text: "Status", value: "status" },
    ],
    payments: [],
    loading: false,
  }),
  watch: {
    expanded(newVal) {
      if (newVal) {
        if (this.loading) return;
        if (this.payments.length) return;
        this.getPayments(this.coupon.id);
      }
    },
  },
  computed: {
    couponStatus() {
      if (!this.coupon.active) return { text: "Inativo", color: "error" };
      if (moment(this.coupon.validUntil).isBefore(moment()))
        return { text: "Encerrado", color: "error" };
      return { text: "Ativo", color: "success" };
    },
  },
  methods: {
    async getPayments(couponId) {
      try {
        this.loadingTable = true;
        const { payments } = await COUPON.getPayment(
          this.party.organizationId,
          this.party.id,
          couponId
        );
        this.payments = payments;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingTable = false;
      }
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <div>
      <h6 class="mb-0">Selecione os vendedores</h6>
      <v-text-field
        v-model="search"
        label="Pesquisar"
        outlined
        dense
        class="my-2"
        hide-details
      ></v-text-field>
    </div>

    <div>
      <v-chip-group v-model="type" class="mb-2" color="primary" mandatory>
        <v-chip v-for="t in typesMapped" :key="t.value" :value="t.value">
          <v-icon v-if="t.icon" small left>{{ t.icon }}</v-icon>
          {{ t.text }}
          <span v-if="t.total" class="ml-1">
            • {{ t.count }}/{{ t.total }}
          </span>
        </v-chip>
      </v-chip-group>
    </div>
    <v-data-table
      v-model="sellers"
      :headers="sellersOptions.headers"
      :items="filteredList"
      item-key="id"
      :search="search"
      :items-per-page="filteredList.length"
      mobile-breakpoint="0"
      sort-by="name"
      hide-default-footer
      show-select
    >
      <template v-slot:item.name="{ item }">
        <div class="d-flex gap-3 align-center">
          <base-avatar
            v-if="item.type === 'user'"
            :src="item.photo"
            :seed="item.id"
            :size="24"
          />
          <v-icon v-else>mdi-cash-register</v-icon>
          <div>
            <b class="font-weight-medium">
              {{ item.name || item.Address?.name || "Não cadastrado" }}
            </b>
            <p class="mb-0 lh-1 text-12" v-if="item.phone">
              {{ item.phone }}
            </p>
            <p class="mb-0 lh-1 text-12" v-if="item.Address">
              {{ item.Address?.city }} - {{ item.Address?.state }}
            </p>
          </div>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { countBy } from "lodash";
import { mapGetters } from "vuex";
export default {
  emits: ["input"],
  data: () => ({
    search: "",
    type: "all",
    types: [
      { text: "Todos", value: "all" },
      { text: "Usuários", value: "user", icon: "mdi-account" },
      { text: "PDVs", value: "pos", icon: "mdi-cash-register" },
    ],
    sellersOptions: {
      headers: [
        { text: "Nome", value: "name" },
        // { text: "WhatsApp", value: "phone" },
      ],
    },
  }),
  methods: {},
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    list() {
      return [
        ...this.members.map((member) => ({
          ...member,
          type: "user",
        })),
        ...this.posSessions.map((pos) => ({
          ...pos,
          type: "pos",
        })),
      ];
    },
    filteredList() {
      return this.list.filter((s) => {
        if (this.type === "all") return true;
        return s.type === this.type;
      });
    },
    typesMapped() {
      return this.types.map((t) => ({
        ...t,
        total: this.list.filter((s) => t.value === "all" || s.type === t.value)
          .length,
        count: this.sellers.filter(
          (s) => t.value === "all" || s.type === t.value
        ).length,
      }));
    },
    sellers: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit("input", value);
      },
    },
  },
  props: {
    members: {
      type: Array,
      default: () => [],
    },
    posSessions: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
    ticketBlock: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style></style>

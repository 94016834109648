<template>
  <v-card outlined :loading="loading" v-intersect="onIntersect">
    <v-card-title class="justify-space-between">
      <h6 class="mb-0 lh-1">Ingressos por Setor</h6>
      <v-btn
        icon
        @click="() => $refs.modal.open()"
        :disabled="loading || !ticketGroups.length"
        small
      >
        <v-icon small>mdi-arrow-expand</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :items="ticketGroups"
        :headers="headers"
        hide-default-footer
        single-expand
        item-key="id"
        :items-per-page="ticketGroups.length"
        show-expand
        :expanded.sync="expanded"
      >
        <template v-slot:item.count="{ item }">
          {{ item.count }} ingresso{{ item.count == 1 ? "" : "s" }}
          <span class="d-block font-weight-bold">
            {{ item.amount | currency }}
          </span>
        </template>
        <template v-slot:item.amount="{ item }"> </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-2">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Lote</th>
                    <th class="text-right">Qtde</th>
                    <th class="text-right">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="ticket in item.TicketBlock" :key="ticket.id">
                    <td>
                      <b class="d-block">{{ ticket.name }}</b>
                    </td>
                    <td class="text-right">
                      {{ ticket.count }} Ingresso{{
                        ticket.count > 1 ? "s" : ""
                      }}
                    </td>
                    <td class="text-right">{{ ticket.amount | currency }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
    <ticket-office-report-modal ref="modal" :data="data" :loading="loading" />
  </v-card>
</template>

<script>
import TicketOfficeReportModal from "./modals/SalesByTicketBlockModal.vue";

export default {
  components: {
    TicketOfficeReportModal,
  },
  data: () => ({
    error: null,
    display: 0,
    isIntersecting: false,
    viewed: false,
    expanded: [],
    headers: [
      {
        text: "Setor",
        value: "name",
        align: "left",
      },
      { text: "Total", value: "count", align: "right" },
      {
        text: "",
        class: "px-1",
        cellClass: "px-1",
        value: "data-table-expand",
      },
    ],
  }),
  methods: {
    formatNumber(value) {
      return this.display === 0
        ? value
          ? this.$options.filters.currency(value, true)
          : ""
        : value.toFixed(0);
    },
    onIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.isIntersecting = true;
        this.viewed = true;
      } else {
        this.isIntersecting = false;
      }
    },
  },
  computed: {
    ticketGroups() {
      return Object.values(
        this.data.tickets.reduce((acc, t) => {
          if (t.Payment.status !== "succeeded") return acc;

          const block = t.TicketBlock;
          const group = block.TicketGroup;
          if (!acc[group.id])
            acc[group.id] = {
              name: group.name,
              id: group.id,
              TicketBlock: {},
              count: 0,
              amount: 0,
            };
          acc[group.id].count++;
          acc[group.id].amount += t.amount - t.platformFee;
          if (!acc[group.id].TicketBlock[block.id])
            acc[group.id].TicketBlock[block.id] = {
              name: block.name,
              count: 0,
              amount: 0,
            };
          acc[group.id].TicketBlock[block.id].count++;
          acc[group.id].TicketBlock[block.id].amount +=
            t.amount - t.platformFee;
          return acc;
        }, {})
      )
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((group) => {
          group.TicketBlock = Object.values(group.TicketBlock).sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          return group;
        });
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style></style>

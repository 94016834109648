var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Início das vendas","rules":[(v) => !v || _vm.moment(v).isValid() || 'Data inválida'],"type":"datetime-local","disabled":_vm.loading,"hide-details":"","clearable":"","outlined":"","dense":""},model:{value:(_vm.policies.onlineSaleStartsAt),callback:function ($$v) {_vm.$set(_vm.policies, "onlineSaleStartsAt", $$v)},expression:"policies.onlineSaleStartsAt"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Fim das vendas online","rules":[
          (v) => !v || _vm.moment(v).isValid() || 'Data inválida',
          (v) =>
            !v ||
            !_vm.policies.onlineSaleStartsAt ||
            _vm.moment(v).isAfter(_vm.policies.onlineSaleStartsAt) ||
            'Data deve ser maior que a data de início das vendas',
        ],"type":"datetime-local","disabled":_vm.loading,"hide-details":"","clearable":"","outlined":"","dense":""},model:{value:(_vm.policies.onlineSaleEndsAt),callback:function ($$v) {_vm.$set(_vm.policies, "onlineSaleEndsAt", $$v)},expression:"policies.onlineSaleEndsAt"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
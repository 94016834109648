<template>
  <v-dialog
    v-model="dialog"
    width="600"
    scrollable
    :persistent="loading"
    @click:outside="close"
  >
    <v-card>
      <v-card-title>
        <v-progress-linear
          :value="(currentFaqIndex / nonUsedTemplates.length) * 100"
          rounded
          height="10"
        ></v-progress-linear>
      </v-card-title>
      <v-card-text>
        <v-stepper elevation="0" v-model="currentFaqIndex">
          <v-stepper-items>
            <v-stepper-content
              v-for="(item, i) in nonUsedTemplates"
              :key="item.id"
              :step="i + 1"
              class="pa-0"
            >
              <v-card>
                <smart-faq-item
                  @close="close"
                  @next="next"
                  :template="item"
                  :party="party"
                />
              </v-card>
            </v-stepper-content>
            <v-stepper-content :step="nonUsedTemplates.length + 1">
              <v-card class="d-flex flex-column align-center">
                <v-icon size="56" class="mb-2" color="success"
                  >mdi-check-decagram</v-icon
                >
                <h5 class="black--text">Parabéns!</h5>
                <p class="text-center">
                  Você respondeu todas as perguntas frequentes disponíveis. Se
                  desejar, você pode adicionar mais perguntas frequentes.
                </p>
                <v-btn small text @click="close" :disabled="loading">
                  Fechar
                </v-btn>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import FaqTemplate from "@/data/faq-templates.json";
import SmartFaqItem from "./modules/SmartFaqItem.vue";
// import FAQ from "@/services/admin/faq";

export default {
  components: { SmartFaqItem },
  props: {
    party: {
      type: Object,
      required: true,
    },
    faqs: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    error: false,
    loading: false,
    currentFaqIndex: 1,
  }),

  methods: {
    async save() {},
    open() {
      this.dialog = true;
      this.currentFaqIndex = 1;
    },
    close() {
      this.dialog = false;
      this.$emit("refresh");
    },
    next() {
      this.currentFaqIndex++;
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    nonUsedTemplates() {
      const usedIds = new Array(
        ...new Set(this.faqs.map((faq) => faq.templateId))
      );
      return FaqTemplate.filter((template) => !usedIds.includes(template.id));
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.currentFaqIndex = 1;
      }
    },
    currentFaqIndex(val) {
      if (val > this.nonUsedTemplates.length) {
        this.$confetti.start();
        setTimeout(() => {
          this.$confetti.stop();
        }, 3000);
      }
    },
  },
  mounted() {
    this.$parent.$on("smart-faq-modal", this.open);
  },
};
</script>

<style></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column align-center"},[_c('v-carousel',{staticClass:"rounded-lg",style:({
      maxHeight: _vm.maxSize,
      minHeight: _vm.minSize,
      maxWidth: _vm.maxSize,
      minWidth: _vm.minSize,
    }),attrs:{"cycle":!_vm.disabledCycle && !_vm.fullScreen,"hide-delimiters":"","show-arrows":_vm.items.length > 1,"show-arrows-on-hover":"","height":'auto'},model:{value:(_vm.focusedIndex),callback:function ($$v) {_vm.focusedIndex=$$v},expression:"focusedIndex"}},[(_vm.items.length === 0)?_c('v-carousel-item',[_c('v-card',{staticClass:"rounded-xl",attrs:{"outlined":"","color":"#43434340"}},[_c('v-responsive',{attrs:{"aspect-ratio":1}},[_c('v-card-text',{staticClass:"h-full d-flex flex-column gap-5 justify-center align-center"},[_c('v-icon',{attrs:{"size":"100"}},[_vm._v("mdi-image-off")]),_c('span',[_vm._v("Resolução recomendada 600x600px")])],1)],1)],1)],1):_vm._e(),_vm._l((_vm.items),function(item,i){return _c('v-carousel-item',{key:i,staticClass:"rounded-lg"},[_c('v-img',{staticClass:"rounded-xl custom-cursor",attrs:{"src":item.src,"alt":item.variant?.name,"aspect-ratio":1},on:{"click":function($event){_vm.fullScreen = true}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-card',{staticClass:"h-full",attrs:{"elevation":"0"}},[_c('v-skeleton-loader',{staticClass:"rounded-lg",attrs:{"type":"image","height":"100%"}})],1)]},proxy:true}],null,true)},[_c('div',{staticClass:"pa-4",staticStyle:{"position":"absolute","top":"0","left":"0","right":"0","bottom":"0"}},[(item.variant && _vm.variants.length > 1)?_c('v-chip',{attrs:{"color":"primary","small":""}},[_vm._v(" "+_vm._s(item.variant.name)+" ")]):_vm._e()],1)])],1)})],2),(_vm.miniatures)?_c('div',{staticClass:"w-full px-3 d-flex justify-center",staticStyle:{"margin-top":"-40px"}},[_c('v-card',{staticClass:"pa-1 rounded-lg"},[(!_vm.items.length)?_c('v-alert',{staticClass:"mb-0",attrs:{"color":"warning","dense":"","text":""}},[_vm._v(" Não há imagens cadastradas ")]):_vm._e(),_c('vue-perfect-scrollbar',{ref:"miniatures",staticClass:"w-full",attrs:{"settings":{
          suppressScrollY: false,
          wheelPropagation: false,
        }}},[_c('div',[_c('div',{staticClass:"d-flex gap-1"},_vm._l((_vm.items),function(img,i){return _c('div',{key:i,on:{"click":function($event){return _vm.openItem(i)}}},[_c('v-img',{ref:`miniature-${i}`,refInFor:true,staticClass:"rounded",style:({
                  cursor: 'pointer',
                  border:
                    _vm.focusedIndex === i
                      ? '2px solid var(--v-primary-base)'
                      : '2px solid transparent',
                }),attrs:{"height":_vm.miniaturesSize,"width":_vm.miniaturesSize,"aspect-ratio":1,"src":img.src},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-card',{staticClass:"h-full",attrs:{"elevation":"0"}},[_c('v-skeleton-loader',{staticClass:"rounded",attrs:{"type":"image","height":"100%"}})],1)]},proxy:true}],null,true)})],1)}),0)])])],1)],1):_vm._e(),_c('v-dialog',{attrs:{"content-class":"dialog-no-shadow","overlay-opacity":"0.7"},model:{value:(_vm.fullScreen),callback:function ($$v) {_vm.fullScreen=$$v},expression:"fullScreen"}},[_c('v-img',{attrs:{"height":"90vh","contain":"","src":_vm.items[_vm.focusedIndex]?.src},on:{"click":function($event){_vm.fullScreen = false}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
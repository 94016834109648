<template>
  <v-dialog
    v-model="isOpen"
    width="900"
    scrollable
    :fullscreen="$vuetify.breakpoint.smAndDown"
    @click:outside="close"
    content-class="rounded-lg"
  >
    <v-card :loading="loading">
      <v-card-text class="pt-3">
        <div class="d-flex justify-space-between align-center gap-2">
          <div style="display: grid">
            <div class="d-flex gap-2">
              <h6 class="mb-0 text-truncate">
                {{ saleLink?.name }}
              </h6>
              <base-copier
                v-if="config.opMode"
                type="chip"
                :value="saleLink?.id || ''"
                hide-icon
              >
                <template v-slot:default="{ copy, icon }">
                  <v-chip
                    @click="copy"
                    x-small
                    label
                    class="text-overline font-weight-medium"
                  >
                    #{{ saleLink?.id | shortId }}
                    <v-icon right x-small>{{ icon }}</v-icon>
                  </v-chip>
                </template>
              </base-copier>
            </div>
          </div>
        </div>
        <v-divider class="my-2" />
        <v-alert v-if="error" type="error">{{ error }}</v-alert>
        <v-card
          outlined
          class="pa-2 d-flex flex-wrap justify-space-around mb-4"
        >
          <div class="text-center px-4">
            <h6 class="mt-2 mb-0">
              {{ saleLink?.views || 0 | number }}
            </h6>
            <span class="text-caption">Acessos</span>
          </div>
          <div class="text-center px-4">
            <h6 class="mt-2 mb-0">
              {{ saleLink?._count?.Ticket || 0 | number }}
            </h6>
            <span class="text-caption">Ingressos</span>
          </div>
          <div class="text-center px-4">
            <h6 class="mt-2 mb-0">
              {{ reducedAmount | currency }}
            </h6>
            <span class="text-caption">Valor Bruto</span>
          </div>
        </v-card>
        <b>Ingressos</b>
        <v-data-table
          :items="tickets || []"
          :headers="headers"
          :items-per-page="10"
          :loading="loading"
          sort-by="createdAt"
          sort-desc
        >
          <template v-slot:item.id="{ item }">
            <span class="text-overline">#{{ item.id | shortId }}</span>
          </template>
          <template v-slot:item.owner="{ item }">
            <div class="d-flex gap-2 align-center">
              <v-avatar v-if="!item.Owner.name" :size="24" class="mr-2">
                <v-icon>mdi-account-question</v-icon>
              </v-avatar>
              <base-avatar
                v-else
                :src="item.Owner.photo"
                :seed="item.Owner.id"
                :size="28"
              />
              <div>
                {{ item.Owner.name || "Não cadastrado" }}
                <small class="d-block">
                  {{ item.Owner.phone || "" }}
                </small>
              </div>
            </div>
          </template>
          <template v-slot:item.ticketGroup="{ item }">
            {{ item.TicketBlock.TicketGroup.name }}
            <small class="d-block">
              {{ item.TicketBlock.name }}
            </small>
          </template>
          <template v-slot:item.amount="{ item }">
            {{ item.amount | currency }}
          </template>
          <template v-slot:item.createdAt="{ item }">
            {{ item.createdAt | date("DD/MM/YYYY HH:mm") }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="d-flex align-end gap-1 justify-center">
        <v-btn text :disabled="loading" @click="close"> Fechar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import PARTY from "@/services/admin/party";

export default {
  components: {},
  data: () => ({
    isOpen: false,
    loading: false,
    error: false,

    saleLink: null,
    tickets: null,

    headers: [
      { text: "ID", value: "id" },
      { text: "Dono", value: "owner" },
      { text: "Lote", value: "ticketGroup" },
      { text: "Valor bruto", value: "amount" },
      { text: "Data", value: "createdAt" },
    ],
  }),

  methods: {
    open(saleLink) {
      this.isOpen = true;
      this.loading = false;
      this.error = false;
      this.saleLink = saleLink;
      this.tickets = null;
      this.getData();
    },
    close() {
      this.isOpen = false;
      this.loading = false;
      this.error = false;
      this.tickets = null;
      this.saleLink = null;
    },

    async getData() {
      this.loading = true;
      try {
        const data = await PARTY.reports.saleLink(
          this.selectedOrganization.id,
          this.party.id,
          this.saleLink.id
        );
        this.tickets = data.tickets;
      } catch (error) {
        this.error = error.message || "Erro ao buscar os dados";
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    ...mapGetters("auth", ["config"]),
    reducedAmount() {
      if (!this.tickets) return 0;
      return this.tickets.reduce((acc, ticket) => acc + ticket.amount, 0);
    },
  },
  mounted() {
    this.$parent.$on("sale-link-report", this.open);
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
  created() {},
};
</script>

<style></style>

<template>
  <div>
    <v-menu :close-on-content-click="false" v-model="menu" offset-y eager>
      <template v-slot:activator="{ on }">
        <v-chip
          small
          :color="ticketBlock.length ? 'primary' : 'warning'"
          v-on="on"
        >
          <v-icon x-small left>
            mdi-{{
              ticketBlock.length === totalTicketBlocks
                ? "check-all"
                : ticketBlock.length === 0
                ? "close"
                : "filter"
            }}
          </v-icon>
          {{
            ticketBlock.length === totalTicketBlocks
              ? "Todos os lotes"
              : ticketBlock.length === 0
              ? "Nenhum lote"
              : `${ticketBlock.length} lote${ticketBlock.length > 1 ? "s" : ""}`
          }}
        </v-chip>
      </template>
      <v-card class="pa-2" style="position: relative">
        <v-btn @click="toggleSelectAll" text block x-small class="mb-1">
          {{
            ticketBlock.length === totalTicketBlocks
              ? "Desmarcar todos"
              : "Marcar todos"
          }}
        </v-btn>
        <vue-perfect-scrollbar
          :settings="{ suppressScrollX: true, wheelPropagation: false }"
          style="max-height: 300px"
        >
          <div v-for="item in items" :key="item.id">
            <v-list-item
              @click.stop="toogleTicketGroup(item)"
              v-if="item.type == 'TicketGroup'"
              :class="
                ticketGroupSelectionStatus[item.tgId][0] === 'all'
                  ? 'v-list-item--active primary--text'
                  : ''
              "
              class="d-flex"
              style="margin: 0 -16px"
            >
              <v-simple-checkbox
                @click.stop="toogleTicketGroup(item)"
                :value="ticketGroupSelectionStatus[item.tgId][0] === 'all'"
                :indeterminate="
                  ticketGroupSelectionStatus[item.tgId][0] === 'some'
                "
              />
              <span class="text-14 font-weight-bold"> {{ item.title }} </span>
            </v-list-item>
            <v-list-item
              @click.stop="toggleTicketBlock(item.id)"
              v-if="item.type == 'TicketBlock'"
              :class="
                ticketBlock.includes(item.id)
                  ? 'v-list-item--active primary--text'
                  : ''
              "
              style="
                margin: 0 -16px;
                min-height: 35px !important;
                height: 30px !important;
              "
              class="pl-6 d-flex align-center"
              dense
            >
              <v-simple-checkbox
                @click.stop="toggleTicketBlock(item.id)"
                :value="ticketBlock.includes(item.id)"
                class="ma-0"
              />
              <span> {{ item.title }} </span>
            </v-list-item>
          </div>
        </vue-perfect-scrollbar>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    ticketGroups: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    menu: false,
    ticketBlock: [],
  }),
  watch: {
    ticketBlock: {
      handler() {
        this.$emit("input", this.ticketBlock);
      },
      immediate: true,
    },
    value: {
      handler(val, oldVal) {
        if (val === oldVal) return;
        this.ticketBlock = val;
      },
      immediate: true,
    },
  },
  methods: {
    toggleSelectAll() {
      if (this.ticketBlock.length === this.totalTicketBlocks) {
        this.ticketBlock = [];
      } else {
        this.ticketBlock = this.ticketGroups.reduce(
          (acc, tg) => [...acc, ...tg.TicketBlock.map(({ id }) => id)],
          []
        );
      }
    },
    toggleTicketBlock(ticketBlockId) {
      if (this.ticketBlock.includes(ticketBlockId)) {
        this.ticketBlock = this.ticketBlock.filter(
          (tb) => tb !== ticketBlockId
        );
      } else {
        this.ticketBlock.push(ticketBlockId);
      }
    },
    toogleTicketGroup(ticketGroup) {
      if (this.ticketGroupSelectionStatus[ticketGroup.tgId][0] === "all") {
        this.ticketBlock = this.ticketBlock.filter(
          (tb) => !ticketGroup.ticketBlocks.includes(tb)
        );
      } else {
        var newSelection = new Set([
          ...this.ticketBlock,
          ...ticketGroup.ticketBlocks,
        ]);
        this.ticketBlock = Array.from(newSelection);
      }
    },
  },
  computed: {
    totalTicketBlocks() {
      console.log("ticketGroups", this.ticketGroups);
      return this.ticketGroups.reduce(
        (acc, tg) => acc + tg.TicketBlock.length,
        0
      );
    },
    ticketGroupSelectionStatus() {
      return this.items.reduce((acc, tg) => {
        if (tg.type == "TicketBlock") return acc;
        console.log("tg", this.ticketBlock);
        const selected = this.ticketBlock.filter((tbId) =>
          tg.ticketBlocks.includes(tbId)
        );
        acc[tg.tgId] =
          selected.length === tg.ticketBlocks.length
            ? ["all", selected.length]
            : selected.length === 0
            ? ["none", selected.length]
            : ["some", selected.length];
        return acc;
      }, {});
    },
    items() {
      return this.ticketGroups.reduce((acc, tg) => {
        acc.push({
          title: tg.name,
          type: "TicketGroup",
          tgId: tg.id,
          ticketBlocks: tg.TicketBlock.map(({ id }) => id),
        });

        tg.TicketBlock.forEach((t) => {
          acc.push({
            title: t.name,
            type: "TicketBlock",
            id: t.id,
          });
        });
        return acc;
      }, []);
    },
  },
};
</script>

<style lang="scss" scooped>
.smallInput {
  .v-input__control {
    .v-input__slot {
      .v-select__slot {
        .v-select__selections {
          input {
            width: 10px;
          }
        }
      }
    }
  }
}

.mediumInput {
  .v-input__control {
    .v-input__slot {
      .v-select__slot {
        .v-select__selections {
          input {
            width: 70px;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div>
    <v-progress-linear v-if="loading" indeterminate />
    <v-card outlined class="pa-4 pb-1 rounded-lg mt-2">
      <div class="d-flex align-center justify-space-between flex-wrap">
        <h6 class="mb-0">Solicitações de Reembolsos</h6>
        <v-btn-toggle mandatory v-model="refoundRequestFilterType">
          <v-btn small value="pending"> Pendentes </v-btn>
          <v-btn small value="finished"> Finalizadas </v-btn>
        </v-btn-toggle>
      </div>

      <v-text-field
        v-model="search"
        label="Pesquisar"
        class="my-2"
        outlined
        dense
        hide-details
      ></v-text-field>

      <v-data-table
        :expanded.sync="expanded"
        :headers="refundRequestsHeaders"
        :items="refundRequestsGrouped[refoundRequestFilterType]"
      >
        <template v-slot:item.ownerName="{ item }">
          {{
            item.Ticket?.Owner?.name || item?.Payment?.Ticket[0]?.Owner?.name
          }}
          <small class="d-block">
            {{
              item.Ticket?.Owner?.phone ||
              item?.Payment?.Ticket[0]?.Owner?.phone
            }}
          </small>
        </template>
        <template v-slot:item.createdAt="{ item }">
          {{ item?.createdAt | date("DD/MM/YYYY HH:mm") }}
        </template>
        <template v-slot:item.ticketCount="{ item }">
          {{ item.ticketCount }}
          ingresso{{ item.ticketCount == 1 ? "" : "s" }}
        </template>
        <template v-slot:item.amount="{ item }">
          {{
            item?.Ticket?.Payment.amount ||
            item?.Payment?.amount | currency(true)
          }}
        </template>
        <template v-slot:item.data-table-expand="{ item }">
          <v-icon small @click="openRefundDetails(item)"
            >mdi-information</v-icon
          >
        </template>
      </v-data-table>
    </v-card>

    <v-card outlined class="pa-4 pb-1 rounded-lg mt-2">
      <div class="d-flex align-center justify-space-between flex-wrap">
        <h6 class="mb-0">Ingressos Reembolsos</h6>
        <b>
          {{ refundedTickets.length }} reembolso{{
            refundedTickets.length == 1 ? "" : "s"
          }}
        </b>
      </div>
      <v-text-field
        v-model="searchRefundedTickets"
        label="Pesquisar"
        class="my-2"
        outlined
        dense
        hide-details
      ></v-text-field>
      <v-data-table :headers="ticketsHeaders" :items="filteredRefundedTickets">
        <template v-slot:item.Owner.name="{ item }">
          {{ item.Owner.name }}
          <small class="d-block">
            {{ item.Owner.phone }}
          </small>
        </template>
        <template v-slot:item.refundedAt="{ item }">
          {{ item?.Payment.refundedAt | date("DD/MM/YYYY HH:mm") }}
        </template>
        <template v-slot:item.ticketGroup="{ item }">
          {{ item?.TicketBlock?.TicketGroup.name }} /
          {{ item?.TicketBlock?.name }}
        </template>
        <template v-slot:item.refoundAmount="{ item }">
          {{ item?.Payment.refundedAmount | currency(true) }}
          ({{
            (
              (item?.Payment.amount
                ? item?.Payment.refundedAmount / item?.Payment.amount
                : 1) * 100
            ).toFixed(0)
          }}%)
        </template>
      </v-data-table>
    </v-card>
    <ticket-refund-details
      :item="selectedRefundItem"
      @close="showRefundDetailsDialog = false"
      @refresh="getRefunds"
    />
  </div>
</template>

<script>
import PARTY from "@/services/admin/party";
import TicketRefundDetails from "./TicketRefundRow.vue";

export default {
  components: { TicketRefundDetails },
  data: () => ({
    loading: true,
    expanded: [],
    search: "",
    searchRefundedTickets: "",
    refoundRequestFilterType: "pending", // pending | finished
    refundRequests: [],
    refundedTickets: [],
    selectedRefundItem: null,
    showRefundDetailsDialog: false,
    ticketsHeaders: [
      { text: "Nome", value: "Owner.name" },
      { text: "Reembolsado em", value: "refundedAt" },
      { text: "Setor / Lote", value: "TicketBlock.TicketGroup.name" },
      { text: "Valor reembolsado", value: "refoundAmount" },
      // { text: "Opções", value: "option", sortable: false },
    ],
  }),
  methods: {
    async getRefunds() {
      try {
        this.loading = true;
        const { refundRequests } = await PARTY.refund.getAllRequests(
          this.party.organizationId,
          this.party.id
        );
        this.refundRequests = refundRequests;

        const { tickets } = await PARTY.refund.getAll(
          this.party.organizationId,
          this.party.id
        );

        this.refundedTickets = tickets;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    openRefundDetails(item) {
      this.selectedRefundItem = item;
      this.showRefundDetailsDialog = true;
      this.$root.$emit("refund-details", item);
    },
  },
  mounted() {
    this.getRefunds();
    this.$root.$on("refund-ticket:success", this.getRefunds);
  },
  destroyed() {
    this.$root.$off("refund-ticket:success", this.getRefunds);
  },
  computed: {
    refundRequestsGrouped() {
      return this.refundRequests
        .filter((refundRequest) => {
          if (!this.search) return true;
          const search = this.search.toLowerCase().trim();

          // verify if search is a number
          const phone = refundRequest.Ticket.Owner.phone;
          if (!isNaN(search)) return phone.replace(/\D/g, "").includes(search);

          const name = refundRequest.Ticket.Owner.name.toLowerCase();
          const ticketGroup =
            refundRequest.Ticket.TicketBlock.TicketGroup.name.toLowerCase();
          const block = refundRequest.Ticket.TicketBlock.name.toLowerCase();
          return (
            name.includes(search) ||
            ticketGroup.includes(search) ||
            block.includes(search)
          );
        })
        .reduce(
          (acc, curr) => {
            var data = {
              ...curr,
              ticketCount:
                curr?.Payment?.Ticket?.length ||
                curr?.Ticket?.Payment?.Ticket?.length ||
                1,
              amount:
                curr?.Payment?.amount || curr?.Ticket?.Payment.amount || 0,
            };
            if (curr.closedAt) acc.finished.push(data);
            else acc.pending.push(data);

            return acc;
          },
          {
            finished: [],
            pending: [],
          }
        );
    },
    refundRequestsHeaders() {
      const data = [
        { text: "Nome", value: "ownerName" },
        { text: "Solicitado em", value: "createdAt" },
        { text: "Ingressos", value: "ticketCount" },
        { text: "Valor", value: "amount" },
        { text: "", value: "data-table-expand" },
        // { text: "Opções", value: "option", sortable: false },
      ];
      return data;
    },
    filteredRefundedTickets() {
      return this.refundedTickets.filter((ticket) => {
        if (!this.searchRefundedTickets) return true;
        const search = this.searchRefundedTickets.toLowerCase().trim();
        const phone = ticket.Owner.phone;
        if (!isNaN(search)) return phone.replace(/\D/g, "").includes(search);
        const name = ticket.Owner.name.toLowerCase();
        const ticketGroup = ticket.TicketBlock.TicketGroup.name.toLowerCase();
        const block = ticket.TicketBlock.name.toLowerCase();
        return (
          name.includes(search) ||
          ticketGroup.includes(search) ||
          block.includes(search)
        );
      });
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>

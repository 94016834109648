<template>
  <div>
    <span>Selecionar Icone</span>
    <div>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-avatar color="secondary" v-bind="attrs" v-on="on">
            <v-icon color="white">
              {{ selectedIcon || "mdi-help-box-multiple" }}
            </v-icon>
          </v-avatar>
        </template>
        <v-card style="max-width: 300px">
          <vue-perfect-scrollbar
            class="d-flex flex-column gap-4 pa-5"
            :settings="{ suppressScrollX: true, wheelPropagation: false }"
            style="max-height: 400px"
          >
            <div v-for="(category, index) in icons" :key="index">
              <span class="font-weight-bold text-15">{{ category.title }}</span>
              <v-row dense class="mx-0">
                <v-col cols="2" v-for="(icon, i) in category.icons" :key="i">
                  <v-btn
                    @click="selectedIcon = icon"
                    class="d-flex flex-column"
                    icon
                  >
                    <v-icon
                      :style="icon === selectedIcon ? 'opacity:1' : 'opacity: 0.5'"
                      :color="icon === selectedIcon ? 'primary' : ''"
                    >
                      {{ icon }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </vue-perfect-scrollbar>
        </v-card>
      </v-menu>
    </div>
  </div>
</template>

<script>
import categoryIcons from "@/utils/categoryIcons";

export default {
  data: () => ({
    icons: categoryIcons,
  }),
  props: {
    value: String,
  },
  computed: {
    selectedIcon: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style></style>

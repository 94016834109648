<template>
  <div class="mt-2">
    <v-alert v-if="error" type="error">
      <v-row align="center">
        <v-col class="grow py-0">
          {{ error }}
        </v-col>
        <v-col class="shrink py-0">
          <v-btn small @click="getOrders" :disabled="refreshDisabled">
            Tentar novamente
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <template v-if="loading && !orders.length">
      <v-progress-linear
        color="info"
        indeterminate
        class="rounded rounded-b-0"
      />
      <v-alert text type="info" class="mb-0 rounded-t-0">
        Carregando compras...
      </v-alert>
    </template>
    <v-alert
      text
      v-else-if="!orders.length && !error && !loading"
      type="info"
      class="mb-0"
    >
      Nenhuma compra encontrada
    </v-alert>
    <div class="px-0" v-else-if="!error">
      <div class="d-flex justify-space-between align-center">
        <h6 class="mb-0">Compras</h6>
      </div>
      <div class="d-flex pa-2">
        <v-text-field
          outlined
          dense
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar pelo usuário, #pagamento ou #pedido"
          single-line
          hide-details
        />
        <v-btn
          icon
          @click="getOrders"
          :loading="loading"
          :disabled="refreshDisabled"
          class="ml-2"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </div>

      <v-data-table
        :headers="headers"
        :items="filteredOrders"
        :loading="loading"
        :items-per-page="25"
        :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100, -1] }"
        sort-by="createdAt"
        :sort-desc="true"
        dense
      >
        <template v-slot:item.id="{ item }">
          <span class="text-overline lh-1">#{{ item.id | shortId }}</span>
        </template>
        <template v-slot:item.createdAt="{ item }">
          {{ item.createdAt | date("DD/MM/YY HH:mm") }}
        </template>
        <template v-slot:item.amount="{ item }">
          {{ item.amount | currency(true) }}
        </template>
        <template v-slot:item.name="{ item }">
          <v-avatar v-if="!item.Owner?.name" :size="24" class="mr-2">
            <v-icon>mdi-account-question</v-icon>
          </v-avatar>
          <base-avatar
            v-else
            :src="item.Owner?.photo"
            :seed="item.Owner?.id"
            :size="24"
            class="mr-2"
          />
          {{ item.Owner?.name || "Não cadastrado" }}
        </template>

        <template v-slot:item.email="{ item }">
          <a :href="'mailto:' + item.email">{{ item.email }}</a>
        </template>
        <template v-slot:item.items="{ item }">
          <span v-if="item.Items.length === 1">1 produto</span>
          <span v-else>{{ item.Items.length }} produtos</span>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            label
            small
            :color="item.status.color"
            class="font-weight-medium"
          >
            {{ item.status.text }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            icon
            @click="viewDetails(item)"
            :disabled="loading"
            class="mr-2"
          >
            <v-icon small>mdi-eye</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import PAYMENT from "@/utils/payment";
import PRODUCT from "@/services/admin/products";
import { mapGetters } from "vuex";

export default {
  components: {},
  data: () => ({
    loading: false,
    error: false,
    refreshDisabled: false,
    search: "",
    orders: [],
    timeout: null,
    headers: [
      {
        text: "#Pedido",
        align: "start",
        sortable: true,
        value: "id",
      },
      {
        text: "Nome",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        text: "Data",
        align: "start",
        sortable: true,
        value: "createdAt",
      },
      {
        text: "Items",
        value: "items",
        align: "center",
      },
      {
        text: "Status",
        value: "status",
        align: "center",
        sortable: false,
        cellClass: "px-1",
      },
      // {
      //   text: "Ações",
      //   value: "actions",
      //   sortable: false,
      //   align: "center",
      //   cellClass: "px-1",
      // },
    ],
    paymentStatus: PAYMENT.ticketStatus,
    paymentGroups: PAYMENT.status_db_groups,
  }),
  methods: {
    async getOrders() {
      this.error = false;
      this.loading = true;
      this.refreshDisabled = true;
      try {
        const data = await PRODUCT.reports.orders(this.selectedOrganization.id);
        this.orders = data.orders.map((order) => ({
          ...order,
          ...this.calculateStatus(order),
        }));

        this.timeout = setTimeout(() => {
          this.getOrders();
        }, 2 * 60 * 1000);

        setTimeout(() => {
          this.refreshDisabled = false;
        }, 10000);
      } catch (error) {
        setTimeout(() => {
          this.refreshDisabled = false;
        }, 5000);
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
    filterName(search, { Owner: member }) {
      if (!member.name) return false;
      const name = member.name.toLowerCase();
      const names = search.trim().toLowerCase().split(" ");
      return names.every((n) => name.includes(n));
    },
    filterPhone(search, member) {
      if (!member.phone) return false;
      let p = member.phone.replace(/\D/g, "");
      let s = search.replace(/\D/g, "");
      if (!p || !s) return false;
      return p.includes(s);
    },
    filterList(value, search, item) {
      return this.filterName(search, item) || this.filterPhone(search, item);
    },
    calculateStatus(order) {
      const [payment] = order.Payment;
      if (this.paymentGroups.approved.includes(payment.status))
        return { status: this.paymentStatus["succeeded"] };

      if (payment.status === "refunded")
        return { status: this.paymentStatus["refunded"] };

      if (this.paymentGroups.pending.includes(payment.status))
        return { status: { color: "orange", text: "Aguardando" } };

      return { status: { color: "secondary", text: "Não pago" } };
    },
    viewDetails(order) {
      this.$root.$emit("product-order-details", order);
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    filteredOrders() {
      if (this.search === "") return this.orders;
      if (this.search.startsWith("#")) {
        var id = this.search.slice(1).trim().toLowerCase();

        return this.orders.filter(
          (o) =>
            o.id.startsWith(id) || o.Payment.some((p) => p.id.startsWith(id))
        );
      } else {
        return this.orders.filter((g) => this.filterList(null, this.search, g));
      }
    },
  },

  mounted() {
    this.getOrders();
  },
  beforeDestroy() {
    clearInterval(this.timeout);
  },
  props: {
    products: {
      type: Array,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style></style>

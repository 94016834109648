<template>
  <div class="pa-6">
    <div class="d-flex gap-3 align-center">
      <!-- <div>
        <v-img
          :src="party.cover"
          class="rounded-lg"
          :aspect-ratio="16 / 9"
          width="150"
          style="cursor: pointer"
        ></v-img>
      </div> -->
      <div class="flex-grow-1">
        <h5>{{ party.name }}</h5>
        <h6>{{ party.date | date("D [de] MMMM [de] YYYY") }}</h6>
      </div>
      <div class="text-right">
        <v-chip label>
          <h6 class="mb-0">{{ selectedReport?.title }}</h6></v-chip
        >

        <small class="d-block mb-0">gerado em</small>
        <small class="mb-0">{{ new Date().toLocaleString() }}</small>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    party: {
      type: Object,
      required: true,
    },
    selectedReport: {
      type: Object,
    },
  },
};
</script>

<style></style>

<template>
  <v-dialog
    v-model="isOpen"
    :width="this.step === 2 ? 1000 : 700"
    scrollable
    :fullscreen="$vuetify.breakpoint.smAndDown"
    content-class="rounded-lg"
  >
    <v-card class="h-full d-flex flex-column" style="max-height: 90dvh">
      <v-card-title class="pb-0 pt-3 px-6">
        <div class="d-flex w-full justify-space-between">
          <span class="text-16"> Criar lotes em massa </span>
        </div>
      </v-card-title>
      <v-card-text class="pa-0 ma-0">
        <v-stepper
          v-model="step"
          class="elevation-0"
          :non-linear="!!options?.id"
        >
          <v-stepper-header class="elevation-0 mb-2" style="height: 50px">
            <template v-for="(stp, i) in steps">
              <v-divider v-if="i" :key="`divider-` + i" />
              <v-stepper-step
                :key="`step-` + i"
                :complete="step > i + 1"
                :step="i + 1"
                :editable="!!options?.id && !(!validInfo || !validMembership)"
                class="py-0"
              >
                {{ stp }}
              </v-stepper-step>
            </template>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1" class="pt-0">
              <ticket-block-multiple-info
                v-model="options"
                @valid="validInfo = $event"
                @options="onTicketOptionsUpdate"
                :party="party"
                :ticketGroups="ticketGroups"
              />
            </v-stepper-content>
            <v-stepper-content v-if="steps.length == 3" step="2" class="pt-0">
              <ticket-block-multiple-matriz
                :options="this.options"
                @options="onTicketOptionsUpdate"
                @valid="validMatriz = $event"
              />
            </v-stepper-content>
            <v-stepper-content :step="steps.length" class="pt-0">
              <ticket-block-sellers
                v-model="sellers"
                :members="members"
                :ticket-block="options"
                :posSessions="posSessions"
              />
              <v-progress-linear
                v-if="loading"
                :value="Math.floor(((currentStep + 1) / totalSteps) * 100)"
                height="20"
                color="primary"
                class="mb-3 rounded-lg my-5"
              >
                Criando {{ currentStep }} de {{ totalSteps }} lotes
              </v-progress-linear>
              <v-alert v-if="!!error" outlined text color="error" class="mt-2">
                {{ error }}
              </v-alert>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>

      <v-card-actions class="d-flex align-end gap-1">
        <template v-if="step === 1">
          <v-spacer />
          <v-btn text :disabled="loading" @click="close"> Cancelar </v-btn>
          <v-btn
            color="primary"
            :disabled="!validInfo || loading"
            @click="step++"
          >
            Continuar
          </v-btn>
        </template>
        <template v-if="steps[step - 1] == 'Vincular Setores'">
          <v-spacer />
          <v-btn class="ml-1" text :disabled="loading" @click="step--">
            Voltar
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!validMatriz || loading"
            @click="step++"
            class="ml-2"
          >
            Continuar
          </v-btn>
        </template>
        <template v-if="step === steps.length">
          <p class="align-self-center mb-0">
            {{ sellers.length }} vendedor{{
              sellers.length != 1 ? "es" : ""
            }}
            selecionado{{ sellers.length != 1 ? "s" : "" }}
          </p>
          <v-spacer />
          <v-btn class="ml-1" text @click="step--" :disabled="loading">
            Voltar
          </v-btn>
          <v-btn
            color="primary"
            :disabled="loading || !validInfo"
            :loading="loading"
            @click="save"
          >
            Criar
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import TICKET from "@/services/admin/ticket";
import ORGANIZATION from "@/services/admin/organization";
import TicketBlockMultipleInfo from "./modules/TicketBlockMultipleInfo.vue";
import TicketBlockMultipleMatriz from "./modules/TicketBlockMultipleMatriz.vue";
import TicketBlockSellers from "./modules/TicketBlockSellers.vue";

const defaultOptions = () => ({
  categories: [""],
  onlineSale: false,
  allowTransfer: false,
  active: true,
  ticketBlocks: [
    {
      prices: {},
      quantities: {},
      name: "",
      startDate: "",
      endDate: "",
    },
  ],
});

export default {
  components: {
    TicketBlockMultipleInfo,
    TicketBlockSellers,
    TicketBlockMultipleMatriz,
  },
  data: () => ({
    options: defaultOptions(),
    isOpen: false,
    loading: false,
    validInfo: false,
    validMatriz: false,
    ticketGroup: null,
    error: false,
    step: 1,
    members: [],
    sellers: [],
    posSellers: [],
    totalSteps: 0,
    currentStep: 0,
  }),

  methods: {
    onTicketOptionsUpdate(data) {
      this.options = data;
    },
    open(data = {}) {
      this.isOpen = true;
      this.options = Object.assign(defaultOptions());
      this.ticketGroup = data.ticketGroup;
    },
    close() {
      this.isOpen = false;
      this.loading = false;
      this.error = false;
      this.step = 1;
      this.sellers = [];
      this.options = defaultOptions();
      // this.$root.$emit("reset");
    },
    async save() {
      try {
        this.loading = true;
        this.error = false;
        let orgId = this.selectedOrganization.id;
        let { partyId } = this.$route.params;

        const sellersGrouped = this.sellers.reduce(
          (acc, s) => {
            if (!acc[s.type]) acc[s.type] = [];
            acc[s.type].push(s.id);
            return acc;
          },
          { user: [], pos: [] }
        );

        this.totalSteps =
          this.options.ticketBlocks.length * this.options.categories.length;
        this.currentStep = 0;

        for (const ticketBlock of this.options.ticketBlocks) {
          for (const category of this.options.categories) {
            const name = `${ticketBlock.name} • ${category}`;
            const price = ticketBlock.prices[category];
            const quantity = ticketBlock.quantities[category];

            if (!price || !quantity) continue;

            await TICKET.createTicketBlock(orgId, partyId, {
              ticketGroup: this.ticketGroup,
              name: name,
              price: price,
              quantity: parseInt(quantity),
              startDate: ticketBlock.startDate,
              endDate: ticketBlock.endDate,
              allowTransfer: this.options.allowTransfer,
              active: ticketBlock.active,
              onlineSale: this.options.onlineSale,
              sellers: sellersGrouped.user,
              posSellers: sellersGrouped.pos,
              ticketBlockId: this.ticketGroup,
            });

            this.currentStep++;
          }
        }

        this.$emit("success");
        this.close();
      } catch (e) {
        this.error = e.message;
        this.loading = false;
      }
    },
    async getMembers() {
      try {
        this.loading = true;
        var response = await ORGANIZATION.members(this.selectedOrganization.id);
        this.members = response.users.filter((u) => {
          return (
            u.owner ||
            (32 & u.permission) === 32 ||
            (1024 & u.permission) === 1024
          );
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {},

  computed: {
    ...mapGetters("auth", ["config"]),
    ...mapGetters("organization", ["memberships", "selectedOrganization"]),
    steps() {
      const data = ["Informações"];
      data.push("Vincular Setores");
      data.push("Vendedores");
      return data;
    },
  },
  mounted() {
    this.$root.$on("ticket-multi-blocks-modal", this.open);
    this.getMembers();
  },
  props: {
    party: {
      type: Object,
      default: () => ({}),
    },
    ticketGroups: {
      type: Array,
      default: () => [],
    },
    posSessions: {
      type: Array,
      default: () => [],
    },
  },
  created() {},
};
</script>

<style></style>

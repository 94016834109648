<template>
  <div class="d-flex flex-column gap-5">
    <div class="d-flex justify-space-between align-center mt-2">
      <h6 class="mb-0">Cortesias</h6>
      <v-btn
        @click="emitCourtesy"
        color="primary"
        :small="$vuetify.breakpoint.mdAndDown"
      >
        <v-icon :left="$vuetify.breakpoint.smAndUp">mdi-plus</v-icon>
        <template v-if="$vuetify.breakpoint.smAndUp">Cortesia</template>
      </v-btn>
    </div>

    <courtesy-emitter-list :party="party" />

    <courtesy-promotional-list :party="party" />

    <courtesy-list :party="party" />

    <courtesy-emitter-modal :party="party" @refresh="refresh" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import CourtesyEmitterList from "./courtesy/CourtesyEmitterList.vue";
import CourtesyList from "./courtesy/CourtesyList.vue";
import CourtesyEmitterModal from "./courtesy/CourtesyEmitterModal.vue";
import CourtesyPromotionalList from "./courtesy/CourtesyPromotionalList.vue";
export default {
  components: {
    CourtesyEmitterModal,
    CourtesyList,
    CourtesyEmitterList,
    CourtesyPromotionalList,
  },
  methods: {
    emitCourtesy() {
      this.$emit("openEmitterModal");
    },

    refresh() {
      this.$root.$emit("refresh-courtesy");
    },
  },

  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <div class="d-flex align-center gap-2 align-center mb-2">
      <v-text-field
        v-model="search"
        label="Buscar POS"
        outlined
        dense
        hide-details
        clearable
        append-icon="mdi-magnify"
        placeholder="Busque por nome ou serial number"
      />
    </div>
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
      class="mt-2"
    />
    <v-alert v-else-if="!loading && error" class="mb-0" type="error">
      {{ error }}
    </v-alert>
    <v-expansion-panels v-else v-model="expanded" class="mt-4">
      <v-expansion-panel v-for="(pos, i) in filteredPos" :key="i">
        <v-expansion-panel-header>
          <div class="d-flex gap-2 align-center">
            <div class="flex-grow-1">
              <h6 class="mb-1">
                <v-avatar v-if="!pos.active" color="error" size="16">
                  <v-icon x-small color="white">mdi-connection</v-icon>
                </v-avatar>
                {{ pos.name || pos.Address?.name || "Sem nome" }}
              </h6>
              <small>
                {{ pos.Device.serialNumber }}
              </small>
            </div>
            <b
              :class="
                pos.available == 0
                  ? 'text-secondary'
                  : pos.available < 0
                  ? 'success--text'
                  : 'error--text'
              "
            >
              {{ pos.available | currency(true) }}
            </b>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div>
            <v-card outlined>
              <v-card-text class="d-flex flex-wrap justify-space-around px-">
                <div class="text-center px-4">
                  <h6 class="mt-2 mb-0">
                    {{ pos.cashAmount | currency }}
                  </h6>
                  <span class="text-caption">Valor em dinheiro</span>
                </div>
                <div class="text-center px-4">
                  <h6 class="mt-2 mb-0">
                    {{ pos.cashoutAmount | currency }}
                  </h6>
                  <span class="text-caption">
                    {{ pos.Cashout.length }} Sangria{{
                      pos.Cashout.length > 1 ? "s" : ""
                    }}
                  </span>
                </div>
                <div class="text-center px-4">
                  <h6 class="mt-2 mb-0">
                    {{ pos.available | currency }}
                  </h6>
                  <span class="text-caption"> Disponível para sangria </span>
                </div>
              </v-card-text>
            </v-card>

            <v-data-table
              :headers="headers"
              :items="pos.Cashout"
              :items-per-page="5"
            >
              <template v-slot:item.id="{ item }">
                <span class="text-overline lh-1">#{{ item.id | shortId }}</span>
              </template>
              <template v-slot:item.createdAt="{ item }">
                {{ item.createdAt | date("DD/MM/YYYY HH:mm") }}
              </template>
              <template v-slot:item.amount="{ item }">
                {{ item.amount | currency }}
              </template>
              <template v-slot:item.User.name="{ item }">
                <div class="d-flex align-center gap-1">
                  <base-avatar
                    :src="item.User.photo"
                    :seed="item.User.id"
                    :size="18"
                  />
                  {{ item.User.name }}
                </div>
              </template>
            </v-data-table>

            <v-btn
              v-if="hasPermission(1073741824)"
              @click="cashoutPos(pos)"
              color="primary"
              block
            >
              Sangrar PDV
            </v-btn>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <pre>{{ posSessions }}</pre>

    <cashout-pos @success="getPos" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import POS from "@/services/admin/pos";

import CashoutPos from "./modal/CashoutPos.vue";
import AdoptPos from "./modal/AdoptPos.vue";
import EditAdoptPos from "./modal/EditAdoptPos.vue";
import PosReport from "./modal/PosReport.vue";

export default {
  components: { AdoptPos, EditAdoptPos, CashoutPos, PosReport },
  data: () => ({
    sessions: [],
    expanded: null,
    loading: true,
    error: false,
    search: "",
    headers: [
      { text: "#", value: "id" },
      { text: "Data", value: "createdAt" },
      { text: "Valor", value: "amount" },
      { text: "Responsável", value: "User.name" },
    ],
  }),

  methods: {
    async getPos() {
      this.loading = true;
      try {
        const orgId = this.selectedOrganization.id;
        const { posSessions } = await POS.cashoutReport(orgId, {
          partyId: this.partyId || undefined,
        });
        this.sessions = posSessions;
      } catch (error) {
        this.error = error.message || "Erro ao buscar os POS";
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    addPos() {
      this.$emit("adopt-pos-modal");
    },
    posReport(pos) {
      this.$emit("pos-report-modal", pos);
    },
    editPos(pos) {
      this.$emit("edit-adopt-pos-modal", pos);
    },
    cashoutPos(pos) {
      this.$emit("cashout-pos-modal", pos);
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    ...mapGetters("auth", ["hasPermission"]),
    reducedSessions() {
      return this.sessions
        .map((session) => {
          const totalCashOut = session.Cashout.reduce(
            (acc, cashout) => acc + cashout.amount,
            0
          );
          return {
            ...session,
            cashoutAmount: totalCashOut,
            available: session.cashAmount - totalCashOut,
          };
        })
        .sort((a, b) => b.available - a.available);
    },
    filteredPos() {
      if (!this.search) return this.reducedSessions;

      const search = this.search.toLowerCase();
      const filtered = this.reducedSessions.filter((session) => {
        const name = session.name || session.Address?.name || "";
        if (name.toLowerCase().includes(search)) return true;
        if (session.Device.serialNumber.toLowerCase().includes(search))
          return true;

        return false;
      });

      if (filtered.length === 1) this.expanded = 0;

      return filtered;
    },
  },
  watch: {
    selectedOrganization() {
      this.sessions = [];
      this.getPos();
    },
    search() {
      this.expanded = null;
    },
  },
  mounted() {
    this.getPos();
    if (this.$route.query.code) {
      this.$emit("adopt-pos-modal", this.$route.query.code);
      this.$router.replace({ query: {} });
    }
  },
  props: {
    partyId: {
      type: String,
      default: null,
    },
  },
};
</script>

<style >
.text-truncate-2-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}
</style>
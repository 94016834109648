<template>
  <div class="d-flex flex-column gap-3">
    <v-data-table
      :headers="headers"
      :items="data?.payments || []"
      :loading="loading"
      :items-per-page="5"
      sort-by="createdAt"
      sort-desc
    >
      <template v-slot:item.id="{ item }"> #{{ item.id | shortId }} </template>
      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | date("DD/MM/YY HH:mm") }}
      </template>
      <template v-slot:item.amount="{ item }">
        <b>{{ item.amount | currency(true) }}</b>
        <p class="mb-0 text-12">
          {{ item.Ticket.length }} ingresso{{
            item.Ticket.length > 1 ? "s" : ""
          }}
        </p>
      </template>
      <template v-slot:item.paymentType="{ item }">
        {{ PAYMENT.paymentType[item.paymentType]?.text }}
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip
          :color="PAYMENT.ticketStatus[item.status]?.color"
          text-color="white"
          small
          label
        >
          {{ PAYMENT.ticketStatus[item.status]?.text }}
        </v-chip>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn icon small @click="openModal(item)">
          <v-icon small>mdi-eye</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <pos-payment-details />
  </div>
</template>

<script>
import PAYMENT from "@/utils/payment";
import PosPaymentDetails from "./PosPaymentDetails.vue";

export default {
  components: { PosPaymentDetails },
  props: {
    session: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    PAYMENT,
    headers: [
      { text: "#", value: "id", class: "px-2", cellClass: "px-2" },
      { text: "Data", value: "createdAt" },
      { text: "Valor", value: "amount" },
      { text: "Tipo", value: "paymentType" },
      { text: "Status", value: "status" },
      {
        text: "",
        value: "action",
        class: "px-0",
        cellClass: "px-0",
        sortable: false,
      },
    ],
  }),
  methods: {
    openModal(item) {
      this.$root.$emit("payment-pos-detail", item);
    },
  },
};
</script>

<style>
</style>
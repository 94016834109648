<template>
  <v-select
    dense
    hide-details
    item-value="id"
    :items="items"
    :menu="true"
    multiple
    v-model="ticketBlock"
    placeholder="Filtrar"
    :label="label"
    class="smallInput"
    :outlined="outlined"
  >
    <template v-slot:selection="{ index }">
      <div v-if="index === 0">
        <v-chip
          color="primary"
          x-small
          v-for="selectedItem in selectedLabels"
          :key="selectedItem"
          >{{ selectedItem }}</v-chip
        >
      </div>
    </template>
    <template v-slot:item="{ item, attrs }">
      <v-list-item
        @click.stop="toogleTicketGroup(item)"
        v-if="item.type == 'TicketGroup'"
        :class="
          ticketGroupSelectionStatus[item.tgId][0] === 'all'
            ? 'v-list-item--active primary--text'
            : ''
        "
        class="d-flex"
        style="margin: 0 -16px"
      >
        <v-simple-checkbox
          @click.stop="toogleTicketGroup(item)"
          :value="ticketGroupSelectionStatus[item.tgId][0] === 'all'"
          :indeterminate="ticketGroupSelectionStatus[item.tgId][0] === 'some'"
        />
        <span class="text-14 font-weight-bold"> {{ item.title }} </span>
      </v-list-item>
      <div
        v-bind="attrs"
        @click.stop="toggleTicketBlock(item.id)"
        v-if="item.type == 'TicketBlock'"
        class="pl-5 d-flex align-center"
      >
        <v-simple-checkbox
          @click.stop="toggleTicketBlock(item.id)"
          :value="ticketBlock.includes(item.id)"
        />
        <span> {{ item.title }} </span>
      </div>
    </template>
  </v-select>
</template>

<script>
export default {
  props: {
    ticketGroups: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: "Filtrar por lote",
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    startSelectAll: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    ticketBlock: [],
  }),
  methods: {
    toggleTicketBlock(ticketBlockId) {
      if (this.ticketBlock.includes(ticketBlockId)) {
        this.ticketBlock = this.ticketBlock.filter(
          (tb) => tb !== ticketBlockId
        );
      } else {
        this.ticketBlock.push(ticketBlockId);
      }
    },
    toogleTicketGroup(ticketGroup) {
      if (this.ticketGroupSelectionStatus[ticketGroup.tgId][0] === "all") {
        this.ticketBlock = this.ticketBlock.filter(
          (tb) => !ticketGroup.ticketBlocks.includes(tb)
        );
      } else {
        var newSelection = new Set([
          ...this.ticketBlock,
          ...ticketGroup.ticketBlocks,
        ]);
        this.ticketBlock = Array.from(newSelection);
      }
    },
  },
  mounted() {
    if (this.startSelectAll) {
      this.ticketBlock = this.ticketGroups.reduce(
        (acc, tg) => [...acc, ...tg.TicketBlock.map((tb) => tb.id)],
        []
      );
    }
  },
  computed: {
    selectedLabels() {
      const ticketGroups = Object.entries(this.ticketGroupSelectionStatus);
      if (ticketGroups.length === 0) return [];
      if (ticketGroups.every(([_, status]) => status[0] === "all"))
        return ["Todos"];
      return ticketGroups.reduce((acc, [tgId, status]) => {
        const ticketGroup = this.ticketGroups.find((tg) => tg.id === tgId);
        if (status[0] === "none") return acc;
        acc.push(
          `${ticketGroup.name}: ${status[0] === "all" ? "Todos" : status[1]}`
        );
        return acc;
      }, []);
    },
    ticketGroupSelectionStatus() {
      return this.items.reduce((acc, tg) => {
        if (tg.type == "TicketBlock") return acc;
        const selected = this.ticketBlock.filter((tbId) =>
          tg.ticketBlocks.includes(tbId)
        );
        acc[tg.tgId] =
          selected.length === tg.ticketBlocks.length
            ? ["all", selected.length]
            : selected.length === 0
            ? ["none", selected.length]
            : ["some", selected.length];
        return acc;
      }, {});
    },
    items() {
      return this.ticketGroups.reduce((acc, tg) => {
        acc.push({
          title: tg.name,
          type: "TicketGroup",
          tgId: tg.id,
          ticketBlocks: tg.TicketBlock.map(({ id }) => id),
        });

        tg.TicketBlock.forEach((t) => {
          acc.push({
            title: t.name,
            type: "TicketBlock",
            id: t.id,
          });
        });
        return acc;
      }, []);
    },
  },
  watch: {
    ticketBlock: {
      handler() {
        this.$emit("input", this.ticketBlock);
      },
      immediate: true,
    },
    value: {
      handler(val, oldVal) {
        if (val === oldVal) return;
        this.ticketBlock = val;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scooped>
.smallInput {
  .v-input__control {
    .v-input__slot {
      .v-select__slot {
        .v-select__selections {
          input {
            width: 10px;
          }
        }
      }
    }
  }
}

.mediumInput {
  .v-input__control {
    .v-input__slot {
      .v-select__slot {
        .v-select__selections {
          input {
            width: 70px;
          }
        }
      }
    }
  }
}
</style>

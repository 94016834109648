<template>
  <div class="d-flex gap-3">
    <v-chip v-if="filterOption.dates" label>{{ formattedDateRange }}</v-chip>
    <v-chip v-if="!filterOption.courtesy" label>Não inclui cortesias</v-chip>
    <v-chip v-if="filterOption.ticketBlocks" label>
      {{ filterOption.ticketBlocks.length }} lote{{
        filterOption.ticketBlocks.length > 1 ? "s" : ""
      }}
    </v-chip>
    <v-chip v-if="posSellers" label
      >{{ posSellers }} PDV{{ posSellers > 1 ? "s" : "" }}</v-chip
    >
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    filterOption: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedDateRange() {
      if (!this.filterOption.dates) return "Todas as datas";
      const startDate = moment(this.filterOption.dates[0]).format(
        "D [de] MMMM"
      );
      const endDate = moment(this.filterOption.dates[1]).format("D [de] MMMM");
      if (startDate === endDate) return startDate;
      return `${startDate} até ${endDate}`;
    },
    posSellers() {
      if (!this.filterOption.sellers) return false;
      return this.filterOption.sellers.filter(({ type }) => type === "pos")
        .length;
    },
  },
};
</script>

<style></style>

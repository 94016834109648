<template>
  <div class="py-2">
    <p class="mb-0 font-weight-bold">Transferencias</p>
    <v-timeline align-top dense>
      <v-timeline-item large>
        <template v-slot:icon>
          <v-tooltip slot="icon" v-if="!i" top>
            <template v-slot:activator="{ on }">
              <v-icon size="22" color="white" v-on="on">
                mdi-account-cash
              </v-icon>
            </template>
            <span>Comprador original</span>
          </v-tooltip>
        </template>
        <div class="d-flex gap-3 align-center" style="flex: 3">
          <baseAvatar
            :size="30"
            :src="sortedTicketTransfer[0].from.photo"
            :seed="sortedTicketTransfer[0].from.id"
          />
          <div>
            <span class="font-weight-bold">
              {{ sortedTicketTransfer[0].from.name || "Conta não criada" }}
            </span>
            <small class="d-block">
              {{ sortedTicketTransfer[0].from.phone }}
            </small>
          </div>
        </div>
        <v-divider class="mt-4" />
      </v-timeline-item>
      <v-timeline-item
        v-for="(transfer, i) in sortedTicketTransfer"
        :key="transfer.id"
        class="pb-0"
        :color="i + 1 == sortedTicketTransfer.length ? 'success' : 'primary'"
      >
        <template v-slot:icon>
          <span class="white--text font-weight-bold">{{ i + 1 }}</span>
        </template>
        <div class="d-flex gap-3" style="flex: 3">
          <baseAvatar
            class="mt-1"
            :size="30"
            :src="transfer.to.photo"
            :seed="transfer.to.id"
          />
          <div class="flex-grow-1">
            <span class="font-weight-bold">
              {{ transfer.to.name || "Conta não criada" }}
            </span>
            <small class="d-block lh-1">
              {{ transfer.to.phone }}
            </small>
          </div>
          <small class="text-overline lh-1">
            {{ transfer.createdAt | date("DD/MM/YY HH:mm") }}
          </small>
        </div>
        <v-divider class="my-4" />
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script>
export default {
  computed: {
    sortedTicketTransfer() {
      return this.ticketTransfer.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
    },
  },
  props: {
    ticketTransfer: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style>
</style>
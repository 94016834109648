var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"600","scrollable":"","persistent":_vm.loading},on:{"click:outside":_vm.close},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.faq?.id ? `Editar FAQ` : "Criar FAQ")+" ")]),_c('v-card-text',{staticClass:"pt-1 pb-0"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"label":"Pergunta","outlined":"","required":"","counter":"500","rules":[
            (v) => !!v || 'Pergunta é obrigatória',
            (v) => v.length <= 200 || 'Máximo de 200 caracteres',
            (v) => v.length >= 10 || 'Escreva um pouco mais',
          ]},model:{value:(_vm.faq.question),callback:function ($$v) {_vm.$set(_vm.faq, "question", $$v)},expression:"faq.question"}}),_c('v-textarea',{attrs:{"label":"Resposta","outlined":"","counter":"","rules":[
            (v) => !!v || 'Resposta é obrigatória',
            (v) => v.length >= 10 || 'Escreva um pouco mais',
          ]},model:{value:(_vm.faq.answer),callback:function ($$v) {_vm.$set(_vm.faq, "answer", $$v)},expression:"faq.answer"}})],1)],1),_c('v-card-actions',{staticClass:"mb-2"},[_c('v-btn',{attrs:{"text":"","disabled":_vm.loading},on:{"click":_vm.close}},[_vm._v(" Fechar ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.valid,"loading":_vm.loading},on:{"click":_vm.save}},[_vm._v(" Salvar ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
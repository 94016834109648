<template>
  <div>
    <v-card elevation="0" outlined class="ma-3 mb-6">
      <template>
        <p class="mb-0 px-3 pt-2 font-weight-bold">Vendas por canal</p>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Modalidade</th>
                <th class="text-center">Quantidade</th>
                <th class="text-center">Taxas</th>
                <th class="text-center">Valor</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Vendas Online</td>
                <td class="text-center">{{ dataReduce.byMethod.online.quantity }}</td>
                <td class="text-center">{{ dataReduce.byMethod.online.fee | currency(true) }}</td>
                <td class="text-center">
                  {{ dataReduce.byMethod.online.amount | currency(true) }}
                </td>
              </tr>
              <tr>
                <td>Vendas PDV</td>
                <td class="text-center">{{ dataReduce.byMethod.pdv.quantity }}</td>
                <td class="text-center">{{ dataReduce.byMethod.pdv.fee | currency(true) }}</td>
                <td class="text-center">{{ dataReduce.byMethod.pdv.amount | currency(true) }}</td>
              </tr>
              <tr>
                <td>Cortesia</td>
                <td class="text-center">{{ dataReduce.byMethod.courtesy.quantity }}</td>
                <td class="text-center">{{ dataReduce.byMethod.courtesy.fee | currency(true) }}</td>
                <td class="text-center">
                  {{ dataReduce.byMethod.courtesy.amount | currency(true) }}
                </td>
              </tr>
              <tr class="font-weight-bold">
                <td>Total</td>
                <td class="text-center">{{ dataReduce.byMethod.total.quantity }}</td>
                <td class="text-center">{{ dataReduce.byMethod.total.fee | currency(true) }}</td>
                <td class="text-center">{{ dataReduce.byMethod.total.amount | currency(true) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>
    </v-card>
    <v-card elevation="0" outlined class="ma-3">
      <template>
        <p class="mb-0 px-3 pt-2 font-weight-bold">Ingressos por tipo de pagamento</p>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Modalidade</th>
                <th class="text-center">Quantidade</th>
                <th class="text-center">Taxas</th>
                <th class="text-center">Valor</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Vendas Site</td>
                <td class="text-center">{{ dataReduce.byType.site.quantity }}</td>
                <td class="text-center">{{ dataReduce.byType.site.fee | currency(true) }}</td>
                <td class="text-center">{{ dataReduce.byType.site.amount | currency(true) }}</td>
              </tr>
              <tr>
                <td>Vendas PDV Cartão / Pix</td>
                <td class="text-center">{{ dataReduce.byType.pdvDigital.quantity }}</td>
                <td class="text-center">{{ dataReduce.byType.pdvDigital.fee | currency(true) }}</td>
                <td class="text-center">
                  {{ dataReduce.byType.pdvDigital.amount | currency(true) }}
                </td>
              </tr>
              <tr>
                <td>Vendas PDV Dinheiro</td>
                <td class="text-center">{{ dataReduce.byType.pdvMoney.quantity }}</td>
                <td class="text-center">{{ dataReduce.byType.pdvMoney.fee | currency(true) }}</td>
                <td class="text-center">
                  {{ dataReduce.byType.pdvMoney.amount | currency(true) }}
                </td>
              </tr>
              <tr class="font-weight-bold">
                <td>Total</td>
                <td class="text-center">{{ dataReduce.byType.total.quantity }}</td>
                <td class="text-center">{{ dataReduce.byType.total.fee | currency(true) }}</td>
                <td class="text-center">{{ dataReduce.byType.total.amount | currency(true) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    reportData: {
      type: Array,
      required: true,
    },
  },
  data: () => ({}),
  methods: {},
  computed: {
    dataReduce() {
      return this.reportData.reduce(
        (acc, item) => {
          if (item.status != "succeeded") return acc;
          const method =
            item.paymentType == "COURTESY"
              ? "courtesy"
              : item.PosSession
              ? "pdv"
              : "online";
          const type = !item.PosSession
            ? "site"
            : item.paymentType == "MONEY"
            ? "pdvMoney"
            : "pdvDigital";
          const quantity = item.Ticket.length;
          const fee = item.platformFee;
          const amount = item.amount - item.platformFee;

          acc.byMethod[method].quantity += quantity;
          acc.byMethod[method].fee += fee;
          acc.byMethod[method].amount += amount;
          acc.byMethod.total.quantity += quantity;
          acc.byMethod.total.fee += fee;
          acc.byMethod.total.amount += amount;

          acc.byType[type].quantity += quantity;
          acc.byType[type].fee += fee;
          acc.byType[type].amount += amount;
          acc.byType.total.quantity += quantity;
          acc.byType.total.fee += fee;
          acc.byType.total.amount += amount;

          return acc;
        },
        {
          byMethod: {
            online: {
              quantity: 0,
              fee: 0,
              amount: 0,
            },
            pdv: {
              quantity: 0,
              fee: 0,
              amount: 0,
            },
            courtesy: {
              quantity: 0,
              fee: 0,
              amount: 0,
            },
            total: {
              quantity: 0,
              fee: 0,
              amount: 0,
            },
          },
          byType: {
            site: {
              quantity: 0,
              fee: 0,
              amount: 0,
            },
            pdvDigital: {
              quantity: 0,
              fee: 0,
              amount: 0,
            },
            pdvMoney: {
              quantity: 0,
              fee: 0,
              amount: 0,
            },
            total: {
              quantity: 0,
              fee: 0,
              amount: 0,
            },
          },
        }
      );
    },
  },
};
</script>

<style></style>

<template>
  <div class="mt-2">
    <v-alert v-if="error" type="error">
      <v-row align="center">
        <v-col class="grow py-0">
          {{ error }}
        </v-col>
        <v-col class="shrink py-0">
          <v-btn small @click="getGuests" :disabled="refreshDisabled">
            Tentar novamente
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <template v-if="loading && !itens.length">
      <v-progress-linear
        color="info"
        indeterminate
        class="rounded rounded-b-0"
      />
      <v-alert text type="info" class="mb-0 rounded-t-0">
        Carregando convidados...
      </v-alert>
    </template>
    <v-alert
      text
      v-else-if="!guests.length && !error && !loading"
      type="info"
      class="mb-0"
    >
      Nenhum convidado encontrado
    </v-alert>
    <div class="px-0" v-else-if="!error">
      <div class="d-flex justify-space-between align-center">
        <h6 class="mb-0">
          {{ displayType === "guest" ? "Convidados" : "Pagamentos" }}
        </h6>
        <v-btn-toggle class="px-2" mandatory v-model="displayType">
          <v-btn small value="guest">
            <v-icon small>mdi-account-group</v-icon>
            <span class="hidden-sm-and-down ml-2">Convidados</span>
          </v-btn>
          <v-btn small value="payment">
            <v-icon small>mdi-cash-multiple</v-icon>
            <span class="hidden-sm-and-down ml-2">Pagamentos</span>
          </v-btn>
        </v-btn-toggle>
      </div>
      <div class="d-flex pa-2">
        <v-text-field
          outlined
          dense
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar pelo usuário, #pagamento ou #ingresso"
          single-line
          hide-details
        />
        <v-btn
          icon
          @click="getGuests"
          :loading="loading"
          :disabled="refreshDisabled"
          class="ml-2"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </div>
      <div class="d-flex pa-2 py-0">
        <ticket-block-chip-filter
          v-model="filter.ticketBlocks"
          :ticket-groups="ticketGroups || []"
        />
      </div>

      <v-data-table
        :headers="computedHeaders"
        :items="itens"
        :loading="loading"
        :sort-by.sync="sort.sortBy"
        :sort-desc.sync="sort.descending"
        :items-per-page="25"
        dense
        :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100, -1] }"
      >
        <template v-slot:item.id="{ item }">
          <span class="text-overline lh-1">#{{ item.id | shortId }}</span>
        </template>
        <template v-slot:item.createdAt="{ item }">
          {{ item.createdAt | date("DD/MM/YY HH:mm") }}
        </template>
        <template v-slot:item.amount="{ item }">
          {{ item.amount | currency(true) }}
        </template>
        <template v-slot:item.name="{ item }">
          <v-avatar v-if="!item.name" :size="24" class="mr-2">
            <v-icon>mdi-account-question</v-icon>
          </v-avatar>
          <base-avatar
            v-else
            :src="item.photo"
            :seed="item.id"
            :size="24"
            class="mr-2"
          />
          {{ item.name || "Não cadastrado" }}
        </template>
        <template v-slot:item.phone="{ item }">
          <a
            :href="`http://wa.me/55${(item.phone || '').replace(
              /[^0-9]/g,
              ''
            )}?text=Olá, ${item.name}!`"
            target="_blank"
            class="text-decoration-none text--black"
          >
            {{ item.phone }}
          </a>
        </template>

        <template v-slot:item.email="{ item }">
          <a :href="'mailto:' + item.email">{{ item.email }}</a>
        </template>
        <template v-slot:item.tickets="{ item }">
          <span v-if="item.tickets === 0" class="text--secondary">Nenhum</span>
          <span v-else-if="item.tickets === 1">1 ingresso</span>
          <span v-else>{{ item.tickets }} ingressos</span>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            label
            small
            :color="item.status.color"
            class="font-weight-medium"
          >
            {{ item.status.text }}
          </v-chip>
        </template>
        <template v-slot:item.lastPayment="{ item }">
          <span v-if="item.lastPayment">
            {{ item.lastPayment | date("DD/MM/YY HH:mm") }}
          </span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            icon
            @click="viewDetails(item)"
            :disabled="loading"
            class="mr-2"
          >
            <v-icon small>mdi-eye</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <guest-profile @refresh="getGuests" :party="party" />
    <payment-details @refresh="getGuests" :party="party" />
    <ticket-details @refresh="getGuests" :party="party" />
  </div>
</template>

<script>
import ORGANIZATION from "@/services/admin/organization";
import TICKET from "@/services/admin/ticket";
import GuestProfile from "../modals/Guest/GuestProfile.vue";
import PaymentDetails from "../modals/Guest/PaymentDetails.vue";
import TicketDetails from "../modals/Guest/TicketDetails.vue";
import TicketBlockChipFilter from "../../global/TicketBlockChipFilter.vue";

const PARTYREPORTS = ORGANIZATION.party.reports;
import PAYMENT from "@/utils/payment";

export default {
  components: {
    GuestProfile,
    PaymentDetails,
    TicketDetails,
    TicketBlockChipFilter,
  },
  data: () => ({
    loading: false,
    error: false,
    refreshDisabled: false,
    search: "",
    menu: false,
    guests: [],
    ticketGroups: [],
    interval: null,
    displayType: "guest",
    sort: {
      sortBy: "lastPayment",
      descending: true,
    },
    filter: {
      ticketBlocks: [],
    },
    headers: [
      {
        text: "#Pagamento",
        align: "start",
        sortable: true,
        value: "id",
        for: ["payment"],
      },
      {
        text: "Data",
        align: "start",
        sortable: true,
        value: "createdAt",
        for: ["payment"],
      },
      {
        text: "Nome",
        align: "start",
        sortable: true,
        value: "name",
        for: ["guest"],
      },
      {
        text: "Whatsapp",
        value: "phone",
        sortable: false,
        for: ["guest"],
      },
      {
        text: "Valor",
        value: "amount",
        align: "center",
        for: ["payment"],
      },
      {
        text: "Ingressos Válidos",
        value: "tickets",
        align: "center",
        for: ["guest", "payment"],
      },
      {
        text: "Última compra",
        sortable: true,
        value: "lastPayment",
        for: ["guest"],
      },
      {
        text: "Status",
        value: "status",
        align: "center",
        sortable: false,
        cellClass: "px-1",
        for: ["guest", "payment"],
      },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "center",
        cellClass: "px-1",
        for: ["guest", "payment"],
      },
    ],
    paymentStatus: PAYMENT.ticketStatus,
  }),
  methods: {
    async getGuests() {
      this.error = false;
      this.loading = true;
      this.refreshDisabled = true;
      try {
        const { guests } = await PARTYREPORTS.guests(
          this.party.organizationId,
          this.party.id
        );
        this.guests = guests;
        setTimeout(() => {
          this.refreshDisabled = false;
        }, 10000);
      } catch (error) {
        setTimeout(() => {
          this.refreshDisabled = false;
        }, 5000);
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
    async getTickets() {
      try {
        const response = await TICKET.getTickets(
          this.party.organizationId,
          this.party.id
        );
        this.ticketGroups = response.ticketGroups;
        this.filter.ticketBlocks = this.ticketGroups.reduce(
          (acc, tg) => [...acc, ...tg.TicketBlock.map((tb) => tb.id)],
          []
        );
      } catch (error) {
        console.error(error);
      }
    },
    filterName(search, member) {
      if (!member.name) return false;
      const name = member.name.toLowerCase();
      const names = search.trim().toLowerCase().split(" ");
      return names.every((n) => name.includes(n));
    },
    filterPhone(search, member) {
      if (!member.phone) return false;
      let p = member.phone.replace(/\D/g, "");
      let s = search.replace(/\D/g, "");
      if (!p || !s) return false;
      return p.includes(s);
    },
    filterEmail(search, member) {
      if (!member.email) return false;
      return member.email.toLowerCase().includes(search.toLowerCase());
    },

    filterList(value, search, item) {
      return (
        this.filterName(search, item) ||
        this.filterPhone(search, item) ||
        this.filterEmail(search, item)
      );
    },
    calculateStatus(guest) {
      const validTickets = guest.TicketOwner.filter(
        (t) => t.Payment.status === "succeeded"
      );

      if (validTickets.length !== 0)
        return {
          status: this.paymentStatus["succeeded"],
          tickets: validTickets.length,
        };

      if (
        guest.TicketOwner.some(
          (t) =>
            !["canceled", "refunded", "succeeded"].includes(t.Payment.status)
        )
      )
        return {
          status: { color: "orange", text: "Aguardando" },
          tickets: validTickets.length,
        };
      return {
        status: { color: "secondary", text: "Não pago" },
        tickets: validTickets.length,
      };
    },
    viewDetails(guest) {
      if (this.displayType === "payment")
        this.$root.$emit("payment-details", guest);
      else this.$emit("guest-profile", guest);
    },
  },
  computed: {
    itens() {
      return this.displayType === "guest"
        ? this.computedClients
        : this.computedPayments;
    },

    preFilteredGuests() {
      if (
        !this.filter.ticketBlocks.length ||
        this.filter.ticketBlocks.length === this.totalTicketBlocks
      )
        return this.guests;
      return this.guests.filter((g) => {
        if (
          g.TicketOwner.some((t) =>
            this.filter.ticketBlocks.includes(t.ticketBlockId)
          )
        )
          return true;
      });
    },
    filteredGuests() {
      if (this.search === "") return this.preFilteredGuests;
      if (this.search.startsWith("#")) {
        var id = this.search.slice(1).trim().toLowerCase();
        return this.preFilteredGuests
          .filter((g) =>
            g.TicketOwner.some(
              (t) => t.id.startsWith(id) || t.Payment.id.startsWith(id)
            )
          )
          .map((g) => {
            return {
              ...g,
              TicketOwner: g.TicketOwner.filter(
                (t) => t.id.startsWith(id) || t.Payment.id.startsWith(id)
              ),
            };
          });
      } else {
        return this.preFilteredGuests.filter((g) =>
          this.filterList(null, this.search, g)
        );
      }
    },
    computedClients() {
      return this.filteredGuests
        .filter((g) => !!g.id)
        .map((g) => {
          return {
            ...g,
            ...this.calculateStatus(g),
            lastPayment: g.TicketOwner[0]?.createdAt,
          };
        });
    },
    computedPayments() {
      const payments = this.filteredGuests.reduce((acc, g) => {
        g.TicketOwner.forEach((t) => {
          if (!acc[t.Payment.id]) {
            acc[t.Payment.id] = {
              ...t.Payment,
              ...this.calculateStatus({
                TicketOwner: [t],
              }),
              tickets: 0,
            };
          }
          acc[t.Payment.id].tickets += 1;
        });
        return acc;
      }, {});
      return Object.values(payments);
    },
    computedHeaders() {
      return this.headers.filter((h) => h.for.includes(this.displayType));
    },
    totalTicketBlocks() {
      console.log("ticketGroups", this.ticketGroups);
      return this.ticketGroups.reduce(
        (acc, tg) => acc + tg.TicketBlock.length,
        0
      );
    },
  },
  watch: {
    displayType(val) {
      this.sort = {
        sortBy: val === "payment" ? "createdAt" : "lastPayment",
        descending: true,
      };
    },
    menu(val) {
      if (!val) return;
      const ticketBlockSelector = this.$refs.ticketBlockSelector;
      this.$nextTick(() => {
        ticketBlockSelector.focus();
      });
    },
  },

  mounted() {
    this.getGuests();
    this.getTickets();
    this.interval = setInterval(() => {
      this.getGuests();
    }, 2 * 60 * 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>

import http from "../../../http-common";

// const stripe = async (orgId) => {
//   return await http
//     .get(`/admin/organization/${orgId}/stripe/settings`)
//     .then((r) => r.data)
//     .catch((error) => {
//       throw error.response?.data || error.response || error;
//     });
// };

const deleteImage = async (orgId, productId, variantId, imageId) => {
  return await http
    .delete(
      `/admin/organization/${orgId}/product/${productId}/variant/${variantId}/image/${imageId}`
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const updateStock = async (orgId, productId, data) => {
  return await http
    .put(`/admin/organization/${orgId}/product/${productId}/stock`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const createCategory = async (orgId, data) => {
  return await http
    .post(`/admin/organization/${orgId}/product/category`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const createVariant = async (orgId, productId, data) => {
  return await http
    .post(`/admin/organization/${orgId}/product/${productId}/variant`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const updateVariant = async (orgId, productId, variantId, data) => {
  return await http
    .put(
      `/admin/organization/${orgId}/product/${productId}/variant/${variantId}`,
      data
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const deleteVariant = async (orgId, productId, variantId) => {
  return await http
    .delete(
      `/admin/organization/${orgId}/product/${productId}/variant/${variantId}`
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const updateCategory = async (orgId, categoryId, data) => {
  return await http
    .put(`/admin/organization/${orgId}/product/category/${categoryId}`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const updateProduct = async (orgId, productId, data) => {
  return await http
    .put(`/admin/organization/${orgId}/product/${productId}`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const getAll = async (orgId, query) => {
  return await http
    .get(`/admin/organization/${orgId}/product`, { params: query })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const create = async (orgId, data) => {
  return await http
    .post(`/admin/organization/${orgId}/product`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const uploadImage = (orgId, productId, file) => {
  if (!file) throw new Error("file is required");

  var formData = new FormData();
  formData.append("file", file);

  return http
    .put(`/admin/organization/${orgId}/product/${productId}/image`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((r) => r.data)
    .catch((e) => {
      throw e.response.data || e;
    });
};

const uploadVariantImage = (orgId, productId, variantId, file) => {
  if (!file) throw new Error("file is required");

  var formData = new FormData();
  formData.append("file", file);

  return http
    .post(
      `/admin/organization/${orgId}/product/${productId}/variant/${variantId}/image`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((r) => r.data)
    .catch((e) => {
      throw e.response.data || e;
    });
};

const setPartyProducts = async (orgId, partyId, data) => {
  return await http
    .put(`/admin/organization/${orgId}/party/${partyId}/product`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const orderReports = async (orgId) => {
  return await http
    .get(`/admin/organization/${orgId}/product/reports/orders`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

export default {
  getAll,
  create,
  createVariant,
  updateVariant,
  deleteVariant,
  updateProduct,
  uploadImage,
  uploadVariantImage,
  updateStock,
  deleteImage,
  setPartyProducts,
  category: {
    create: createCategory,
    update: updateCategory,
  },
  reports: {
    orders: orderReports,
  }
};

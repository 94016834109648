<template>
  <div class="mt-2">
    <v-expansion-panels focusable v-model="expansePanel">
      <v-expansion-panel v-for="(ep, i) in expansionsPanels" :key="ep.name">
        <v-expansion-panel-header class="justify-start" disable-icon-rotate>
          <div class="d-flex align-center" style="gap: 1rem">
            <v-icon>{{ ep.icon }}</v-icon>
            <h6 class="mb-0">{{ ep.title }}</h6>
          </div>
          <template v-slot:actions>
            <v-btn small icon v-if="!!ep.saving" :loading="ep.saving.loading">
              <v-icon :color="ep.saving.error ? 'error' : 'success'">
                {{
                  ep.saving.error
                    ? "mdi-alert-circle"
                    : "mdi-content-save-check"
                }}
              </v-icon>
            </v-btn>
            <v-btn icon v-else-if="expansePanel !== i || expansePanel == null">
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
            <v-btn icon color="primary" v-else>
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <component
            :is="ep.component"
            :party="party"
            :ref="ep.name"
            :loading="expansionsPanels[i]?.saving?.loading"
          ></component>
          <div class="d-flex justify-end">
            <v-btn
              color="success"
              :loading="ep.saving.loading"
              @click="saveGroup(i)"
            >
              Salvar
            </v-btn>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import ORGANIZATION from "@/services/admin/organization";
import AgeGroup from "./policies/AgeGroup.vue";
import Entrance from "./policies/Entrance.vue";
import Refund from "./policies/Refund.vue";
import Privacy from "./policies/Privacy.vue";
import SalesRestriction from "./policies/SalesRestriction.vue";
import OnlineSale from "./policies/OnlineSale.vue";

export default {
  components: {
    AgeGroup,
    Entrance,
    Refund,
    SalesRestriction,
    OnlineSale,
    Privacy,
  },
  data: () => ({
    loading: false,
    fileLoading: false,
    valid: false,
    error: false,
    expansePanel: null,
    expansionsPanels: [
      {
        name: "salesRestriction",
        icon: "mdi-lock",
        title: "Restrição de Vendas",
        component: "sales-restriction",
        saving: false,
      },
      {
        name: "privacy",
        icon: "mdi-security",
        title: "Privacidade",
        component: "Privacy",
        saving: false,
      },
      {
        name: "ageGroup",
        icon: "mdi-account-group",
        title: "Faixa Etária",
        component: "age-group",
        saving: false,
      },
      {
        name: "entrance",
        icon: "mdi-shield-account",
        title: "Portaria",
        component: "entrance",
        saving: false,
      },
      {
        name: "refund",
        icon: "mdi-cash-refund",
        title: "Reembolso e Transferência",
        component: "refund",
        saving: false,
      },
      {
        name: "onlineSale",
        icon: "mdi-calendar-cursor",
        title: "Vendas Online",
        component: "online-sale",
        saving: false,
      },
    ],
  }),
  methods: {
    async update() {
      try {
        this.loading = true;
        this.error = false;
        const response = await ORGANIZATION.party.update(this.partyBkp);
        this.partyBkp = response.party;
        this.$emit("refresh");
      } catch (e) {
        this.error = error.message;
        this.loading = false;
      }
    },
    async saveGroup(index) {
      try {
        this.expansionsPanels[index].saving = {
          loading: true,
          error: false,
        };

        await this.$refs[this.expansionsPanels[index].name][0].save();

        this.expansionsPanels[index].saving = {
          loading: false,
          error: false,
        };
      } catch (e) {
        console.error(e);
        this.expansionsPanels[index].saving = {
          loading: false,
          error: true,
        };
      } finally {
        await new Promise((resolve) => setTimeout(resolve, 5000));
        this.expansionsPanels[index].saving = false;
      }
    },
  },
  watch: {
    expansePanel(val, oldVal) {
      if (oldVal != null) this.saveGroup(oldVal);
      if (val != null) this.expansionsPanels[val].saving = false;
    },
  },
  mounted() {
    this.policies = Object.assign({}, this.party);
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>

<template>
  <div class="mt-2">
    <div class="d-flex">
      <h6 class="mb-0">Selecionar produtos para venda no evento</h6>
    </div>

    <v-row>
      <v-col
        v-for="product in products"
        :key="product.id"
        cols="6"
        sm="4"
        md="3"
        lg="2"
      >
        <v-card rounded="xl">
          <v-img
            :aspect-ratio="1"
            :src="product.image"
            @click="toggle(product)"
            style="cursor: pointer"
          >
            <v-card
              style="position: absolute; top: 10px; left: 10px"
              class="d-flex"
            >
              <v-checkbox
                @click.stop
                v-model="upsellProducts"
                multiple
                :value="product.id"
                color="primary"
                class="ma-0 pt-0 remove-padding text-truncate"
                hide-details
              />
            </v-card>

            <div
              class="mx-4 text-center"
              style="
                position: absolute;
                bottom: 10px;
                left: 10px;
                right: 10px;
                cursor: pointer;
              "
            >
              <v-chip
                class="font-weight-bold unselectable non-draggable"
                :color="
                  upsellProducts.includes(product.id) ? 'primary' : undefined
                "
              >
                {{ product.name }}
              </v-chip>
            </div>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
    <v-alert v-if="error" type="error">
      {{ error }}
    </v-alert>
    <div class="d-flex justify-end mt-4">
      <v-btn
        color="primary"
        :loading="loading"
        :disabled="loading"
        @click="saveProducts"
      >
        Salvar
      </v-btn>
    </div>
  </div>
</template>

<script>
import PRODUCT from "@/services/admin/products";

export default {
  components: {},
  data: () => ({
    loading: false,
    error: false,
    products: [],
    upsellProducts: [],
  }),
  methods: {
    toggle(product) {
      const index = this.upsellProducts.indexOf(product.id);
      if (index === -1) {
        this.upsellProducts.push(product.id);
      } else {
        this.upsellProducts.splice(index, 1);
      }
    },
    async saveProducts() {
      try {
        this.loading = true;
        this.error = null;
        const organizationId = this.party.organizationId;
        const partyId = this.party.id;
        const products = this.upsellProducts;

        await PRODUCT.setPartyProducts(organizationId, partyId, { products });
      } catch (error) {
        this.error = error.message || "Erro ao salvar produtos";
      } finally {
        this.loading = false;
      }
    },
    async getProducts() {
      this.loading = true;
      this.error = null;
      const organizationId = this.party.organizationId;
      try {
        const data = await PRODUCT.getAll(organizationId, {
          partyId: this.party.id,
        });
        this.products = data.products;
        this.categories = data.categories;
        data.products.forEach((product) => {
          if (product.Parties && product.Parties.length > 0)
            this.upsellProducts.push(product.id);
        });
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
    decomposeToBits(num) {
      const bits = [];
      let power = 0;

      while (num > 0) {
        if (num & 1) {
          bits.push(Math.pow(2, power));
        }
        num = num >> 1;
        power++;
      }

      return bits;
    },
  },
  mounted() {
    this.getProducts();
  },
  computed: {},
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.remove-padding {
  .v-input--selection-controls__input {
    margin-right: 0px !important;
  }
}
</style>

const { VUE_APP_PLATFORM_NAME } = process.env;

const ExcelJS = require("exceljs");
const moment = require("moment");

const downloadFile = require("./utils/downloadFile");
const header = require("./modules/header");
const formatName = require("./utils/formatName");
const PAYMENT = require("../payment.js").default;

const store = require("../../store").default;

module.exports = async ({ party, ticketGroups, tickets }) => {
  const opMode = store?.getters["auth/config"]?.opMode || false;

  const workbook = new ExcelJS.Workbook();
  workbook.creator = VUE_APP_PLATFORM_NAME;
  workbook.lastModifiedBy = VUE_APP_PLATFORM_NAME;

  workbook.title = `Vendas por setor - ${formatName(party.name)}`;
  workbook.created = new Date();

  const sheetResume = workbook.addWorksheet("Resumo", {
    properties: { tabColor: { argb: "242939" } },
    views: [{ showGridLines: false }]
  });


  const columns = [
    { key: "name", width: 50 },
    { key: "sales", width: 15 },
    { key: "courtesy", width: 18 },
    { key: "totalTickets", width: 20 },
    { key: "validated", width: 20 },
    { key: "averageTicket", width: 20 },
    { key: "totalValue", width: 20 },
  ];
  sheetResume.columns = columns.flat();
  sheetResume.columns.forEach((column, index) => {
    if (!index) column.alignment = { vertical: "middle", horizontal: "left" };
    else column.alignment = { vertical: "middle", horizontal: "center" };
  });
  applyStyle(sheetResume, {
    font: { name: "Courier New" }
  });


  await header(workbook, sheetResume, { title: "Vendas por setor", party });

  // Dados
  const lastColumn = String.fromCharCode(65 + columns.length - 1);
  ticketGroups.forEach((ticketGroup) => {
    const r = sheetResume.addRow({ name: ticketGroup.name, });
    r.font = { bold: true, size: 14 };
    r.alignment = { horizontal: "center" };
    r.height = 50;
    r.eachCell((cell) => { cell.border = { bottom: { style: "thin" }, top: { style: "medium" } }; })


    // header 
    sheetResume.mergeCells(`A${r.number}:${lastColumn}${r.number}`);
    for (let i = 1; i <= columns.length; i++) {
      r.getCell(i).font = { name: "Courier New", bold: true, size: 12, color: { argb: "295f99" } };
    }

    // add columns
    const hRow = sheetResume.addRow({
      name: `Setor • ${ticketGroup.name}`,
      sales: "Vendas",
      courtesy: "Cortesias",
      totalTickets: "Total Ingressos",
      averageTicket: "Ticket Médio",
      validated: "Validados",
      totalValue: "Valor Total",
    });
    hRow.font = { name: "Courier New", bold: true, size: 12 };
    const initialCell = hRow.getCell(1);
    ticketGroup.TicketBlock.forEach((ticketBlock) => {
      var tbRow = sheetResume.addRow({
        name: ticketBlock.name,
        sales: ticketBlock.sale,
        courtesy: ticketBlock.courtesy,
        totalTickets: ticketBlock.count,
        averageTicket: {
          formula: `G${sheetResume.rowCount + 1} / D${sheetResume.rowCount + 1}`,
          result: ticketBlock.value / ticketBlock.count,
        },
        validated: ticketBlock.validated,
        totalValue: ticketBlock.value,
      });

      tbRow.getCell(6).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00';
      tbRow.getCell(7).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00';
    });

    // Total row
    const initialRow = sheetResume.rowCount - ticketGroup.TicketBlock.length;
    const totalRow = sheetResume.addRow({
      name: "Sub-Total",
      sales: ticketGroup.total.sale,
      courtesy: ticketGroup.total.courtesy,
      totalTickets: ticketGroup.total.count,
      validated: ticketGroup.total.validated,
      averageTicket: ticketGroup.total.value / ticketGroup.total.count,
      totalValue: ticketGroup.total.value,
    });

    totalRow.getCell(1).alignment = { horizontal: "right" };
    totalRow.getCell(6).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00';
    totalRow.getCell(7).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00';
    totalRow.font = { name: "Courier New", bold: true, color: { argb: "FF0000" } };
    totalRow.eachCell((cell) => { cell.border = { top: { style: "dotted" } }; });
    totalRow.height = 18;

    // Empty row
    sheetResume.addRow([]);
  });

  sheetResume.addRow([]);

  const totalValue = ticketGroups.reduce((acc, { total }) => {
    return {
      sale: acc.sale + total.sale,
      courtesy: acc.courtesy + total.courtesy,
      count: acc.count + total.count,
      value: acc.value + total.value,
      validated: acc.validated + total.validated,
    }
  }, { sale: 0, courtesy: 0, count: 0, value: 0, validated: 0 });

  const totalRow = sheetResume.addRow({
    name: "Total",
    sales: totalValue.sale,
    courtesy: totalValue.courtesy,
    totalTickets: totalValue.count,
    averageTicket: totalValue.value / totalValue.count,
    validated: totalValue.validated,
    totalValue: totalValue.value,
  });

  totalRow.height = 30;
  totalRow.font = { name: "Courier New", bold: true, color: { argb: "FF0000" } };
  totalRow.alignment = { vertical: "middle", horizontal: "center" };
  totalRow.getCell(6).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00';
  totalRow.getCell(7).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00';
  totalRow.getCell(1).alignment = { horizontal: "right", vertical: "middle" };
  totalRow.eachCell((cell, i) => {
    cell.border = {
      top: { style: "double" },
      bottom: { style: "double" },
      left: i === 1 ? { style: "double" } : undefined,
      right: i === 7 ? { style: "double" } : undefined,
    };
  });

  const timestempRow = sheetResume.addRow({ name: moment().format("DD [de] MMMM [de] YYYY   HH:mm:ss") });
  timestempRow.font = { size: 10 };
  timestempRow.alignment = { horizontal: "right" };
  timestempRow.height = 50;
  sheetResume.mergeCells(`A${timestempRow.number}:${lastColumn}${timestempRow.number}`);


  // Transactions
  const sheetTransactions = workbook.addWorksheet("Ingressos", {
    properties: { tabColor: { argb: "242939" } },
  });

  const columnsTransactions = [
    { header: "#", key: "id", width: 12 },
    { header: "Data", key: "date", width: 22 },
    { header: "Setor", key: "group", width: 25 },
    { header: "Lote", key: "block", width: 25 },
    { header: "Válidado", key: "validated", width: 20 },
    { header: "Valor Líquido", key: "value", width: 20 },
    opMode ? { header: "Taxa Plataforma", key: "platformFee", width: 20 } : null,
    // opMode ? { header: "Taxa Cliente", key: "clientFee", width: 20 } : null,
    { header: "Meio de pagamento", key: "paymentMethod", width: 25 },
    { header: "Forma de pagamento", key: "paymentType", width: 25 },
  ].filter((column) => !!column);

  sheetTransactions.columns = columnsTransactions.flat();
  applyStyle(sheetTransactions, {
    font: { name: "Courier New" }
  });

  sheetTransactions.getRow(1).font = { bold: true, name: "Courier New" };
  sheetTransactions.columns.forEach((column, index) => {
    if (index < 5) column.alignment = { vertical: "middle", horizontal: "left" };
    else column.alignment = { vertical: "middle", horizontal: "center" }
  });

  tickets
    .sort((a, b) => a.Payment.createdAt - b.Payment.createdAt)
    .forEach((ticket) => {
      const r = sheetTransactions.addRow({
        id: ticket.id.substring(0, 8),
        date: moment(ticket.Payment.createdAt).format("DD/MM/YYYY HH:mm"),
        group: ticket.TicketBlock.TicketGroup.name,
        block: ticket.TicketBlock.name,
        validated: ticket._count.TicketEntry ? "Sim" : "Não",
        value: ticket.amount - ticket.platformFee,
        platformFee: ticket.platformFee || 0,
        // clientFee: ticket.clientFee || 0,
        paymentMethod: PAYMENT.paymentMethod[ticket.Payment.paymentMethod].text,
        paymentType: PAYMENT.paymentType[ticket.Payment.paymentType].text,
      });
      r.getCell(6).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00';
    });



  // Protect sheet
  if (!opMode) {
    const ramdomPassword = Math.random().toString(36).slice(-8);
    console.log(ramdomPassword);
    sheetResume.protect(ramdomPassword, {
      sort: true,
      autoFilter: true,
      selectLockedCells: false,
    });
  }

  downloadFile(workbook, `Vendas por Setor - ${party.name}`);
  return true;
};


function applyStyle(sheet, style) {
  const { font, alignment } = style;
  sheet.columns.forEach((column, index) => {
    if (font) column.font = font;
    if (alignment) column.alignment = alignment;
  });
} 
import http from "@/http-common";

const create = async (orgId, partyId, options) => {
    return await http
      .post(`/admin/organization/${orgId}/party/${partyId}/print-options`, options)
      .then((r) => r.data)
      .catch((error) => {
        throw error.response?.data || error.response || error;
      });
  };

const get = async (orgId, partyId) => {
  return await http
    .get(`/admin/organization/${orgId}/party/${partyId}/print-options`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

// const upload = async (orgId, partyId, { id, ...options }) => {
//   return await http
//     .put(`/admin/organization/${orgId}/party/${partyId}/period/${id}`, options)
//     .then((r) => r.data)
//     .catch((error) => {
//       throw error.response?.data || error.response || error;
//     });
// };

export default { get, create };

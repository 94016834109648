<template>
  <v-dialog v-model="dialog" max-width="400px">
    <v-card rounded="lg">
      <v-card-title> Vincular POS por Número de Série </v-card-title>
      <v-card-text>
        <v-autocomplete
          v-model="session"
          :items="items"
          :loading="loading"
          :search-input.sync="search"
          hide-no-data
          hide-details
          item-text="Device.serialNumber"
          label="Número de Série"
          placeholder="Digite o número de série"
          return-object
          outlined
          :filter="filter"
        >
          <template v-slot:selection="data">
            <v-img
              v-if="session.Device.Model?.image"
              :src="
                (session.Device?.acquirer &&
                  session.Device?.Model?.imageVariants?.[
                    session?.Device?.acquirer
                  ]) ||
                session.Device.Model.image
              "
              max-height="30"
              max-width="30"
            />
            {{ data.item.Device.serialNumber }}
          </template>

          <template v-slot:item="{ item: session }">
            <v-list-item-avatar>
              <v-img
                v-if="session.Device.Model?.image"
                :src="
                  (session.Device?.acquirer &&
                    session.Device?.Model?.imageVariants?.[
                      session?.Device?.acquirer
                    ]) ||
                  session.Device.Model.image
                "
                max-height="40"
                max-width="40"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <span>
                {{ session.Device.Model.model }} •
                {{ session.Device.Model.brand }}
              </span>
              <h6 class="mb-0">{{ session.Device.serialNumber }}</h6>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-card-text>
      <v-card-actions class="justify-space-between">
        <v-btn text @click="dialog = false">cancelar</v-btn>
        <v-btn color="primary" @click="connect" :disabled="!session"> Continuar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

import POS from "@/services/admin/pos";

export default {
  data: () => ({
    dialog: false,
    session: null,
    search: null,
    loading: false,
    list: [],
    timeout: null,
  }),
  methods: {
    open() {
      if (this.timeout) clearTimeout(this.timeout);
      this.dialog = true;
      this.list = [];
      this.session = "";
    },
    async performSearch() {
      try {
        let orgId = this.selectedOrganization.id;
        const { sessions } = await POS.adopt.searchSN(orgId, this.search);
        this.list = sessions;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    connect() {
      this.dialog = false;
      this.$emit("success", this.session);
    },
    filter(item, query) {
      return item.Device.serialNumber
        .toLowerCase()
        .startsWith(query.toLowerCase());
    },
  },

  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),

    fields() {
      if (!this.model) return [];

      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || "n/a",
        };
      });
    },
    items() {
      return this.list;
    },
  },

  watch: {
    search(val) {
      if (this.timeout) clearTimeout(this.timeout);
      this.loading = true;
      this.timeout = setTimeout(() => {
        this.performSearch();
      }, 500);
    },
  },

  mounted() {
    this.$root.$on("find-pos-session-by-sn", this.open);
  },
};
</script>

<style>
</style>
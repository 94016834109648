<template>
  <v-card outlined :loading="loading">
    <v-card-title class="justify-space-between">
      <h6 class="mb-0">Vendas por PDV</h6>
      <v-btn
        icon
        @click="() => $refs.modal.open()"
        :disabled="loading || !filteredTickets.length"
        small
      >
        <v-icon small>mdi-arrow-expand</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :items="ticketGroups"
        :headers="headers"
        hide-default-footer
        single-expand
        item-key="id"
        show-expand
        :expanded.sync="expanded"
      >
        <template v-slot:item.name="{ item }">
          <td class="px-1">
            <div class="d-flex align-center gap-2">
              <v-avatar v-if="!item.active" color="warning" size="20">
                <v-icon size="12" color="white">mdi-connection</v-icon>
              </v-avatar>
              <div>
                <span class="mb-0 d-block text-overline lh-1">
                  SN:{{ item.Device.serialNumber }}
                </span>
                <span>{{ item.name || item.Address?.name }}</span>
              </div>
            </div>
          </td>
        </template>
        <template v-slot:item.qnt="{ item }">
          <td class="px-1 text-right">
            <span> {{ item.qnt }} ingresso{{ item.qnt == 1 ? "" : "s" }}</span>
            <span class="mb-0 d-block">{{ item.totalValue | currency }}</span>
          </td>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-2">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Forma de Pagamento</th>
                    <th class="text-right">Qtde</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="paymentType in item.paymentTypes"
                    :key="paymentType.paymentType"
                  >
                    <td>
                      <b class="d-block">
                        {{ PAYMENTTYPE[paymentType.paymentType].text }}
                      </b>
                    </td>
                    <td class="text-right">
                      <span>
                        {{ paymentType.qnt }} ingresso{{
                          paymentType.qnt == 1 ? "" : "s"
                        }}
                      </span>
                      <span class="mb-0 d-block">
                        {{ paymentType.totalValue | currency }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
    <ticket-office-report-modal
      ref="modal"
      :data="data"
      :loading="loading"
      :filtered-tickets="filteredTickets"
      title="Vendas por PDV"
    ></ticket-office-report-modal>
  </v-card>
</template>

<script>
import payment from "@/utils/payment";
import TicketOfficeReportModal from "./modals/PosReportModal.vue";
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  components: { TicketOfficeReportModal },
  data() {
    return {
      PAYMENTTYPE: payment.paymentType,
      expanded: [],
      headers: [
        {
          text: "POS",
          value: "name",
          align: "left",
        },
        {
          text: "Qtde",
          value: "qnt",
          align: "right",
        },
      ],
    };
  },
  computed: {
    filteredTickets() {
      return this.data.tickets.filter(
        ({ Payment: p }) =>
          p.status === "succeeded" && p.PosSession && !p.PosSession?.partyId
      );
    },
    ticketGroups() {
      return Object.values(
        this.filteredTickets.reduce((acc, t) => {
          const p = t.Payment;
          let id = p.PosSession.id;

          if (!acc[id])
            acc[id] = {
              ...p.PosSession,
              qnt: 0,
              totalValue: 0,
              paymentTypes: {},
            };
          acc[id].qnt += 1;
          acc[id].totalValue += t.amount - t.platformFee;

          const paymentType = p.paymentType;
          if (!acc[id].paymentTypes[paymentType])
            acc[id].paymentTypes[paymentType] = { qnt: 0, totalValue: 0 };
          acc[id].paymentTypes[paymentType].qnt += 1;
          acc[id].paymentTypes[paymentType].totalValue +=
            t.amount - t.platformFee;

          return acc;
        }, {})
      ).map((v) => ({
        ...v,
        paymentTypes: Object.entries(v.paymentTypes).map(
          ([paymentType, value]) => ({ ...value, paymentType })
        ),
      }));
    },
  },
};
</script>

<style scoped></style>

<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-card outlined class="pa-4 d-flex flex-column gap-4">
        <div>
          <h6>Video</h6>
          <v-text-field
            v-model="multimedia.youtubeId"
            label="Link do Youtube"
            dense
            outlined
            hide-details
            prefix="youtube.com/watch?v="
          />
        </div>
        <v-responsive :aspect-ratio="16 / 9">
          <iframe
            v-if="multimedia.youtubeId"
            width="100%"
            height="100%"
            style="border-radius: 8px; border: 0px"
            :src="`https://www.youtube.com/embed/${multimedia.youtubeId}`"
          >
          </iframe>
          <v-card
            v-else
            class="d-flex flex-column justify-center align-center w-full h-full"
          >
            <v-icon left x-large>mdi-youtube</v-icon>

            <p>Adicione um video para ver a prévia</p>
          </v-card>
        </v-responsive>
      </v-card>
    </v-col>
    <v-col cols="12" md="6">
      <v-card outlined class="pa-4">
        <h6>Trilha sonora</h6>
        <v-text-field
          v-model="multimedia.spotifyId"
          label="Spotify Link"
          hint="Link para uma playlist, música ou artista"
          dense
          outlined
          :rules="[(v) => !v || validSpotifyLink || 'Link inválido']"
        />
        <v-card
          v-if="!multimedia.spotifyId || !validSpotifyLink"
          color="d-flex flex-column align-center pa-4"
          elevation="0"
        >
          <v-icon left x-large>mdi-spotify</v-icon>
          <p class="mb-0">Adicione um link do Spotify para ver a prévia</p>
        </v-card>
        <spotify-embed v-else :id="multimedia.spotifyId" class="w-full" />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import PARTY from "@/services/admin/party";
import SpotifyEmbed from "../../../global/SpotifyEmbed.vue";

export default {
  components: { SpotifyEmbed },
  data() {
    return {
      multimedia: {
        youtubeId: this.party?.youtubeId || "",
        spotifyId: this.party?.spotifyId || "",
      },
      validSpotifyRegex: /((album|playlist|track|artist)\/)\S*/g,
    };
  },
  methods: {
    save() {
      return PARTY.update({
        id: this.party.id,
        organizationId: this.party.organizationId,

        youtubeId: this.multimedia.youtubeId || null,
        spotifyId: this.validSpotifyLink
          ? this.multimedia.spotifyId || null
          : null,
      });
    },
  },
  computed: {
    validSpotifyLink() {
      const id = this.multimedia.spotifyId || "";
      return !!id.match(this.validSpotifyRegex);
    },
  },
  watch: {
    party: {
      handler: function (val, oldVal) {
        if (!val) return;
        this.multimedia = {
          youtubeId: val.youtubeId,
          spotifyId: val.spotifyId,
        };
      },
      deep: true,
    },

    "multimedia.spotifyId": {
      handler(value) {
        if (!value) return;
        [value] = value.split("?");
        const regex = /((?:album|playlist|track|artist)\/)\S*/g;
        const match = value.match(regex);
        if (match) {
          const [id] = match;
          this.multimedia.spotifyId = id;
        }
      },
      immediate: true,
    },
    "multimedia.youtubeId": {
      handler(value) {
        if (!value) return;
        const regex =
          /(?:https?:\/\/)?(?:(?:(?:www\.?)?youtube\.com(?:\/(?:(?:watch\?.*?(v=[^&\s]+).*)|(?:v(\/.*))))?)|(?:youtu\.be(\/.*)?))/;
        const match = value.match(regex);
        if (match) {
          const id = match[1] || match[2];
          this.multimedia.youtubeId = id.replace("v=", "");
        }
      },
      immediate: true,
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>

<template>
  <div class="mt-2">
    <div class="d-flex">
      <v-spacer />
      <v-btn color="primary" small @click="add">
        <v-icon small>mdi-plus</v-icon>
        Adicionar
      </v-btn>
    </div>
    <v-data-table :headers="headers" :items="coOrganizers" :loading="loading">
      <template v-slot:item.Organization.name="{ item }">
        <base-avatar
          :src="item.Organization.photo"
          :seed="item.Organization.id"
          :size="30"
          avatarStyle="shapes"
          color="secondary lighten-2"
          class="mr-2"
        />
        {{ item.Organization.name }}
      </template>
      <template v-slot:item.permissions="{ item }">
        <v-chip small label>
          {{ item.permissions.length }} permiss{{
            item.permissions.length > 1 ? "ões" : "ão"
          }}
        </v-chip>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn @click="edit(item)" icon>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <co-organizer-modal :party="party" @refresh="getCoOrganizers" />
  </div>
</template>

<script>
import CO_ORGANIZER from "@/services/admin/party/coOrganizer";
import CoOrganizerModal from "../modals/CoOrganizerModal.vue";
export default {
  components: { CoOrganizerModal },
  data: () => ({
    loading: false,
    error: false,
    coOrganizers: [],
    headers: [
      { text: "Nome", value: "Organization.name" },
      { text: "Permissões", value: "permissions", sortable: false },
      { text: "", value: "action", sortable: false },
    ],
  }),
  methods: {
    edit(item) {
      this.$root.$emit("co-organizer-modal", item);
    },
    add() {
      this.$root.$emit("co-organizer-modal");
    },
    async getCoOrganizers() {
      try {
        this.loading = true;
        const data = await CO_ORGANIZER.getAll(
          this.party.organizationId,
          this.party.id
        );
        this.coOrganizers = data.coOrganizers.map((coOrganizer) => ({
          ...coOrganizer,
          permissions: this.decomposeToBits(coOrganizer.permission),
        }));
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
    decomposeToBits(num) {
      const bits = [];
      let power = 0;

      while (num > 0) {
        if (num & 1) {
          bits.push(Math.pow(2, power));
        }
        num = num >> 1;
        power++;
      }

      return bits;
    },
  },
  mounted() {
    this.getCoOrganizers();
  },
  computed: {},
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <div class="flex pl-1">
      <h5 v-if="viewOnly">Dúvidas frequentes</h5>
      <v-text-field
        class="mb-2"
        label="Qual dúvida você tem?"
        v-model="search"
        outlined
        dense
        hide-details
      ></v-text-field>
    </div>
    <v-expansion-panels v-model="expanded" focusable>
      <v-expansion-panel v-for="(item, i) in faqFiltered" :key="item.id">
        <v-expansion-panel-header>
          <div class="d-flex align-center gap-1">
            <v-icon left> {{ item.icon || "mdi-help-circle" }}</v-icon>
            <span class="font-weight-bold">
              {{ item.question }}
            </span>
          </div>
          <v-spacer />
          <v-scroll-y-transition>
            <div
              v-if="expanded === i && !viewOnly"
              class="d-flex align-center justify-space-between mx-2 flex-grow-0 gap-2"
            >
              <div class="d-flex justify-end">
                <v-btn icon @click="editFAQ(item)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon @click.stop="deleteFAQ(item.id)">
                  <v-icon v-if="confirmDelete" color="error"
                    >mdi-delete-empty</v-icon
                  >
                  <v-icon v-else>mdi-delete</v-icon>
                </v-btn>
              </div>
            </div>
          </v-scroll-y-transition>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="pt-3">
            <p
              v-for="(line, i) in item.answer.split('\n')"
              :key="i"
              class="mb-0"
              style="min-height: 20px"
            >
              {{ line }}
            </p>
          </div>
          <small class="text--disabled"
            >Esta informação é de responsabilidade do organizador do
            evento.</small
          >
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-btn
      v-if="viewOnly && faqItens.length > itemShowLimit && !search"
      @click="itemShowLimit += 5"
      text
      block
      class="mt-1"
      small
    >
      Ver mais dúvidas
    </v-btn>
    <v-alert type="info" v-if="faqFiltered.length === 0 && viewOnly" text>
      Não encontramos nenhuma resposta para a sua busca. Entre em contato com a
      organização do evento para mais informações.
    </v-alert>
  </div>
</template>

<script>
import FaqTemplate from "@/data/faq-templates.json";
import Fuse from "fuse.js";

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fuse: null,
      search: "",
      results: [],
      expanded: null,
      confirmDelete: false,
      itemShowLimit: 5,
      faqItens: [], // Inicialize faqItens aqui
    };
  },
  methods: {
    editFAQ(item) {
      this.$emit("edit-faq", item);
    },
    deleteFAQ(id) {
      if (!this.confirmDelete) {
        this.confirmDelete = true;
        setTimeout(() => {
          this.confirmDelete = false;
        }, 2000);
        return;
      }
      this.$emit("delete-faq", id);
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    },
    async init() {
      this.faqItens = this.data.map((item) => {
        if (!item.templateId) return item;
        const template = FaqTemplate.find((t) => t.id === item.templateId);
        return {
          ...item,
          icon: template?.icon || "mdi-help-circle",
        };
      });
      this.shuffleArray(this.faqItens);

      this.fuse = new Fuse(this.faqItens, {
        keys: [
          { name: "question", weight: 0.7 },
          { name: "answer", weight: 0.3 },
        ],
        threshold: 0.6,
      });

      if (this.search) this.performSearch();
    },
    performSearch() {
      if (!this.fuse) return;
      this.results = this.fuse.search(this.search);
    },
  },
  mounted() {
    this.init();
  },
  computed: {
    faqFiltered() {
      if (!this.search) {
        if (!this.viewOnly) return this.faqItens;
        return this.faqItens.slice(0, this.itemShowLimit);
      }
      return this.results.map(({ item }) => item);
    },
  },
  watch: {
    search() {
      this.performSearch();
    },
    data: {
      handler() {
        this.init();
      },
      deep: true,
    },
    expanded() {
      this.confirmDelete = false;
    },
  },
};
</script>

<style scoped>
.v-expansion-panel-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>

<template>
  <v-card outlined :loading="loading" v-intersect="onIntersect">
    <v-card-title class="justify-space-between">
      <h6 class="lh-1">Ingressos Reembolsados</h6>
      <v-btn
        x-small
        text
        color="primary"
        v-if="hasPermission(128)"
        @click="goToRefound"
      >
        Ver reembolsos
      </v-btn>
    </v-card-title>
    <v-card-text class="text-center">
      <v-avatar color="secondary lighten-2" size="50">
        <v-icon class="display-1">mdi-ticket-confirmation</v-icon>
      </v-avatar>
      <h4 class="mb-0">
        {{ refundedTicketsCount }} pagamento{{
          refundedTicketsCount == 1 ? "" : "s"
        }}
      </h4>
      <h6>
        {{ refundedTicketsValue | currency }}
        <sup class="font-weight-thin"> {{ refundedTicketsPercentage }}% </sup>
      </h6>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import PAYMENT from "@/utils/payment";

export default {
  data: () => ({
    PAYMENT,
    viewed: false,
    isIntersecting: false,
  }),
  methods: {
    goToRefound() {
      this.$router.push({
        hash: "#refound",
      });
    },
    onIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.isIntersecting = true;
        this.viewed = true;
      } else {
        this.isIntersecting = false;
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["hasPermission"]),

    validPayments() {
      if (!this.viewed) return [];
      return this.data.payments.filter(
        (payment) => !payment.status != "canceled"
      );
    },
    refundedPayments() {
      return this.validPayments.filter(
        (payment) => payment.status == "refunded"
      );
    },
    refundedTicketsCount() {
      return this.refundedPayments.length;
    },
    refundedTicketsValue() {
      return this.refundedPayments.reduce(
        (acc, payment) => acc + payment.amount - (payment.platformFee || 0),
        0
      );
    },
    validTicketsValue() {
      return this.validPayments.reduce(
        (acc, payment) => acc + payment.amount - (payment.platformFee || 0),
        0
      );
    },
    refundedTicketsPercentage() {
      return ((this.refundedTicketsValue / this.validTicketsValue || 1) * 100)
        .toFixed(1)
        .replace(/[.,]0$/, "");
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style></style>

<template>
  <v-dialog v-model="dialog" max-width="500px" overlay-opacity="0.9" @click:outside="close" :persistent="loading">
    <v-card v-if="!!payment && !succeeded">
      <v-card-title class="justify-center">
        <b>
          {{ operatationType === "refund" ? "Reembolsar" : "Cancelar" }}
          Pagamento
        </b>
      </v-card-title>
      <v-card-text class="pb-2">
        <v-card class="pa-4 rounded-xl d-flex mb-3 justify-space-between align-center" outlined>
          <div class="d-flex flex-column">
            <h4 class="mb-0">
              {{ payment.amount + payment.installmentsFee | currency(true) }}
            </h4>
            <small class="lh-1">
              {{ payment.amount - payment.clientFee | currency(true) }} valor do ingresso
            </small>
            <small class="lh-1">
              +{{ payment.clientFee | currency(true) }} taxa de serviço
            </small>
            <small v-if="payment.installmentsFee" class="lh-1">
              + {{ payment.installmentsFee | currency(true) }} taxa de
              parcelamento
            </small>
          </div>
          <div>
            <p class="mb-1 text-end font-weight-medium lh-1">
              {{ payment?.Ticket.length }} ingresso{{
                payment?.Ticket?.length > 1 ? "s" : ""
              }}
            </p>
            <p class="mb-0 text-end text--secondary font-weight-bold lh-1">
              {{ PAYMENT.paymentMethod[payment.paymentMethod].text }} •
              {{ PAYMENT.paymentType[payment.paymentType].text }}
              <small v-if="payment.installments && payment.installments > 1"> 
                {{ payment.installments }}x
              </small>
            </p>
          </div>
        </v-card>

        <v-form v-model="valid" ref="transferForm" class="mb-1" @submit="refund">
          <template v-if="operatationType === 'refund'">
            <h6 class="text-center text-14 mb-1">Reembolso</h6>
            <v-btn-toggle v-model="type" mandatory width="100%" class="d-flex mb-4">
              <v-btn text style="width: 50%">
                Total •
                {{
                  (payment.amount + payment.installmentsFee) | currency(true)
                }}
              </v-btn>
              <v-btn text style="width: 50%"> Parcial </v-btn>
            </v-btn-toggle>
            <v-scroll-y-transition leave-absolute>
              <v-text-field v-if="type === 1" prefix="R$" v-model="amount" label="Valor" type="number" step="0.01"
                min="0" :max="payment.amount + payment.installmentsFee" required outlined :rules="[
                  (v) => !!v || 'Valor é obrigatório',
                  (v) =>
                    v <= payment.amount ||
                    'Valor não pode ser maior que o valor do ingresso',
                  (v) => v > 0 || 'Valor deve ser maior que 0',
                ]"></v-text-field>
            </v-scroll-y-transition>
          </template>
          <v-alert type="info" text border="left">
            <b>Atenção:</b> após
            {{ operatationType === "refund" ? "reembolsar" : "cancelar" }}
            um ingresso não será possível utilizar, transferir ou reembolsar
            novamente.
          </v-alert>
          <v-alert v-if="warningMessage" type="warning" class="mb-0" border="left" text>
            {{ warningMessage[1] }}
          </v-alert>
          <v-alert type="error" v-if="error" class="mt-2 mb-0" border="left">
            {{ error }}
          </v-alert>

          <v-checkbox v-model="confirm" :label="operatationType === 'cancel'
            ? 'Desjo cancelar esse pagamento'
            : warningMessage[0]
              ? 'Desjo realizalizar o reembolso para cliente'
              : 'Já que realizei o reembolso ao cliente'
            " required :rules="[(v) => !!v || 'Confirmação é obrigatória']"></v-checkbox>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex flex-column">
        <div class="w-full d-flex gap-2">
          <v-btn v-if="config?.opMode" color="primary" @click="toogleType" :disabled="loading" class="mb-2"
            min-width="30px">
            <v-icon small>
              mdi-{{ operatationType === "refund" ? "cancel" : "cash-refund" }}
            </v-icon>
          </v-btn>
          <v-btn color="primary" @click="refund" :disabled="!valid" :loading="loading" class="mb-2 flex-grow-1">
            <v-icon small left>mdi-cash-refund</v-icon>
            {{ operatationType === "refund" ? "Reembolsar" : "Cancelar" }} •
            {{
              type ? amount || (payment.amount + payment.installmentsFee) : (payment.amount + payment.installmentsFee) |
                currency(true)
            }}
          </v-btn>
        </div>
        <v-btn color="primary" text @click="dialog = false" :disabled="loading" block class="ml-0">
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="succeeded">
      <v-card-text>
        <div class="text-center pt-8">
          <v-avatar class="mb-4" size="60" color="success lighten-4">
            <v-icon x-large color="success darken-2">mdi-check</v-icon>
          </v-avatar>
          <h6>{{ result.message }}</h6>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex flex-column">
        <v-btn color="primary" text @click="close(true)" block class="ml-0">
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TICKET from "@/services/admin/ticket";
import PAYMENT from "@/utils/payment";

import { mapGetters } from "vuex";
export default {
  data: () => ({
    operatationType: "refund",
    dialog: false,
    payment: null,
    loading: false,
    valid: false,
    confirm: false,

    error: false,
    succeeded: false,
    result: null,
    type: 0,
    amount: 0,
    PAYMENT,
  }),
  props: {
    party: {
      type: Object | null,
      required: true,
    },
  },
  methods: {
    async refund() {
      try {
        this.loading = true;
        this.error = false;

        if (this.operatationType === "cancel") {
          const confirm = window.confirm(
            "Ao cancelar o pagamento, ele não aparecerá no relatorio ou como reembolsado, use essa opção apenas para pagamentos duplicados ou indevidos. Deseja continuar?"
          );
          if (!confirm) return;
        }

        const response = await TICKET.refund(
          this.selectedOrganization.id,
          this.party.id,
          this.payment.id,
          {
            amount: this.type ? parseFloat(this.amount) : this.payment.amount + this.payment.installmentsFee,
            operation: this.operatationType,
          }
        );
        this.result = response;
        this.succeeded = true;
        this.loading = false;
        this.$emit("success");
        this.$root.$emit("refund-ticket:success");
      } catch (error) {
        this.error = error.message || "Erro ao transferir ingresso";
      } finally {
        this.confirm = false;
        this.loading = false;
      }
    },
    open(payment) {
      this.payment = payment;
      this.null = null;
      this.confirm = false;
      this.succeeded = false;
      this.error = false;
      this.type = 0;
      this.operatationType = "refund";
      this.amount = payment.amount;
      this.dialog = true;
    },
    close() {
      if (this.loading) return;
      this.dialog = false;
    },
    toogleType() {
      this.operatationType =
        this.operatationType === "refund" ? "cancel" : "refund";
    },
  },
  computed: {
    ...mapGetters("auth", ["config"]),
    ...mapGetters("organization", ["selectedOrganization"]),
    warningMessage() {
      if (this.payment.paymentMethod === "POS") {
        if (this.payment.paymentType === "MONEY")
          return [
            false,
            "O pagamento foi realizado em dinheiro em um POS, certifique-se de realizar o reembolso ao cliente antes de finalizar a operação.",
          ];
        return [
          false,
          "O pagamento foi realizado via POS, certifique-se de realizar o reembolso junto a adquirente antes de finalizar a operação.",
        ];
      }
      if (
        this.payment.paymentMethod === "OFFLINE" &&
        this.payment.paymentType === "MONEY"
      )
        return [
          false,
          `O pagamento foi realizado em dinheiro com o promoter ${this.payment.Ticket[0].Seller?.name}, certifique-se de realizar o reembolso ao cliente antes de finalizar a operação.`,
        ];

      if (this.payment.paymentType === "CREDIT_CARD")
        return [
          true,
          `O pagamento foi realizado via cartão de crédito, o reembolso será realizado diretamente no cartão do cliente em até 2 faturas.`,
        ];
      if (this.payment.paymentType === "PIX")
        return [
          true,
          `O pagamento foi realizado via PIX, o reembolso será realizado diretamente na conta do cliente em até 1 hora.`,
        ];

      return [
        false,
        `O pagamento foi realizado via ${this.payment.paymentType}, certifique-se de realizar o reembolso ao cliente antes de finalizar a operação.`,
      ];
    },
  },
  mounted() {
    this.$root.$on("refund-ticket", this.open);
  },
};
</script>

<style></style>

<template>
  <div class="banner-management">
    <v-form @submit.prevent="saveBanner(banner)" ref="form">
      <div class="d-flex flex-column gap-4">
        <div class="d-flex flex-column gap-2 align-center">
          <v-img
            v-if="banner.imageUrl || banner.image"
            :src="banner.imageUrl"
            :aspect-ratio="aspectRatio"
            class="rounded"
            width="100%"
            style="max-height: 600px !important"
          ></v-img>
          <div
            v-else
            @click="openFileInput"
            class="banner-placeholder rounded d-flex flex-column justify-center align-center"
            :style="{
              aspectRatio: aspectRatio,
              backgroundColor: '#E0E0E0',
              width: '100%',
              cursor: 'pointer',
            }"
            style="max-height: 600px !important"
          >
            <div class="d-flex flex-column align-center">
              <p class="mb-0 text-13 font-weight-medium">
                Clique para selecionar uma imagem
              </p>
              <small class="text-caption text--secondary mb-0">
                Formato recomendado: proporção
                {{ sizeDescriptions.aspectRatio }}
              </small>
              <p class="text-caption text--secondary mb-0">
                e mínimo de {{ sizeDescriptions.size }}.
              </p>
            </div>
          </div>
          <v-btn
            v-if="banner.imageUrl || banner.image"
            small
            text
            color="primary"
            @click="openFileInput"
          >
            {{
              banner.imageUrl || banner.image
                ? "Alterar Imagem"
                : "Adicionar Imagem"
            }}
          </v-btn>
        </div>

        <v-file-input
          v-show="false"
          v-model="banner.image"
          label="Imagem do Banner"
          outlined
          hide-details
          dense
          accept="image/*"
          @change="onImageSelected"
          ref="fileInput"
        ></v-file-input>

        <v-text-field
          v-model="banner.link"
          label="Link"
          outlined
          hide-details="auto"
          dense
          clearable
          :rules="[linkRule]"
        ></v-text-field>
        <div class="d-flex gap-7">
          <v-text-field
            v-model="banner.startDate"
            label="Data de Início"
            outlined
            dense
            hide-details
            type="datetime-local"
            clearable
            :rules="dateRules.start"
            ref="startDate"
          ></v-text-field>

          <v-text-field
            v-model="banner.endDate"
            label="Data de Fim"
            outlined
            dense
            type="datetime-local"
            hide-details
            clearable
            :rules="dateRules.end"
            ref="endDate"
          ></v-text-field>
        </div>
        <v-switch v-model="banner.active" label="Ativo"></v-switch>
        <v-alert v-if="error" type="error">{{ error }}</v-alert>
        <div class="d-flex justify-end">
          <v-btn
            type="submit"
            color="primary"
            :disabled="!isFormValid || (!banner.image && !banner.imageUrl)"
            :loading="loading"
          >
            Salvar
          </v-btn>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import PARTY from "@/services/admin/party";

export default {
  name: "BannerManagement",
  data() {
    return {
      loading: false,
      error: null,
      banner: {
        ...this.value,
      },
      valid: false,
      disableRules: false,
      isFormValid: true,
      linkRule: (v) => {
        if (!v) return true;
        const urlPattern =
          /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
        return urlPattern.test(v) || "Por favor, insira uma URL válida";
      },
    };
  },
  watch: {
    value: {
      handler(val) {
        this.banner = { ...val };
      },
      immediate: true,
    },
    valid(val) {
      this.$emit("valid", val);
    },
    "banner.startDate"(val) {
      this.$refs.endDate.validate();
    },
    "banner.endDate"(val) {
      this.$refs.startDate.validate();
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    dateRules() {
      const rules = {
        start: [
          (v) => !!v || "Data de início é obrigatória",
          (v) => new Date(v).getFullYear() < 3000 || "Data de início inválida",
          (v) =>
            !this.banner.endDate ||
            v < this.banner.endDate ||
            "Data de início deve ser menor que a data de fim",
        ],
        end: [
          (v) => !!v || "Data de fim é obrigatória",
          (v) => new Date(v).getFullYear() < 3000 || "Data de fim inválida",
          (v) =>
            !this.banner.startDate ||
            v > this.banner.startDate ||
            "Data de fim deve ser maior que a data de início",
        ],
      };

      if (this.disableRules) rules[this.disableRules] = [];

      return rules;
    },
  },
  methods: {
    async saveBanner(banner) {
      try {
        this.loading = true;
        this.error = null;
        const orgId = this.selectedOrganization.id;
        const partyId = this.$route.params.partyId;

        const formattedBanner = {
          ...banner,
          position: this.position,
          startDate: banner.startDate
            ? moment(banner.startDate, "YYYY-MM-DDTHH:mm").toISOString()
            : null,
          endDate: banner.endDate
            ? moment(banner.endDate, "YYYY-MM-DDTHH:mm").toISOString()
            : null,
        };

        let savedBanner;
        if (banner.id) {
          savedBanner = await PARTY.updateBanner(
            orgId,
            partyId,
            banner.id,
            formattedBanner
          );
        } else {
          savedBanner = await PARTY.saveBanner(orgId, partyId, formattedBanner);
        }

        if (banner.image) {
          await this.uploadBannerImage(savedBanner.id, banner.image);
        }

        // const index = this.banners.findIndex(
        //   (b) => b.position === banner.position
        // );
        // if (index !== -1) {
        //   this.banners[index] = { ...this.banners[index], ...savedBanner };
        // }
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
    async uploadBannerImage(bannerId, image) {
      try {
        this.loading = true;
        this.error = null;
        const orgId = this.selectedOrganization.id;
        const partyId = this.$route.params.partyId;

        const formData = new FormData();
        formData.append("file", image);

        await PARTY.uploadImage(orgId, partyId, bannerId, formData);
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },

    openFileInput() {
      this.$refs.fileInput.$el.querySelector("input").click();
    },

    onImageSelected() {
      if (this.banner.image) {
        this.banner.imageUrl = URL.createObjectURL(this.banner.image);
      }
    },
    removeImage(index) {
      this.banners[index].imageUrl = "";
      this.banners[index].image = null;
    },
    cleanImageUrl(url) {
      return url;
    },
    validateForm() {
      this.$refs.form.validate();
      this.isFormValid =
        this.$refs.form.validate() &&
        (!this.banner.startDate ||
          !this.banner.endDate ||
          this.banner.startDate < this.banner.endDate);
    },
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    position: {
      type: String,
      required: true,
    },
    aspectRatio: {
      type: Number,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    sizeDescriptions: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.banner-management {
}
</style>

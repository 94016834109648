<template>
  <v-dialog
    v-model="isOpen"
    width="700"
    persistent
    scrollable
    :fullscreen="$vuetify.breakpoint.smAndDown"
    content-class="rounded-lg"
  >
    <v-card class="h-full d-flex flex-column" style="max-height: 90dvh">
      <v-card-text class="ma-0 pa-6">
        <v-card
          class="d-flex gap-4 align-center pa-2 mb-4"
          outlined
          rounded="lg"
        >
          <v-img
            v-if="device?.Model?.image"
            :src="
              (device?.acquirer &&
                device?.Model?.imageVariants?.[device.acquirer]) ||
              device?.Model?.image
            "
            max-height="55"
            max-width="55"
          />
          <v-icon v-else color="primary" size="55"> mdi-cash-register </v-icon>
          <div class="flex-grow-1">
            <h6 class="mb-0">{{ address?.name }}</h6>
            <span class="text-12 lh-1">SN: {{ device?.serialNumber }}</span>
          </div>
        </v-card>
        <v-text-field
          v-if="address"
          v-model="address.name"
          label="Nome"
          outlined
          dense
          class="mt-4"
          :rules="[
            (v) => !!v || 'Name é obrigatório',
            (v) =>
              (v && v.length <= 30) || 'Name deve ter menos de 30 caracteres',
          ]"
          :disabled="!address"
        />

        <h6 class="mb-0">Formas de pagamento</h6>
        <div class="d-flex flex-wrap gap-x-4 mb-4">
          <v-checkbox
            v-model="allowPaymentMethods"
            label="Dinheiro"
            value="money"
            class="d-flex"
            hide-details
          />
          <v-checkbox
            v-model="allowPaymentMethods"
            label="PIX"
            value="pix"
            class="d-flex"
            hide-details
          />
          <v-checkbox
            v-model="allowPaymentMethods"
            label="Cartão de Crédito"
            value="credit"
            class="d-flex"
            hide-details
          />
          <v-checkbox
            v-model="allowPaymentMethods"
            label="Cartão de Débito"
            value="debit"
            class="d-flex"
            hide-details
          />
        </div>

        <v-switch
          v-if="hasPermission(524288)"
          v-model="allowCourtesy"
          :disabled="loading"
          label="Permitir emitir cortesia"
          class="mt-4"
        />

        <div class="mb-3 d-flex justify-space-between align-center">
          <h6 class="mb-0">Operadores</h6>
          <v-btn x-small @click="addUser" class="ml-2" color="primary">
            <v-icon x-small left>mdi-plus</v-icon>
            Operador
          </v-btn>
        </div>
        <v-alert
          v-if="users.length"
          color="info"
          dense
          text
          class=""
        >
          <small>
            Os operadores que ainda não possuem uma conta precisarão criar uma
            para usar o POS.
          </small>
        </v-alert>

        <p class="text-center text-secondary py-2" v-if="!users.length">
          Ao menos um operador é obrigatório
        </p>

        <div v-for="(user, i) in users" :key="i" class="d-flex">
          <phone-input
            :phone="user.phone"
            @update:phone="(e) => setUsers(i, 'phone', e)"
            :ddi="user.ddi"
            @update:ddi="(e) => setUsers(i, 'ddi', e)"
            @valid="(v) => setUsers(i, 'valid', v)"
            dense
            outlined
            class="flex-grow"
            label="WhatsApp do operador"
            :disabled="loading"
          />

          <v-btn @click="removeUser(i)" class="ml-2" icon>
            <v-icon>mdi-minus</v-icon>
          </v-btn>
        </div>

        <v-switch
          v-model="requireClientRegistration"
          :disabled="loading"
          label="Requerer cadastro do cliente"
          class="mt-2"
          hide-details
        />

        <v-alert v-if="error" type="error" class="mb-0 mt-4">
          {{ error }}
        </v-alert>
      </v-card-text>

      <v-card-actions class="d-flex align-end gap-1 justify-space-between">
        <v-btn text :disabled="loading" @click="close"> Cancelar </v-btn>
        <v-btn
          color="success"
          :disabled="!users.every((u) => u.valid) || !address || !users.length"
          :loading="loading"
          @click="save"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import POS from "@/services/admin/pos";
import PhoneInput from "../../../global/PhoneInput.vue";

export default {
  components: {
    PhoneInput,
  },
  data: () => ({
    isOpen: false,
    loading: false,
    code: null,
    device: null,
    session: null,
    users: [],
    error: false,
    step: 1,
    address: null,
    allowCourtesy: false,
    requireClientRegistration: false,
    allowPaymentMethods: ["money", "pix", "credit", "debit"],
  }),

  methods: {
    open(session) {
      this.isOpen = true;
      this.loading = false;
      this.error = false;
      this.users = session.Users;
      this.allowPaymentMethods = session.allowPaymentMethods || [];
      this.requireClientRegistration = session.requireClientRegistration || false;
      this.session = Object.assign({}, session);
      this.address = Object.assign({}, session.Address);
      this.device = Object.assign({}, session.Device);
      if (session && session.allowPaymentMethods.includes("courtesy")) {
        this.allowCourtesy = true;
        this.allowPaymentMethods = this.allowPaymentMethods.filter(
          (m) => m !== "courtesy"
        );
      }
    },
    close() {
      this.isOpen = false;
      this.loading = false;
      this.error = false;
      this.step = 1;
      this.address = null;
      this.device = null;
      this.session = null;
      this.code = null;
      this.totp = null;
      if (this.interval) clearInterval(this.interval);
      this.interval = null;
      this.users = [{ phone: "", ddi: 55, valid: false }];
      this.allowPaymentMethods = ["money", "pix", "credit", "debit"];
      this.allowCourtesy = false;
    },
    setUsers(index, key, value) {
      this.users[index][key] = value;
    },
    removeUser(index) {
      this.users.splice(index, 1);
    },

    addUser() {
      this.users.push({ phone: "", ddi: 55, valid: false });
    },

    async save() {
      try {
        this.loading = true;
        this.error = false;
        let orgId = this.selectedOrganization.id;

        const allowPaymentMethods = this.allowPaymentMethods;
        if (this.allowCourtesy) allowPaymentMethods.push("courtesy");

        await POS.editPos(orgId, this.session.id, {
          address: this.address,
          users: this.users,
          allowPaymentMethods: allowPaymentMethods,
          requireClientRegistration: this.requireClientRegistration,
        });

        this.$emit("success");
        this.close();
      } catch (e) {
        this.error = e.message;
        this.loading = false;
      }
    },
  },
  filters: {},
  computed: {
    ...mapGetters("auth", ["hasPermission"]),
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  mounted() {
    this.$parent.$on("edit-adopt-pos-modal", this.open);
  },
  props: {},
  created() {},
};
</script>

<style></style>

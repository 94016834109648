<template>
  <div class="d-flex flex-column gap-3">
    <v-data-table
      :headers="headers"
      :items="data?.cashier || []"
      :loading="loading"
      :items-per-page="5"
      sort-by="openAt"
      sort-desc
    >
      <template v-slot:item.id="{ item }"> #{{ item.id | shortId }} </template>
      <template v-slot:item.openAt="{ item }">
        {{ item.OpenBy?.name || "N/A" }}
        <small class="d-block">
          {{ item.openAt | date("DD/MM/YYYY HH:mm") }}
        </small>
      </template>
      <template v-slot:item.closeAt="{ item }">
        <template v-if="item.closeAt">
          {{ item.CloseBy?.name || "N/A" }}
          <small class="d-block">
            {{ item.closeAt | date("DD/MM/YYYY HH:mm") }}
          </small>
        </template>
        <template v-else>
          <v-chip color="info" label small>Aberto</v-chip>
        </template>
      </template>
      <template v-slot:item.amountSold="{ item }">
        <span class="text--secondary" v-if="!item.closeAt"> Aberto </span>
        <span v-else>
          {{ item.amountSold | currency }}
        </span>
      </template>
      
      <template v-slot:item.cashoutAmount="{ item }">
        <span class="text--secondary" v-if="!item.closeAt"> Aberto </span>
        <span v-else>
          {{ item.cashoutAmount | currency }}
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import PAYMENT from "@/utils/payment";

export default {
  props: {
    session: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    PAYMENT,
    headers: [
      { text: "#", value: "id" },
      { text: "Abertura", value: "openAt" },
      {
        text: "Fechamento",
        value: "closeAt",
        align: "center",
        sortable: false,
      },
      { text: "Vendas", value: "amountSold" },
      { text: "Sangria", value: "cashoutAmount" },
    ],
  }),
};
</script>

<style>
</style>
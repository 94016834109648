<template>
  <v-autocomplete
    :value="value"
    @input="($event) => $emit('input', $event)"
    :items="items"
    :loading="loading"
    :search-input.sync="search"
    hide-no-data
    hide-selected
    outlined
    label="Organização"
    placeholder="Pesquise pelo nome da organização"
    return-object
    :filter="filter"
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>
          Pesquise pelo nome da organização
        </v-list-item-title>
      </v-list-item>
    </template>
    <template v-slot:selection="{ attr, on, item, selected }">
      <v-chip
        v-bind="attr"
        :input-value="selected"
        color="blue-grey"
        class="white--text"
        v-on="on"
      >
        <base-avatar
          :src="item.photo"
          :seed="item.id"
          :size="10"
          avatarStyle="shapes"
          color="secondary lighten-2"
          class="mr-2"
        />
        <span v-text="item.name"></span>
      </v-chip>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-avatar>
        <base-avatar
          :src="item.photo"
          :seed="item.id"
          :size="35"
          avatarStyle="shapes"
          color="secondary lighten-2"
        />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-text="item.name"></v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import ORGANIZATION from "@/services/admin/organization";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    loading: false,
    items: [],
    search: "",
    timeout: null,
  }),
  methods: {
    filter(item, queryText) {
      const normalize = (str) =>
        str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

      const text = normalize(item.name).toLowerCase();
      const query = normalize(queryText).toLowerCase();
      return text.includes(query);
    },
    async performSearch(query) {
      try {
        if (this.requesting) return;

        this.loading = true;
        this.requesting = true;

        const { organizations } = await ORGANIZATION.grantAccess.search(
          this.selectedOrganization.id,
          query
        );

        this.items = organizations;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
        this.requesting = false;
      }
    },
  },

  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  watch: {
    value(val) {
      if (val?.id && !this.items.length) {
        this.items = [val];
      }
    },
    search(val) {
      if (this.timeout) clearTimeout(this.timeout);
      if (!val || val.length < 4) return;
      this.loading = true;
      this.timeout = setTimeout(() => {
        this.performSearch(val);
      }, 500);
    },
  },

  mounted() {
    if (this.value?.id) this.items = [this.value];
  },
  props: {
    value: {
      type: Object,
    },
  },
};
</script>

<style></style>

import moment from "moment";

export default async function (document, party, title) {
  return new Promise((resolve, reject) => {
    const logo = new Image();
    logo.src = '/img/logo/logo-dark.png'
    // logo.src = '/logo-light.png'
    logo.onload = () => {
      // Determina o aspect ratio original da imagem
      const imageSize = { width: 32, height: 20 };
      const aspectRatio = logo.naturalWidth / logo.naturalHeight;
      let logoWidth = imageSize.width;
      let logoHeight = imageSize.height;

      // Ajusta o tamanho da imagem para manter o aspect ratio sem esticar
      if (aspectRatio > logoWidth / logoHeight) {
        // Se for mais largo do que alto
        logoWidth = Math.min(logoWidth, imageSize.width);
        logoHeight = logoWidth / aspectRatio;
      } else {
        // Se for mais alto do que largo
        logoHeight = Math.min(logoHeight, imageSize.height);
        logoWidth = logoHeight * aspectRatio;
      }

      // Calcula a posição X para centralizar a logo
      const centerX = (imageSize.width - logoWidth) / 2;
      const centerY = (imageSize.height - logoHeight) / 2;

      // Adiciona a logo
      document.addImage(logo.src, 'JPEG', document.margin + centerX, 5 + centerY, logoWidth, logoHeight);

      // Adiciona o título
      document.addTitle(title, 14, { spacing: 3, x: 2 * document.margin + imageSize.width });
      document.addTitle(party.name, 18, { spacing: 2, x: 2 * document.margin + imageSize.width });
      document.addTitle(formatPartyDate(party), 12, { spacing: 3, x: 2 * document.margin + imageSize.width });

      resolve();
    }

    logo.onerror = (error) => {
      reject(error);
    }

  });
}

function formatPartyDate(party) {
  const start = moment(party.date);
  const end = moment(party.endDate);

  const isSameDay = moment(start).isSame(end, 'day');
  if (isSameDay)
    return `${start.format('DD [de] MMM [de] YYYY [das] HH:mm')} às ${end.format('HH:mm')}`;

  const isSameMonth = moment(start).isSame(end, 'month');
  if (isSameMonth)
    return `${start.format('DD [de] MMM [de] YYYY [às] HH:mm')} até dia ${end.format('DD [às] HH:mm')}`;

  return `${start.format('DD [de] MMM [de] YYYY [às] HH:mm')} até ${end.format('DD [de] MMM [de] YYYY [às] HH:mm')}`;
}



<template>
  <div class="pt-2">
    <v-progress-linear indeterminate v-if="loading" />
    <v-alert v-if="error" type="error" class="mb-0">
      <v-row align="center">
        <v-col class="grow py-0">
          {{ error }}
        </v-col>
        <v-col class="shrink py-0">
          <v-btn small @click="getData" :loading="loading">
            Tentar novamente
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <!-- <div class="d-flex justify-space-between align-center" v-if="lastUpdate">
      <v-spacer />
      <v-btn
        icon
        small
        @click="getData"
        :loading="loading"
        :disabled="blockRefresh"
      >
        <v-icon small>mdi-refresh</v-icon>
      </v-btn>
      <small class="text-caption">
        Última atualização: {{ lastUpdate | date("DD/MM [às] HH:mm") }}
      </small>
    </div> -->
    <v-card
      class="pa-2 d-flex align-center rounded-xl px-4"
      outlined
      v-if="formattedDateRange"
    >
      <span>{{ formattedDateRange.startDate }}</span>
      <v-divider class="ml-4" />
      <span>até</span>
      <v-divider class="mr-4" />
      <span> {{ formattedDateRange.endDate }} </span>
    </v-card>

    <template v-if="!!data">
      <AnalyticsHighlights class="mx-2 mt-3" :data="data" />
      <AnalyticsViewsPerPagePath class="mx-2 mt-3" :data="data" />
      <cols-organizer
        md="2"
        xl="3"
        :items="reportComponents"
        class="mt-3"
        v-if="data"
      >
        <template v-slot="{ item }">
          <component
            :is="item.component"
            :data="data"
            :date-range="dateRange"
            :loading="loading"
          />
        </template>
      </cols-organizer>
    </template>
  </div>
</template>

<script>
import moment from "moment";
moment.locale("pt-br");

import PARTY from "@/services/admin/party";
import ColsOrganizer from "../../global/ColsOrganizer.vue";
import AnalyticsHighlights from "./analytics/AnalyticsHighlights.vue";
import AnalyticsViewsPerPagePath from "./analytics/AnalyticsViewsPerPagePath.vue";
import AnalyticsByCity from "./analytics/AnalyticsByCity.vue";
import AnalyticsUsersByTime from "./analytics/AnalyticsUsersByTime.vue";
import AnalyticsSessionSource from "./analytics/AnalyticsSessionSource.vue";
import AnalyticsOperacionalSystem from "./analytics/AnalyticsOperatingSystem.vue";
export default {
  components: {
    ColsOrganizer,
    AnalyticsByCity,
    AnalyticsUsersByTime,
    AnalyticsHighlights,
    AnalyticsViewsPerPagePath,
    AnalyticsOperacionalSystem,
    AnalyticsSessionSource,
  },
  data: () => ({
    loading: false,
    error: false,
    data: null,
    dateRange: null,
    lastUpdate: null,
    blockRefresh: false,
    reportComponents: [
      { component: "AnalyticsUsersByTime" },
      { component: "AnalyticsByCity" },
      { component: "AnalyticsOperacionalSystem" },
      { component: "AnalyticsSessionSource" },
    ],
  }),
  methods: {
    async getData() {
      try {
        if (this.blockRefresh || this.loading) return;
        this.loading = true;
        this.error = false;
        const { data, dateRange } = await PARTY.reports.analytics(
          this.party.organizationId,
          this.party.id
        );
        this.data = data;
        this.dateRange = dateRange;
        this.lastUpdate = new Date();

        this.blockRefresh = true;
        setTimeout(() => {
          this.blockRefresh = false;
        }, 1000 * 60 * 5);
      } catch (e) {
        this.error = e.message || "Erro ao carregar dados";
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    formattedDateRange() {
      if (!this.dateRange) return null;
      const regexDaysAgo = /([0-9]{1,2})daysAgo/;
      const regexIsADate = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;

      const response = {
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
      };

      if (!regexIsADate.test(response.startDate)) {
        const daysAgo = regexDaysAgo.exec(response.startDate)[1];
        response.startDate = `${daysAgo} dia${daysAgo == 1 ? "" : "s"} atrás`;
      } else {
        response.startDate = moment(response.startDate).format("DD [de] MMM");
      }
      if (!regexIsADate.test(response.endDate)) {
        response.endDate = response.endDate === "yesterday" ? "Ontem" : "Hoje";
      } else {
        response.endDate = moment(response.endDate).format(
          "DD [de] MMM [de] YYYY"
        );
      }

      return response;
    },
  },
  mounted() {
    this.getData();
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>

<template>
  <div class="d-flex flex-column gap-3">
    <v-card outlined :loading="loading">
      <p class="mb-0 mx-3 mt-2 font-weight-medium">Vendas</p>
      <v-card-text class="d-flex flex-wrap justify-space-around px-">
        <div class="text-center px-4">
          <h6 class="mt-2 mb-0">
            {{ reducedData.payments.tickets }}
          </h6>
          <span class="text-caption">Ingressos</span>
        </div>
        <div class="text-center px-4">
          <h6 class="mt-2 mb-0">
            {{ reducedData.payments.total }}
          </h6>
          <span class="text-caption">Pagamento</span>
        </div>
        <div class="text-center px-4">
          <h6 class="mt-2 mb-0">
            {{ reducedData.payments.amount | currency }}
          </h6>
          <span class="text-caption">Vendas</span>
        </div>
        <div class="text-center px-4">
          <h6 class="mt-2 mb-0">
            {{ reducedData.payments.money | currency }}
          </h6>
          <span class="text-caption">Dinheiro</span>
        </div>
      </v-card-text>
    </v-card>
    <v-card outlined :loading="loading">
      <p class="mb-0 mx-3 mt-2 font-weight-medium">Sangrias</p>
      <v-card-text class="d-flex flex-wrap justify-space-around px-">
        <div class="text-center px-4">
          <h6 class="mt-2 mb-0">
            {{ reducedData.cashouts.total }}
          </h6>
          <span class="text-caption">Sangrias</span>
        </div>
        <div class="text-center px-4">
          <h6 class="mt-2 mb-0">
            {{ reducedData.cashouts.amount | currency }}
          </h6>
          <span class="text-caption">Valor</span>
        </div>
        <div class="text-center px-4">
          <h6 class="mt-2 mb-0">
            {{
              (reducedData.payments.money - reducedData.cashouts.amount)
                | currency
            }}
          </h6>
          <span class="text-caption">Saldo</span>
        </div>
      </v-card-text>
    </v-card>
    <v-card outlined :loading="loading">
      <p class="mb-0 mx-3 mt-2 font-weight-medium">Dispositivo</p>
      <v-card-text class="d-flex flex-wrap justify-space-around px-">
        <div class="text-center px-4">
          <h6 class="mt-2 mb-0 text-13 lh-1">
            {{ data?.device?.serialNumber || "N/A" }}
          </h6>
          <span class="text-caption"> Serial </span>
        </div>
        <div class="text-center px-4">
          <h6 class="mt-2 mb-0 text-13 lh-1">
            {{ data?.device?.mac || "N/A" }}
          </h6>
          <span class="text-caption"> MAC </span>
        </div>
        <div class="text-center px-4">
          <h6 class="mt-2 mb-0 text-13 lh-1">
            {{ data?.device?.brand }}
            {{ data?.device?.model || "N/A" }}
          </h6>
          <span class="text-caption"> Modelo </span>
        </div>
        <div class="text-center px-4">
          <h6 class="mt-2 mb-0 text-13 lh-1">
            {{ (data?.device?.acquirer || "N/A").toUpperCase() }}
          </h6>
          <span class="text-caption"> Adquirente </span>
        </div>
        <div class="text-center px-4">
          <h6 class="mt-2 mb-0 text-13 lh-1">
            {{ data?.device?.hash.slice(0, 12).toUpperCase() }}...
          </h6>
          <span class="text-caption"> Hash </span>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    session: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    reducedData() {
      const response = {
        payments: {
          total: 0,
          tickets: 0,
          amount: 0,
          money: 0,
        },
        cashouts: {
          total: 0,
          amount: 0,
          money: 0,
        },
      };
      if (!this.data) return response;

      this.data.payments.forEach((p) => {
        if (p.paymentType === "MONEY") response.cashouts.rawMoney += p.amount;
        
        if (p.status !== "succeeded") return;
        response.payments.total += 1;
        response.payments.tickets += p.Ticket.length;
        response.payments.amount += p.amount;
        if (p.paymentType === "MONEY") response.payments.money += p.amount;
      });
      this.data.cashouts.forEach((c) => {
        response.cashouts.total += 1;
        response.cashouts.amount += c.amount;
      });

      return response;
    },
  },
};
</script>

<style>
</style>
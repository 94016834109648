import http from "@/http-common";

const create = async (orgId, partyId, data) => {
  return await http
    .post(`/admin/organization/${orgId}/party/${partyId}/faq`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const update = async (orgId, partyId, { id, ...data }) => {
  return await http
    .put(`/admin/organization/${orgId}/party/${partyId}/faq/${id}`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const getAll = async (orgId, partyId) => {
  return await http
    .get(`/admin/organization/${orgId}/party/${partyId}/faq`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const destroy = async (orgId, partyId, id) => {
  return await http
    .delete(`/admin/organization/${orgId}/party/${partyId}/faq/${id}`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

export default {
  create,
  update,
  getAll,
  destroy,
};

<template>
  <v-card outlined :loading="loading">
    <v-card-title class="justify-space-between">
      <h6 class="mb-0 lh-1">Assentos</h6>
      <v-btn
        icon
        @click="() => $refs.modal.open()"
        :disabled="loading || !Object.keys(gruppedByGroup).length"
        small
      >
        <v-icon small>mdi-arrow-expand</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :items="Object.values(gruppedByGroup)"
        :items-per-page="gruppedByGroup.length"
        hide-default-footer
        :headers="[
          { text: 'Grupo', value: 'name' },
          { text: 'Total', value: 'ticketsSummary.amount', align: 'center' },
        ]"
      >
        <template v-slot:item.name="{ item }">
          <v-avatar :color="item.color" class="mr-2" size="12" />
          {{ item.name }}
        </template>
        <template v-slot:item.ticketsSummary.amount="{ item }">
          {{ item.ticketsSummary.count }} Ingresso{{
            item.ticketsSummary.count != 1 ? "s" : ""
          }}
          <span class="d-block font-weight-bold">
            {{ item.ticketsSummary.amount | currency }}
          </span>
        </template>
      </v-data-table>
    </v-card-text>
    <table-sales-modal
      ref="modal"
      :data="data"
      :loading="loading"
      :groups="gruppedByGroup"
    />
  </v-card>
</template>

<script>
import TableSalesModal from "./modals/TableSalesModal.vue";
export default {
  components: { TableSalesModal },
  data: () => ({}),
  computed: {
    filteredTickets() {
      return this.data.tickets.filter(
        (ticket) => ticket.Payment.status === "succeeded" && ticket.Table
      );
    },
    gruppedByGroup() {
      const groups = this.filteredTickets.reduce((acc, ticket) => {
        if (!acc[ticket.Table.Group.id])
          acc[ticket.Table.Group.id] = {
            ...ticket.Table.Group,
            tickets: [],
          };
        acc[ticket.Table.Group.id].tickets.push(ticket);
        return acc;
      }, {});

      const objEntries = Object.entries(groups);
      return Object.fromEntries(
        objEntries.map(([id, group]) => [
          id,
          {
            ...group,
            ticketsSummary: group.tickets.reduce(
              (acc, t) => {
                acc.amount += t.amount - t.platformFee;
                acc.count += 1;
                return acc;
              },
              { amount: 0, count: 0 }
            ),
          },
        ])
      );
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style></style>

<template>
  <v-dialog v-model="dialog" persistent width="350">
    <v-card class="h-full d-flex flex-column" style="max-height: 90dvh">
      <v-card-text class="pt-4" v-if="!!ticketOffice">
        <div
          v-if="loading && !device"
          style="height: 400px"
          class="d-flex justify-center align-center"
        >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <div v-else-if="device">
          <v-card
            class="d-flex gap-4 align-center pa-2 mb-4"
            outlined
            rounded="lg"
          >
            <v-img
              v-if="device?.Model?.image"
              :src="
                (device?.acquirer &&
                  device?.Model?.imageVariants?.[device.acquirer]) ||
                device?.Model?.image
              "
              max-height="55"
              max-width="55"
            />
            <v-icon v-else color="primary" size="55">
              mdi-cash-register
            </v-icon>
            <div>
              <h6 class="mb-0">POS {{ device?.Model?.brand }}</h6>
              SN: {{ device?.serialNumber }}
            </div>
          </v-card>

          <v-text-field
            v-if="defaultName"
            v-model="ticketOffice.name"
            label="Nome da Bilheteria"
            outlined
            hide-details
            dense
          ></v-text-field>
        </div>

        <v-alert v-if="error" type="error" class="mt-2 mb-0">
          {{ error }}
        </v-alert>
      </v-card-text>

      <v-card-actions v-if="device || error" class="d-flex justify-center">
        <template v-if="defaultName && !error">
          <v-btn @click="close" :disabled="loading" text> Cancelar </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="save"
            :loading="loading"
            :disabled="!ticketOffice?.name || loading"
          >
            Conectar
          </v-btn>
        </template>
        <template v-else>
          <v-btn @click="close" block :disabled="loading" text> Fechar </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import SELLER from "@/services/admin/party/seller";
import POS from "@/services/admin/pos";

export default {
  data: () => ({
    dialog: false,
    error: false,
    loading: false,
    ticketOffice: {},
    code: null,
    device: null,
    defaultName: false,
  }),

  methods: {
    async save() {
      try {
        this.loading = true;
        this.error = false;
        let orgId = this.party.organizationId;

        const { device } = await POS.adopt.adopt(orgId, {
          partyId: this.party.id,
          code: this.code,
          name: this.ticketOffice.name,
          allowPaymentMethods: this.ticketOffice.allowPaymentMethods,
        });

        await SELLER.update(
          this.party.organizationId,
          this.party.id,
          device.id,
          {
            ticketBlocks: this.ticketOffice.ticketBlocks,
            type: "pos",
          }
        );
        this.close();
      } catch (e) {
        this.error = e.message;
      } finally {
        this.loading = false;
      }
    },
    async verifyCode(code) {
      try {
        this.loading = true;
        this.error = false;
        const { device } = await POS.adopt.verifyCode(
          this.selectedOrganization.id,
          code
        );

        this.code = code;
        this.device = device;

        if (!this.defaultName) this.save();
      } catch (e) {
        this.error = e.message;
      } finally {
        this.loading = false;
      }
    },
    open({ code, ticketOffice }) {
      this.loading = false;
      this.error = false;
      this.ticketOffice = ticketOffice;
      this.code = code;
      this.device = null;
      this.defaultName = !ticketOffice.name;
      this.dialog = true;

      this.verifyCode(code);
    },
    close() {
      this.dialog = false;
      this.loading = false;
      this.error = false;
      this.ticketOffice = {};
      this.code = null;
      this.$emit("success");
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  mounted() {
    this.$root.$on("ticket-office-adopt-modal", this.open);
  },
  watch: {},
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>

<template>
  <v-dialog v-model="dialog" max-width="800px" scrollable>
    <v-card>
      <v-card-title>
        <v-icon left>mdi-cash</v-icon>
        Sangrias disponíveis
      </v-card-title>
      <v-card-text>
        <pos-cashout-reports :partyId="party.id" />
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn text @click="close">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PosCashoutReports from "../../pos/PosCashoutReports.vue";
export default {
  components: { PosCashoutReports },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    opened: false,
  }),
  methods: {
    open() {
      this.dialog = true;
      this.opened = true;
    },
    close() {
      this.dialog = false;
    },
  },
  mounted() {
    this.$root.$on("cashout-pos", this.open);
  },
};
</script>

<style>
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"600","scrollable":"","persistent":_vm.loading,"eager":""},on:{"click:outside":_vm.close},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.period?.id ? `Editar período ${_vm.period.name || _vm.period.index + 1}` : "Criar período")+" ")]),_c('v-card-text',{staticClass:"pt-1"},[(_vm.period)?_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"label":"Nome","outlined":"","dense":"","counter":"25"},model:{value:(_vm.period.name),callback:function ($$v) {_vm.$set(_vm.period, "name", $$v)},expression:"period.name"}}),_c('v-textarea',{attrs:{"label":"Descrição","outlined":"","dense":"","rows":"3","counter":"155"},model:{value:(_vm.period.description),callback:function ($$v) {_vm.$set(_vm.period, "description", $$v)},expression:"period.description"}}),_c('div',{staticClass:"d-flex flex-wrap gap-2"},[_c('v-text-field',{attrs:{"type":"datetime-local","label":"Data inicial","outlined":"","dense":"","disabled":!_vm.party.Period.length || _vm.period.index === 0,"messages":!_vm.party.Period.length || _vm.period.index === 0
                ? [
                    'A data do primeiro período obrigatoriamente é a data inicial do evento',
                  ]
                : '',"min":_vm.moment(_vm.party.date).format('YYYY-MM-DDTHH:mm'),"max":_vm.moment(_vm.party.endDate).format('YYYY-MM-DDTHH:mm'),"rules":[
              (v) => !!v || 'Data inicial é obrigatória',
              ..._vm.dateRules(),
            ]},model:{value:(_vm.period.startDate),callback:function ($$v) {_vm.$set(_vm.period, "startDate", $$v)},expression:"period.startDate"}}),_c('v-text-field',{attrs:{"type":"datetime-local","label":"Data final","outlined":"","dense":"","min":_vm.moment(_vm.party.date).format('YYYY-MM-DDTHH:mm'),"max":_vm.moment(_vm.party.endDate).format('YYYY-MM-DDTHH:mm'),"rules":[
              (v) => !!v || 'Data final é obrigatória',
              (v) =>
                _vm.moment(v).isAfter(_vm.period.startDate) ||
                'Data final deve ser posterior a data inicial',
              ..._vm.dateRules(),
            ]},model:{value:(_vm.period.endDate),callback:function ($$v) {_vm.$set(_vm.period, "endDate", $$v)},expression:"period.endDate"}})],1),(_vm.ticketGroups)?_c('v-autocomplete',{attrs:{"items":_vm.ticketGroups,"item-text":"name","item-value":"id","label":"Setores com acesso ao período","multiple":"","outlined":"","chips":"","deletable-chips":"","small-chips":"","search-input":_vm.search,"rules":[(v) => !!v.length || 'Selecione ao menos um grupo']},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},model:{value:(_vm.period.TicketGroup),callback:function ($$v) {_vm.$set(_vm.period, "TicketGroup", $$v)},expression:"period.TicketGroup"}}):_c('v-alert',{staticClass:"mt-2",attrs:{"type":"info"}},[_vm._v(" Você nâo tem permissão para editar os setores deste evento. ")])],1):_vm._e(),(_vm.error)?_c('v-alert',{staticClass:"mt-2",attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()],1),_c('v-card-actions',[_c('v-btn',{staticClass:"ml-1",attrs:{"text":"","disabled":_vm.loading},on:{"click":_vm.close}},[_vm._v(" Fechar ")]),_c('v-spacer'),_c('v-btn',{staticClass:"ml-1",attrs:{"color":"primary","loading":_vm.loading,"disabled":!_vm.valid},on:{"click":_vm.save}},[_vm._v(" Salvar ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import http from "@/http-common";

const create = async (orgId, partyId, data) => {
  return await http
    .post(`/admin/organization/${orgId}/party/${partyId}/courtesy-promotional`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const getAll = async (orgId, partyId) => {
  return await http
    .get(`/admin/organization/${orgId}/party/${partyId}/courtesy-promotional`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

export default {
  create,
  getAll,
};

<template>
  <div>
    <div class="d-flex w-full gap-2 align-center mt-2">
      <div class="d-flex w-full justify-space-between align-center">
        <div class="d-flex gap-2">
          <h6 class="mb-0">Configuração de Impressão:</h6>
          <span class="font-weight-bold">{{ party.name }} </span>
        </div>
      </div>
      <!-- <div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-chip small v-bind="attrs" v-on="on">Selecione o setor </v-chip>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in ticketBlocks" :key="index">
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div> -->
    </div>

    <v-container class="px-0">
      <v-row justify="center">
        <v-col cols="12" sm="12" md="6">
          <v-container class="">
            <div class="mb-5">
              <v-switch
                v-model="printOptions.printQrCode"
                class="mb-0"
                hide-details
                label="Mostrar QR Code"
              ></v-switch>
              <v-switch
                v-model="printOptions.printTitle"
                class="mb-0"
                hide-details
                label="Mostrar nome do evento"
              ></v-switch>
            </div>
            <div class="mb-5">
              <p class="font-weight-bold mb-0">Configuração de logo</p>
              <v-btn-toggle
                mandatory
                class="d-flex flex-column"
                v-model="logoToggle"
                style="max-width: 450px"
              >
                <v-btn
                  v-for="(option, index) in logoOptions"
                  :key="index"
                  active-class="primary white--text"
                  class="rounded"
                  small
                  @click="setPrintOptions(option.value)"
                >
                  {{ option.title }}
                </v-btn>
              </v-btn-toggle>
              <!-- <div v-if="logoToggle === 1" class="card p-2">
                <p class="mb-0 font-weight-bold">Logo personalizada</p>
                <file-drop
                  @change="setCustomLogo"
                  accept=".bmp,.jpg,.jpeg"
                  style="max-width: 450px"
                />
              </div> -->
            </div>
            <div class="mb-5">
              <p class="font-weight-bold mb-0">Configuração de detalhes</p>
              <v-btn-toggle
                mandatory
                class="d-flex flex-column"
                v-model="detailsToggle"
                style="max-width: 450px"
              >
                <v-btn
                  v-for="(option, index) in detailsOptions"
                  :key="index"
                  active-class="primary white--text"
                  class="rounded"
                  small
                  @click="setPrintOptions(option.value)"
                >
                  {{ option.title }}
                </v-btn>
              </v-btn-toggle>
            </div>
            <div class="mb-5">
              <p class="font-weight-bold mb-0">Configuração de valor</p>
              <v-btn-toggle
                mandatory
                class="d-flex flex-column"
                v-model="amountToggle"
                style="max-width: 450px"
              >
                <v-btn
                  v-for="(option, index) in amountOptions"
                  :key="index"
                  active-class="primary white--text"
                  class="rounded"
                  small
                  @click="setPrintOptions(option.value)"
                >
                  {{ option.title }}
                </v-btn>
              </v-btn-toggle>
            </div>
            <div class="mb-5">
              <p class="font-weight-bold mb-0">Configuração de Lote e Setor</p>
              <v-btn-toggle
                mandatory
                class="d-flex flex-column"
                v-model="ticketBlockToggle"
                style="max-width: 450px"
              >
                <v-btn
                  small
                  v-for="(option, index) in ticketBlockOptions"
                  :key="index"
                  active-class="primary white--text"
                  @click="setPrintOptions(option.value)"
                >
                  {{ option.title }}
                </v-btn>
              </v-btn-toggle>
            </div>
            <div>
              <p class="font-weight-bold mb-0">Configuração de cliente</p>

              <v-btn-toggle
                mandatory
                class="d-flex flex-column"
                v-model="clientToggle"
                style="max-width: 450px"
              >
                <v-btn
                  v-for="(option, index) in clientOptions"
                  :key="index"
                  active-class="primary white--text"
                  small
                  @click="setPrintOptions(option.value)"
                >
                  {{ option.title }}
                </v-btn>
              </v-btn-toggle>
            </div>
            <v-btn
              @click="savePrintOptions"
              color="success"
              style="max-width: 450px"
              class="w-full mt-8"
              :loading="loading"
              :disabled="loading"
            >
              Salvar
            </v-btn>
          </v-container>
        </v-col>
        <v-col cols="12" sm="12" md="5">
          <print-preview
            :print-options="printOptions"
            :logo="valCustomLogo"
            :party="party"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PrintPreview from "@/components/global/PrintPreview.vue";
import TICKET from "@/services/admin/ticket";
import PRINTOPTIONS from "@/services/admin/party/printOptions";
import FileDrop from "../../global/FileDrop.vue";

const defaultPrintOptions = () => ({
  printLogo: false,
  printPlatformLogo: false,
  printTitle: true,
  printSubTitle: false,
  printDateTime: true,
  printLocation: true,
  printQrCode: true,
  printLot: true,
  printSector: true,
  printFee: true,
  printPrice: true,
  printTotalAmount: false,
  printOwnerBlock: false,
  printOwnerName: false,
  printOwnerDocument: false,
});

export default {
  components: { PrintPreview, FileDrop },
  data() {
    return {
      ticketBlock: null,
      loading: false,
      error: null,
      customLogo: null,
      valCustomLogo: null,
      ticketGroups: 0,
      amountToggle: 0,
      clientToggle: 2,
      detailsToggle: 0,
      logoToggle: 0,
      ticketBlockToggle: [],
      printOptions: defaultPrintOptions(),
      logoOptions: [
        {
          title: "Exibir logo da plataforma",
          value: {
            printPlatformLogo: true,
            printLogo: true,
          },
        },
        // {
        //   title: "Exibir logo personalizada",
        //   value: {
        //     printPlatformLogo: false,
        //     printLogo: true,
        //   },
        // },
        {
          title: "Não exibir logo",
          value: {
            printPlatformLogo: false,
            printLogo: false,
          },
        },
      ],
      detailsOptions: [
        {
          title: "Exibir data e local",
          value: {
            printDateTime: true,
            printLocation: true,
          },
        },
        {
          title: "Exibir apenas local",
          value: {
            printDateTime: false,
            printLocation: true,
          },
        },
        {
          title: "Exibir apenas data",
          value: {
            printDateTime: true,
            printLocation: false,
          },
        },
        {
          title: "Não exibir",
          value: {
            printDateTime: false,
            printLocation: false,
          },
        },
      ],
      amountOptions: [
        {
          title: "Apenas valor do ingresso",
          value: {
            printFee: false,
            printPrice: true,
            printTotalAmount: false,
          },
        },
        {
          title: "Exibir valor com taxa separada",
          value: {
            printFee: true,
            printPrice: true,
            printTotalAmount: false,
          },
        },
        {
          title: "Exibir valor total",
          value: {
            printFee: false,
            printPrice: false,
            printTotalAmount: true,
          },
        },
        {
          title: "Não exibir",
          value: {
            printFee: false,
            printPrice: false,
            printTotalAmount: false,
          },
        },
      ],
      clientOptions: [
        {
          title: "Exibir apenas nome",
          value: {
            printOwnerName: true,
            printOwnerBlock: false,
            printOwnerDocument: false,
          },
        },
        {
          title: "Exibir nome e documento",
          value: {
            printOwnerName: true,
            printOwnerBlock: true,
            printOwnerDocument: true,
          },
        },
        {
          title: "Não exibir cliente identificado",
          value: {
            printOwnerName: false,
            printOwnerBlock: false,
            printOwnerDocument: false,
          },
        },
      ],
      ticketBlockOptions: [
        {
          title: "Exibir lote e setor",
          value: {
            printLot: true,
            printSector: true,
          },
        },
        {
          title: "Exibir apenas setor",
          value: {
            printLot: false,
            printSector: true,
          },
        },
        {
          title: "Não exibir",
          value: {
            printLot: false,
            printSector: false,
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    ticketBlocks() {
      const ticketBlocks = [];
      if (!this.ticketGroups) return [];
      this.ticketGroups
        .filter(({ tableGroupId }) => !tableGroupId)
        .forEach((ticketGroup) => {
          ticketGroup.TicketBlock.forEach((ticketBlock) => {
            ticketBlocks.push({
              text: `${ticketGroup.name} - ${ticketBlock.name}`,
              value: ticketBlock.id,
            });
          });
        });
      return ticketBlocks;
    },
  },
  methods: {
    setPrintOptions(newOptions) {
      Object.assign(this.printOptions, newOptions);
      console.log("Print options updated:", this.printOptions);
    },
    async getTickets() {
      try {
        this.loading = true;
        this.ticketGroups = [];
        const response = await TICKET.getTickets(
          this.selectedOrganization.id,
          this.party.id
        );
        this.ticketGroups = response.ticketGroups;
      } catch (error) {
        console.log(error);
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
    async getPrintOptions() {
      try {
        const response = await PRINTOPTIONS.get(
          this.selectedOrganization.id,
          this.party.id
        );
        this.printOptions = Object.assign(
          defaultPrintOptions(),
          response.printOptions || {}
        );

        this.updateTogglesBasedOnPrintOptions();
      } catch (error) {
        console.log(error);
        this.error = error.message;
      }
    },
    async savePrintOptions() {
      try {
        this.loading = true;
        await PRINTOPTIONS.create(
          this.selectedOrganization.id,
          this.party.id,
          this.printOptions
        );
      } catch (error) {
        console.log(error);
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
    setCustomLogo(file) {
      this.customLogo = file;
      this.valCustomLogo = URL.createObjectURL(file);
    },
    removeCustomLogo() {
      this.customLogo = null;
    },
    updateTogglesBasedOnPrintOptions() {
      const optionsMapping = (options, printOptions) => {
        return options.findIndex((option) =>
          Object.keys(option.value).every(
            (key) => option.value[key] === printOptions[key]
          )
        );
      };

      this.logoToggle = optionsMapping(this.logoOptions, this.printOptions);
      this.detailsToggle = optionsMapping(
        this.detailsOptions,
        this.printOptions
      );
      this.amountToggle = optionsMapping(this.amountOptions, this.printOptions);
      this.clientToggle = optionsMapping(this.clientOptions, this.printOptions);
      this.ticketBlockToggle = optionsMapping(
        this.ticketBlockOptions,
        this.printOptions
      );
    },
  },
  mounted() {
    this.getTickets();
    this.getPrintOptions();
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>

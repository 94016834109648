<template>
  <v-expansion-panels>
    <v-expansion-panel
      v-for="(bannerType, index) in bannerTypesComputed"
      :key="bannerType.position"
    >
      <v-expansion-panel-header>
        <span class="font-weight-bold">{{ bannerType.title }}</span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <banner-management
          :value="bannerType.banner"
          :position="bannerType.position"
          :aspect-ratio="bannerType.aspectRatio"
          :index="index"
          :size-descriptions="bannerType.sizeDescriptions"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import BannerManagement from "@/components/admin/party/detailsComponents/BannerManagement.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import PARTY from "@/services/admin/party";

export default {
  components: {
    BannerManagement,
  },
  data() {
    return {
      bannerTypes: [
        {
          position: "before_tickets",
          title: "Banner Antes dos Ingressos",
          aspectRatio: 8 / 1,
          sizeDescriptions: {
            aspectRatio: "8:1",
            size: "1024x128px",
          },
        },
        {
          position: "after_tickets",
          title: "Banner Depois dos Ingressos",
          aspectRatio: 9 / 2,
          sizeDescriptions: {
            aspectRatio: "9:2",
            size: "1280x284px",
          },
        },
        {
          position: "sidebar",
          title: "Banner da Barra Lateral",
          aspectRatio: 4 / 3,
          sizeDescriptions: {
            aspectRatio: "4:3",
            size: "800x600px",
          },
        },
      ],
      banners: [
        {
          position: "before_tickets",
          imageUrl: "",
          link: "",
          startDate: null,
          endDate: null,
          active: false,
          image: null,
        },
        {
          position: "after_tickets",
          imageUrl: "",
          link: "",
          startDate: null,
          endDate: null,
          active: false,
          image: null,
        },
        {
          position: "sidebar",
          imageUrl: "",
          link: "",
          startDate: null,
          endDate: null,
          active: false,
          image: null,
        },
      ],
    };
  },
  methods: {
    async getBanners() {
      try {
        const orgId = this.$route.params.orgId;
        const partyId = this.$route.params.partyId;
        const banners = await PARTY.getBanners(orgId, partyId);

        this.banners = this.banners.map((banner) => {
          const receivedBanner = banners.find(
            (b) => b.position === banner.position
          );
          if (receivedBanner) {
            return {
              ...banner,
              ...receivedBanner,
              startDate: receivedBanner.startDate
                ? moment(receivedBanner.startDate).format("YYYY-MM-DDTHH:mm")
                : null,
              endDate: receivedBanner.endDate
                ? moment(receivedBanner.endDate).format("YYYY-MM-DDTHH:mm")
                : null,
              imageUrl: receivedBanner.imageUrl || "",
            };
          }
          return banner;
        });
      } catch (error) {
        console.error("Erro ao buscar banners:", error);
      }
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    bannerTypesComputed() {
      return this.bannerTypes.map((bannerType) => {
        return {
          ...bannerType,
          banner: this.banners.find(
            (banner) => banner.position === bannerType.position
          ),
        };
      });
    },
  },
  async created() {
    await this.getBanners();
  },
};
</script>

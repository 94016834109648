import { jsPDF } from "jspdf";
import "jspdf-autotable";

export default class PDFManager {
  constructor(options = {}) {
    this.doc = new jsPDF({
      orientation: options.orientation || "p",
      unit: "mm",
      format: options.format || "a4",
    });

    // Configurações de layout
    this.margin = options.margin || 10;
    this.spacing = options.spacing || 7;
    this.yPosition = options.initialY || 20;
    this.pageBreak = options.pageBreak || 20; // Limite de altura para quebra de página
  }

  addTitle(text, size = 16, options = { x: null, y: null }) {
    this.doc.setFontSize(size);
    this.doc.text(text, options?.x || this.margin, options?.y || this.yPosition, {
      fontStyle: "bold",
      ...options,
    });
    this.yPosition += (options.spacing || this.spacing) + 4; // Incrementa com espaçamento extra para título

    this.autoPageBreak(); // Verifica se precisa adicionar uma nova página
  }

  addText(text, size = 12, options = {}) {
    this.doc.setFontSize(size);
    this.doc.text(text, this.margin, this.yPosition, options);
    this.yPosition += (options.spacing || this.spacing) + 2; // Incrementa com espaçamento extra para texto
    this.autoPageBreak(); // Verifica se precisa adicionar uma nova página
  }

  addTable(headers, data, options = {}) {
    this.doc.autoTable({
      head: [headers],
      body: data,
      startY: this.yPosition,
      margin: { left: this.margin },
    });
    this.yPosition =
      this.doc.previousAutoTable.finalY + (options.spacing || this.spacing) + 4; // Incrementa com espaçamento extra para tabela
  }

  addImage(...params) {
    this.doc.addImage(...params);
    // this.yPosition += this.spacing; // Incrementa a posição Y após a imagem
  }

  autoPageBreak() {
    if (this.doc.internal.pageSize.height - this.yPosition <= this.pageBreak)
      this.addPage();
  }

  addSpace(size = 10) {
    this.yPosition += size;
  }

  addPage() {
    this.doc.addPage();
    this.yPosition = this.margin; // Reseta a posição Y para o topo da nova página
  }

  addDivider(width = 0.3) {
    this.doc.setLineWidth(width);
    this.doc.line(
      this.margin,
      this.yPosition,
      this.doc.internal.pageSize.width - this.margin,
      this.yPosition
    );
    this.yPosition += this.spacing; // Incrementa a posição Y após a linha divisória
  }

  addFooter(func) {
    const totalPages = this.doc.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      this.doc.setPage(i);
      func(this.doc, { totalPages, currentPage: i });
    }
  }

  save(filename = "document.pdf") {
    // normalize filename to replace special characters for compatibility
    filename = filename.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    this.doc.save(filename);
  }
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0 pt-1",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"rules":[(v) => !!v || 'Nome é obrigatório'],"label":"Nome","required":"","outlined":"","dense":""},model:{value:(_vm.kit.name),callback:function ($$v) {_vm.$set(_vm.kit, "name", $$v)},expression:"kit.name"}})],1),_c('v-col',{staticClass:"pb-0 pt-2",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"rules":[
          (v) => !!v || 'Preço é obrigatório',
          (v) => v > 0 || 'Preço deve ser maior que 0',
        ],"dense":"","prefix":"R$","label":"Preço","type":"number","step":"0.01","outlined":""},model:{value:(_vm.kit.price),callback:function ($$v) {_vm.$set(_vm.kit, "price", $$v)},expression:"kit.price"}})],1),_c('v-col',{staticClass:"pb-0 pt-2",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"dense":"","rules":[
          (v) => !!v || 'Quantidade é obrigatório',
          (v) => v >= 1 || 'Quantidade deve ser maior ou igual a ' + 1,
        ],"label":"Quantidade","type":"number","min":1,"outlined":""},model:{value:(_vm.kit.quantity),callback:function ($$v) {_vm.$set(_vm.kit, "quantity", $$v)},expression:"kit.quantity"}})],1),_c('v-col',{staticClass:"pb-0 pt-2",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"max":new Date(_vm.party.endDate).toISOString().substr(0, 16),"label":"Inicio das vendas","type":"datetime-local","rules":_vm.dateRules.start,"outlined":"","dense":""},model:{value:(_vm.kit.startDate),callback:function ($$v) {_vm.$set(_vm.kit, "startDate", $$v)},expression:"kit.startDate"}})],1),_c('v-col',{staticClass:"pb-0 pt-2",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":`${_vm.kit.startType ? 'Fim' : 'Limite'} das vendas`,"max":new Date(_vm.party.endDate).toISOString().substr(0, 16),"dense":"","type":"datetime-local","rules":_vm.dateRules.end,"outlined":""},model:{value:(_vm.kit.endDate),callback:function ($$v) {_vm.$set(_vm.kit, "endDate", $$v)},expression:"kit.endDate"}})],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"4"}},[_c('v-switch',{attrs:{"label":"Venda online","messages":[
          _vm.kit.onlineSale
            ? 'Venda online permitida'
            : 'Venda online não permitida',
        ]},model:{value:(_vm.kit.onlineSale),callback:function ($$v) {_vm.$set(_vm.kit, "onlineSale", $$v)},expression:"kit.onlineSale"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card outlined :loading="loading" v-intersect="onIntersect">
    <v-card-title class="justify-space-between">
      <h6 class="mb-0 lh-1">Vendas por Promoter</h6>
    </v-card-title>
    <v-card-text>
      <v-alert v-if="error" type="error" class="mb-0">
        {{ error }}
      </v-alert>
      <vue-perfect-scrollbar
        v-else
        :settings="{ suppressScrollX: true, wheelPropagation: false }"
        style="max-height: 500px"
      >
        <v-data-table
          :headers="headers"
          :items="sellers"
          :items-per-page="sellers.length"
          hide-default-footer
          sort-by="total"
          single-expand
          show-expand
          item-key="id"
          sort-desc
          dense
        >
          <template v-slot:item.name="{ item }">
            <base-avatar :src="item.photo" :seed="item.id" size="18" />
            <span class="ml-2">{{ item.name }}</span>
          </template>
          <template v-slot:item.total="{ item }">
            {{ item.total | currency }}
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-simple-table dense class="my-2">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Setor</th>
                      <th class="text-center">Ingressos</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="block in Object.values(item.ticketBlocks)"
                      :key="block.id"
                    >
                      <td>{{ block.TicketGroup.name }} - {{ block.name }}</td>
                      <td class="text-center">{{ block.quantity }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </template>
        </v-data-table>
      </vue-perfect-scrollbar>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    error: null,
    viewed: false,
    isIntersecting: false,
    headers: [
      { text: "Promoter", class: "px-1", cellClass: "px-1", value: "name" },
      {
        text: "Ingressos",
        class: "px-1",
        cellClass: "px-1",
        value: "tickets",
        align: "center",
      },
      // {
      //   text: "Vendas",
      //   class: "px-1",
      //   cellClass: "px-1",
      //   value: "quantity",
      //   align: "center",
      // },
      {
        text: "Valor",
        class: "px-1",
        cellClass: "px-1",
        value: "total",
        align: "end",
      },
      {
        text: "",
        class: "px-1",
        cellClass: "px-1",
        value: "data-table-expand",
      },
    ],
  }),
  methods: {
    onIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.isIntersecting = true;
        this.viewed = true;
      } else {
        this.isIntersecting = false;
      }
    },
  },
  computed: {
    sellers() {
      if (!this.viewed) return [];
      return Object.values(
        this.data.tickets
          .filter(
            (t) =>
              t.Seller &&
              t.Payment?.status == "succeeded" &&
              t.Payment.paymentType !== "COURTESY" &&
              !t.Payment?.PosSession
          )
          .reduce((acc, t) => {
            const seller = t.Seller;
            acc[seller.id] = acc[seller.id] || {
              id: seller.id,
              name: seller.name,
              photo: seller.photo,
              total: 0,
              tickets: 0,
              quantity: 0,
              ticketBlocks: {},
            };

            acc[seller.id].tickets += 1;

            const ticketBlock = t.TicketBlock;
            if (!acc[seller.id].ticketBlocks[ticketBlock.id]) {
              acc[seller.id].ticketBlocks[ticketBlock.id] = {
                ...ticketBlock,
                quantity: 0,
              };
            }
            acc[seller.id].ticketBlocks[ticketBlock.id].quantity += 1;

            acc[seller.id].quantity += 1;
            acc[seller.id].total += t.amount - t.platformFee;

            return acc;
          }, {})
      );
    },
    chartSeries() {
      const response = [];

      var approved = Object.values(this.sellers).map((tickets) => {
        return tickets.reduce((acc, t) => {
          if (t.Payment.status !== "succeeded") return acc;
          return acc + (this.display === 0 ? t.Payment.amount : 1);
        }, 0);
      });

      return approved;
    },
    chartOptions() {
      return {
        chart: {
          type: "donut",
          height: 350,
        },
        labels: Object.keys(this.sellers),
        colors: [
          "#5e5ce6",
          "#82b1ff",
          "#ff5252",
          "#4caf50",
          "#ff5722",
          "#242939",
        ],
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          labels: {
            formatter: (value) =>
              this.display === 0
                ? value.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })
                : `${value} ingressos`,
          },
        },
      };
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style></style>

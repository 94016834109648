import http from "@/http-common";

const create = async (orgId, partyId, data) => {
  return await http
    .post(`/admin/organization/${orgId}/party/${partyId}/courtesy-emitter`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const getAll = async (orgId, partyId) => {
  return await http
    .get(`/admin/organization/${orgId}/party/${partyId}/courtesy-emitter`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const cancel = async (orgId, partyId, ticketBlockId) => {
  return await http
    .delete(
      `/admin/organization/${orgId}/party/${partyId}/courtesy-emitter/${ticketBlockId}`
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const notify = async (orgId, partyId, responsibleId) => {
  return await http
    .post(
      `/admin/organization/${orgId}/party/${partyId}/courtesy-emitter/${responsibleId}/notify`
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

export default {
  create,
  getAll,
  cancel,
  notify,
};

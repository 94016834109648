<template>
  <div class="d-flex justify-space-around align-center w-full" ref="el">
    <div
      v-for="i in dots || nOfDots"
      :key="`t-${i}`"
      class="dot"
      :class="{ top, bottom }"
      :style="{
        backgroundColor: bgColor,
        width: `${size}px`,
        height: `${size}px`,
      }"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      nOfDots: 6,
    };
  },
  watch: {
    $refs: {
      handler() {
        const width = this.$refs.el?.offsetWidth || 7 * 60;
        this.nOfDots = Math.floor(width / 60) - 1;
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    bgColor() {
      if (this.color) return this.color;
      return this.$vuetify.theme.dark ? "#121212" : "#f2f3f8";
    },
  },
  props: {
    top: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
    },
    size: {
      type: Number,
      default: 30,
    },
    dots: {
      type: Number,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.dot {
  border-radius: 50%;
  display: inline-block;
  margin: -100% 0px;
  z-index: 1;
}
.top {
  clip-path: polygon(0% 50%, 0% 100%, 100% 100%, 100% 50%);
}
.bottom {
  clip-path: polygon(0% 0%, 0% 50%, 100% 50%, 100% 0%);
}
</style>

<template>
  <div>
    <v-alert v-if="error" type="error">
      {{ error }}
    </v-alert>
    <v-card-text v-else class="px-0">
      <v-data-table
        :headers="headers"
        :items="filteredSaleLinks"
        :loading="loading"
        :search="search"
      >
        <template v-slot:top>
          <div class="d-flex pa-2">
            <v-text-field
              outlined
              dense
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar link pelo nome cadastrado"
              single-line
              hide-details
              class="w-full"
            />
            <v-spacer />
            <v-btn
              icon
              @click="getSaleLinks"
              :loading="loading"
              :disabled="blockRefresh"
              class="ml-2"
              :small="$vuetify.breakpoint.mdAndDown"
            >
              <v-icon :small="$vuetify.breakpoint.mdAndDown">
                mdi-refresh
              </v-icon>
            </v-btn>
            <v-btn
              @click="newSaleLink"
              :disabled="loading"
              class="ml-2"
              :small="$vuetify.breakpoint.mdAndDown"
              color="primary"
            >
              <v-icon :small="$vuetify.breakpoint.mdAndDown" left>
                mdi-plus
              </v-icon>
              Adicionar link
            </v-btn>
          </div>
        </template>

        <template v-slot:item.views="{ item }">
          {{ item.views | number }} acesso{{ item.views != 1 ? "s" : "" }}
        </template>
        <template v-slot:item._count.Ticket="{ item }">
          {{ item._count.Ticket | number }}
          ingresso{{ item._count.Ticket != 1 ? "s" : "" }}
        </template>
        <template v-slot:item.active="{ item }">
          <v-chip :color="item.active ? 'success' : 'error'" small label>
            {{ item.active ? "Ativo" : "Inativo" }}
          </v-chip>
        </template>
        <template v-slot:item.TicketGroup.length="{ item }">
          {{ item.TicketGroup.length }} setor{{
            item.TicketGroup.length != 1 ? "es" : ""
          }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn icon @click="saleLinkReport(item)" :disabled="loading">
            <v-icon small>mdi-eye</v-icon>
          </v-btn>
          <v-btn icon @click="qrCode(item)" :disabled="loading">
            <v-icon small>mdi-qrcode-scan</v-icon>
          </v-btn>
          <v-btn icon @click="editSaleLink(item)" :disabled="loading">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td class="pb-4 px-0" :colspan="headers.length">
            {{ item }}
          </td>
        </template>
      </v-data-table>
    </v-card-text>
    <sale-link-report :party="party" />
    <sale-link-modal @success="getSaleLinks" :party="party" />
    <sale-link-qr />
  </div>
</template>

<script>
import ORGANIZATION from "@/services/admin/organization";
import moment from "moment";
import SaleLinkModal from "./modal/SaleLinkModal.vue";
import SaleLinkQr from "./modal/SaleLinkQr.vue";
import SaleLinkReport from "./modal/SaleLinkReport.vue";

export default {
  components: {
    SaleLinkModal,
    SaleLinkQr,
    SaleLinkReport,
  },
  name: "OfflinePaymentsManagement",
  data: () => ({
    loading: true,
    blockRefresh: false,
    error: false,
    saleLinks: [],
    lastUpdate: null,
    expanded: [],
    search: "", // Adicionado para busca
    headers: [
      {
        text: "Nome",
        value: "name",
        sortable: true,
        align: "start",
      },
      {
        text: "Acessos",
        value: "views",
        sortable: true,
        align: "center",
      },
      {
        text: "Ingressos",
        value: "_count.Ticket",
        sortable: true,
        align: "center",
      },
      {
        text: "Setores",
        value: "TicketGroup.length",
        align: "center",
      },
      {
        text: "Status",
        value: "active",
        align: "center",
      },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "end",
        width: "140px",
      },
    ],
  }),
  computed: {
    filteredSaleLinks() {
      if (this.search) {
        return this.saleLinks.filter((item) =>
          item.name.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return this.saleLinks;
    },
  },
  methods: {
    async getSaleLinks() {
      try {
        this.error = null;
        this.loading = true;
        this.blockRefresh = true;

        const { saleLinks } = await ORGANIZATION.party.saleLink.getAll(
          this.party.organizationId,
          this.party.id
        );
        this.saleLinks = saleLinks;

        this.lastUpdate = moment().format("HH:mm:ss");
        this.enableRefresh(10000);
      } catch (e) {
        this.error = e.message || "Erro ao carregar os links de venda";
        this.enableRefresh(5000);
      } finally {
        this.loading = false;
      }
    },
    enableRefresh(time) {
      setTimeout(() => {
        this.blockRefresh = false;
      }, time);
    },
    newSaleLink() {
      this.$emit("sale-link-modal");
    },
    editSaleLink(item) {
      this.$emit("sale-link-modal", item);
    },
    saleLinkReport(item) {
      this.$emit("sale-link-report", item);
    },
    qrCode(item) {
      this.$emit("sale-link-qrcode", item);
    },
  },
  mounted() {
    this.getSaleLinks();
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
